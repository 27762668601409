import {
  useEffect,
  useState,
  Suspense,
} from 'react';
import { Button } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import PDPService from '../services/pdp.service';
import {
  IPositionType,
  IPositionTypeResponse,
  IExperienceLevel,
  AttestationPDPProfileData,
  AttestationPDPProfileDefaultData,
  TrainingEducationDefaultData,
} from '../types/pdp.types';
import api from '../services/api.service';
import {
  experienceLevelOptionsState,
} from '../services/state.service';
import {
  UserProfile,
  UserProfileDefaultValue,
} from '../types/user.types';
import SuspenseLoading from './generic/SuspenseLoading';
import ProfileInformation, { ProfileInformationProps } from './pdp/ProfileInformation';
import { getFullName } from '../utils';
import EducationTraining, { EducationTrainingProps } from './pdp/EducationTraining';
import ServiceToTheChurch from './pdp/ServiceToTheChurch';
import WorkExperience from './pdp/WorkExperience';
import StatementOfFaith from './pdp/StatementOfFaith';
import PDPOptionalLinks from './pdp/PDPOptionalLinks';
import SexualMisconduct from './pdp/SexualMisconduct';
import PDPReferences from './pdp/PDPReferences';
import PDPNarratives from './pdp/PDPNarratives';

function AttestationPDPDetails(): JSX.Element {
  const params = useParams();
  const { t } = useTranslation();
  const [pdp, setPDP] = useState<AttestationPDPProfileData>(AttestationPDPProfileDefaultData);
  const [positionTypeList, setPositionTypeList] = useState<IPositionType[]>([]);
  const experienceLevels = useRecoilValue<IExperienceLevel[]>(experienceLevelOptionsState);
  const [filteredExpLevels, setFilteredExpLevels] = useState<IExperienceLevel[]>();
  const [userProfile, setUserProfile] = useState<UserProfile>(UserProfileDefaultValue);
  const [displayPrint, setDisplayPrint] = useState(false);
  const [profileInfo, setProfileInfo] = useState<ProfileInformationProps | null>(null);
  const [educationInfo, setEducationInfo] = useState<EducationTrainingProps>(TrainingEducationDefaultData);

  useEffect(() => {
    const list = [...experienceLevels];
    list.splice(list.findIndex((p) => p.value === 'No Experience/First Ordained Call'), 1);
    setFilteredExpLevels(list);
  }, [experienceLevels]);

  useEffect(() => {
    if (userProfile.firstName && pdp.pdpId) {
      setProfileInfo(() => ({
        header: t('ProfileInformation'),
        pdpId: pdp.pdpId.toString() || '0',
        fullName: getFullName(userProfile),
        email: userProfile.email,
        pronouns: userProfile.pronouns,
        addressOne: userProfile.addressOne,
        fullAddress: userProfile.fullAddress,
        phoneOne: userProfile.phoneOne,
        phoneTwo: userProfile.phoneTwo,
        eccStatusDescription: '',
        presbyteryNonOrdainedDescription: '',
        congregationDescription: '',
        presbyteryDescription: '',
        ordinationDate: '',
      }));
      setEducationInfo({
        formalEducation: pdp.formalEducation,
        trainingCertification: pdp.trainingCertification,
        continuingEducation: pdp.continuingEducation,
        skills: pdp.skills,
      });
    }
  }, [userProfile, pdp]);

  useEffect(() => {
    Promise.all([
      api.get(`/attestationpdpdetails/${params.id}/`).then(
        (response: any) => {
          setUserProfile(response.data.options.userProfile);
          setPDP(response.data.options);
        },
      ),
      PDPService.GetPositionTypes(params.id).then(
        (response: IPositionTypeResponse) => { setPositionTypeList(response.data.options); },
      ),
    ]).then(() => {
      setDisplayPrint(true);
    });
  }, [params.id]);

  return (
    <div className="col-10 my-4 mx-auto">
      {displayPrint && (
        <div className="text-end">
          <Button
            className="mb-3 ms-auto printbutton"
            variant="primary"
            size="sm"
            active
            onClick={() => (window.print())}
          >
            <FaEdit />
            {` ${t('Print_Btn')}`}
          </Button>
        </div>
      )}
      <div className="col-12 mx-auto">
        <div className="mx-auto col-12">

          <div className="title text-center">
            {`${t('PDP.PDP_Title')} ID# ${params.id}`}
          </div>
          {profileInfo && <ProfileInformation profileInfo={profileInfo} />}

          {/* Preferences */}
          <div className="title text-center">
            {t('PDP.Preferences')}
          </div>
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-6">
                <div className="my-4 mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('PDP.Employment_Type')}
                  </div>
                  {pdp.employmentType}
                </div>
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('PDP.Position_Type_Title')}
                  </div>
                  <Suspense fallback={<SuspenseLoading />}>
                    <div>
                      {pdp.positionTypes?.map((option: any) => (
                        <div key={`postype-${option.positionType}`}>
                          {positionTypeList.map((item: any) => (
                            (item.id === option.positionType)
                              ? (
                                <div key={`postype-item-${item.id}`}>
                                  {item.positionType}
                                </div>
                              )
                              : null))}
                          {filteredExpLevels?.map((level: any) => (
                            (level.id === option.experienceLevel)
                              ? (
                                <div key={`explevel-${level.id}`}>
                                  {level.displayName}
                                </div>
                              )
                              : (
                                null
                              )
                          ))}
                        </div>
                      ))}
                    </div>
                  </Suspense>
                </div>
              </div>
              <div className="col-6">
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('PDP.Min_Expected_Salary')}
                  </div>
                  {pdp.minExpectedSalary}
                </div>
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Housing_Type')}
                  </div>
                  {pdp.housingType?.map((option: any) => (
                    <div key={`housing-type-${option.id}`}>
                      {option.value}
                    </div>
                  ))}

                </div>
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Available_To_Match')}
                  </div>
                  <div>
                    <div key="calling-info-actively-seeking">
                      {userProfile.availableToMatch ? (t('Yes')) : (t('No'))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <EducationTraining educationTraining={educationInfo} />
          <WorkExperience workExperience={pdp.workExperience} />
          <ServiceToTheChurch service={pdp.service} />
          <StatementOfFaith statementOfFaith={userProfile.statementOfFaith} />
          <PDPNarratives narratives={pdp.narratives} />
          {pdp.optionalLinks.length > 0 && (
            <PDPOptionalLinks optionalLinks={pdp.optionalLinks} />
          )}
          <PDPReferences references={pdp.reference} />
          <SexualMisconduct sexualMisconduct={pdp} />
        </div>
      </div>
    </div>
  );
}

export default AttestationPDPDetails;
