import {
  useState,
  useEffect,
  useMemo,
  ChangeEvent,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  useRecoilState,
  useSetRecoilState,
} from 'recoil';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import ConfirmModal from './generic/ConfirmModal';
import {
  CompensationData,
  CompensationResponseData,
} from '../services/mdp.service';
import { MDPData } from '../types/mdp.types';
import api from '../services/api.service';
import SanitizeHTML from '../services/html.service';
import { mdpStepState, mdpState, resubmissionState } from '../services/state.service';
import { useStepValid } from '../hooks/useStepValid';
import { MINISTRY_ORG_TYPE } from '../types/constants';

function CompensationAndHousing(): JSX.Element {
  const initialData = {
    mdp: undefined,
    id: undefined,
    minimumExpectedSalary: 0,
    maximumExpectedSalary: 0,
    housingType: '',
    initial: true,
  };

  const { t } = useTranslation();
  const [mdp] = useRecoilState<MDPData>(mdpState);
  const [compensation, setCompensation] = useState<CompensationData>(initialData);
  const [newCompensation, setNewCompensation] = useState<boolean>(true);
  const [showAlert, setShowAlert] = useState(false);
  const [currentCompensationMin, setCurrentCompensationMin] = useState<number>(0);
  const [currentCompensationMax, setCurrentCompensationMax] = useState<number>(0);
  const [newHousingType, setNewHousingType] = useState<string | undefined>('');
  const [currentHousingType, setCurrentHousingType] = useState('');
  const setResubmission = useSetRecoilState(resubmissionState);
  const [refresh, setRefresh] = useState(false);
  const options = { maximumFractionDigits: 6 };
  const [focus, setFocus] = useState<boolean>();
  const inputRef = useRef<any>(null);

  useEffect(() => {
    api.get(`/mdp/${mdp.id}/compensation/`)
      .then(
        (response: CompensationResponseData) => {
          if (response.data.length > 0) {
            setCompensation({ ...response.data[0], initial: true });
            setCurrentCompensationMin(response.data[0].minimumExpectedSalary);
            setCurrentCompensationMax(response.data[0].maximumExpectedSalary);
            setCurrentHousingType(response.data[0].housingType);
            setNewCompensation(false);
          }
        },
      );
  }, [setCompensation, mdp.id, setNewCompensation, refresh]);

  const requiredFields = useMemo(() => ['minimumExpectedSalary', 'maximumExpectedSalary', 'housingType'], []);
  const valid = useStepValid('compensationAndHousing', compensation, requiredFields, mdpStepState);

  useEffect(() => {
    setCompensation((p: any) => ({
      ...p, mdp: mdp.id,
    }));
  }, []);

  useEffect(() => {
    if (valid) {
      if (newCompensation) {
        api.post(`/mdp/${mdp.id}/compensation/`, compensation)
          .then((response: any) => {
            if (response.status === 201) {
              setNewCompensation(false);
            }
          });
      }

      if (newHousingType && newHousingType !== currentHousingType) {
        api.put(`/mdp/${mdp.id}/compensation/${compensation.id}/`, compensation);
      }
    }
  }, [compensation, valid]);

  useEffect(() => {
    const handleClickOutside = (e: any): void => {
      if (inputRef.current && !inputRef.current.contains(e.target) && e.target.tagName !== 'BUTTON') {
        if ((currentCompensationMin !== compensation.minimumExpectedSalary
        || currentCompensationMax !== compensation.maximumExpectedSalary)) {
          if (mdp.submitted && mdp.organizationType === MINISTRY_ORG_TYPE.congregation) {
            setShowAlert(true);
          } else {
            api.put(`/mdp/${mdp.id}/compensation/${compensation.id}/`, compensation);
          }
        }
      }
    };

    if (focus) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [focus, compensation]);

  function triggerChange(): void {
    setFocus(true);
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setCompensation((p: any) => ({
      ...p, housingType: e.target?.dataset.value, initial: false,
    }));
    setNewHousingType(e.target.dataset.value?.toString());
  };

  const closeAlert = useCallback((result: boolean): void => {
    if (result) {
      setShowAlert(false);
      api.put(`/mdp/${mdp.id}/compensation/${compensation.id}/`, compensation);
      setResubmission((r: boolean) => !r);
    } else {
      setRefresh((r: boolean) => !r);
      setShowAlert(false);
    }
    setFocus(false);
  }, [setShowAlert, compensation]);

  return (
    <>
      <ConfirmModal
        show={showAlert}
        title={t('Alert')}
        description={t('MDP_Reapproval_Info_alert')}
        yesLabel={t('Yes')}
        noLabel={t('PDP.Cancel')}
        callback={closeAlert}
      />
      <div className="col-lg-8 col-12 mx-auto">
        <div className="mx-auto">
          <div className="title">{t('Compensation_Housing')}</div>
          <SanitizeHTML html={t('Compensation_Description')} />
          <div className="my-3">
            <Form.Group className="text-start col-6">
              <Form.Label className="bodyheader required">
                {t('Min_Effective_Salary')}
              </Form.Label>
              <OverlayTrigger
                key="tooltip-salary-definition"
                placement="right"
                trigger="click"
                overlay={(
                  <Tooltip id="button-tooltip">
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      className="text-white"
                      href="https://www.pensions.org/calc/totalSalary"
                    >
                      {t('Effective_Salary_Definition')}
                    </a>
                  </Tooltip>
                )}
              >
                <div className="salary-tooltip">
                  <FaQuestionCircle />
                </div>
              </OverlayTrigger>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount"
                  onFocus={() => triggerChange()}
                  value={Intl.NumberFormat('en-US', options).format(compensation?.minimumExpectedSalary || 0)}
                  ref={inputRef}
                  onChange={(e: any) => {
                    setCompensation((p: any) => ({
                      ...p, minimumExpectedSalary: parseInt(e.target.value.replaceAll(',', ''), 10), initial: false,
                    }));
                  }}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="my-4">
            <Form.Group className="text-start col-6">
              <Form.Label className="bodyheader required">
                {t('Max_Effective_Salary')}
              </Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount"
                  onFocus={() => triggerChange()}
                  value={Intl.NumberFormat('en-US', options).format(compensation?.maximumExpectedSalary || 0)}
                  ref={inputRef}
                  onChange={(e: any) => {
                    setCompensation((p: any) => ({
                      ...p, maximumExpectedSalary: parseInt(e.target.value.replaceAll(',', ''), 10), initial: false,
                    }));
                  }}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="my-4">
            <Form.Group className="text-start col-6">
              <Form.Label className="bodyheader required">
                {t('Housing_Type')}
              </Form.Label>
              <div className="ms-2">
                <Form.Check
                  type="radio"
                  label={t('Manse')}
                  id="manse"
                  name="clergy-radio"
                  className="preferencesradio my-1"
                  checked={compensation?.housingType === t('Manse')}
                  onChange={handleCheckboxChange}
                  data-value={t('Manse')}
                />
                <Form.Check
                  type="radio"
                  label={t('Housing_Allowance')}
                  id="allowance"
                  name="clergy-radio"
                  className="preferencesradio my-1"
                  checked={compensation?.housingType === t('Housing_Allowance')}
                  onChange={handleCheckboxChange}
                  data-value={t('Housing_Allowance')}
                />
                <Form.Check
                  type="radio"
                  label={t('Open_to_either')}
                  id="either"
                  name="clergy-radio"
                  className="preferencesradio my-1"
                  checked={compensation?.housingType === t('Open_to_either')}
                  onChange={handleCheckboxChange}
                  data-value={t('Open_to_either')}
                />
                <Form.Check
                  type="radio"
                  label={t('Non_pastoral')}
                  id="non"
                  name="clergy-radio"
                  className="preferencesradio my-1"
                  checked={compensation?.housingType === t('Non-pastoral')}
                  onChange={handleCheckboxChange}
                  data-value={t('Non-pastoral')}
                />
              </div>
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompensationAndHousing;
