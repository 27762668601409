import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReferenceData } from '../../types/pdp.types';

const PDPReferences: React.FC<{ references: ReferenceData[] }> = ({ references }) => {
  const { t } = useTranslation();

  return (
    <div className="border border-dark rounded-3 text-start mb-4">
      <div className="row">
        <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4">{t('PDP.References')}</div>
      </div>
      <hr className="mdpdivider" />
      <div className="row mb-3">
        {references?.map((option: any) => (
          <div key={`references-${option.id}`} className="pdp-references col-sm-12 col-md-4 col-print-4">
            <div className="text-start mt-3 ms-3">
              <b>{option.name}</b>
              <br />
              {option.relation}
              <br />
              {option.phone}
              <br />
              {option.email}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PDPReferences;
