import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Spinner,
  Table,
} from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CallSummaryData, CallSummaryDefaultData, CallSummaryResponseData } from '../types/mdp.types';
import MDPService from '../services/mdp.service';
import withUserAllowed from '../components/layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import { Role } from '../types/user.types';
import { currentRoleState } from '../services/state.service';

interface CallNotificationTitleProps {
  title: string;
}

const CallNotificationTitle: React.FC<CallNotificationTitleProps> = ({ title }) => (
  <tr>
    <td colSpan={2} className="pt-3 pb-2 callNotificationTitle">{title}</td>
  </tr>
);

interface CallNotificationItemProps {
  label: string;
  value: string | number | string[];
}

const CallNotificationItem: React.FC<CallNotificationItemProps> = ({ label, value }) => (
  <tr>
    <td className="callNotificationLabels">
      {label}
      :
    </td>
    <td>{value}</td>
  </tr>
);

interface CallNotificationSummaryProps {
  id?: string;
}

function CallNotificationSummary({ id }: CallNotificationSummaryProps): JSX.Element {
  const params = useParams();
  const { t } = useTranslation();
  const [callSummary, setCallSummary] = useState<CallSummaryData>(CallSummaryDefaultData);
  const [loading, setLoading] = useState(true);
  const [notificationId, setNotificationId] = useState<string | null>(null);
  const currentRole = useRecoilValue<Role>(currentRoleState);

  useEffect(() => {
    if (id) {
      setNotificationId(id.toString());
    } else if (params.id) {
      setNotificationId(params.id);
    }
  }, [id, params.id]);

  useEffect(() => {
    if (!notificationId) {
      return;
    }
    MDPService.GetCallSummary(notificationId)
      .then(
        (response: CallSummaryResponseData) => {
          if (response.data) {
            setCallSummary(response.data);
          } else {
            setCallSummary(CallSummaryDefaultData);
          }
          setLoading(false);
        },
      )
      .catch(() => {
        setLoading(false);
      });
  }, [notificationId]);

  const renderCallSummary = () => {
    if (callSummary.id) {
      return (
        <>
          <div>
            <div className="my-2 text-center callNotificationTitle">
              {t('Call_Notification_Summary')}
            </div>
          </div>
          <Row>
            <Col sm={{ span: 6 }}>
              <Table className="callNotificationTable">
                <tbody>
                  <CallNotificationTitle title={t('Ministry_Information')} />
                  <CallNotificationItem label={t('Match_MDP_ID')} value={callSummary.mdpId} />
                  <CallNotificationItem label={t('Ministry_Name')} value={callSummary.ministryName} />
                  <CallNotificationItem label={t('Presbytery')} value={callSummary.presbytery} />
                  <CallNotificationItem label={t('Ministry_Synod')} value={callSummary.synod} />
                  <CallNotificationItem label={t('State_Zip')} value={callSummary.stateZip} />
                  <CallNotificationItem label={t('Community_Type')} value={callSummary.communityType} />
                  <CallNotificationItem label={t('Ministry_Org_Size')} value={callSummary.organizationSize} />
                  <CallNotificationItem label={t('Ministry_Intercultural_Comp')} value={callSummary.interculturalComposition} />
                  <CallNotificationItem label={t('Released_Date')} value={callSummary.releasedDate} />
                  <CallNotificationItem label={t('Call_Start_Date')} value={callSummary.callingStartDate} />

                  <CallNotificationTitle title={t('MDP.Position_Requirements')} />
                  <CallNotificationItem label={t('Position_Types')} value={callSummary.positionType} />
                  <CallNotificationItem label={t('MDP.Experience_Required')} value={callSummary.experienceLevel} />
                  <CallNotificationItem label={t('Employment_Status')} value={callSummary.employmentStatus} />
                  <CallNotificationItem label={t('Language_Requirements')} value={callSummary.positionLanguages} />

                  <CallNotificationTitle title={t('MDP.Compensation_Housing')} />
                  <CallNotificationItem label={t('PDP.Min_Expected_Salary')} value={callSummary.minEffectiveSalary} />
                  <CallNotificationItem label={t('PDP.Max_Expected_Salary')} value={callSummary.maxExpectedSalary} />
                  <CallNotificationItem label={t('Housing_Type')} value={callSummary.mdpHousingType} />
                </tbody>
              </Table>
            </Col>
            <Col sm={{ span: 6 }}>
              <Table className="callNotificationTable">
                <tbody>
                  <CallNotificationTitle title={t('ProfileInformation')} />
                  {callSummary.pdpId ? (
                    <>
                      <CallNotificationItem label={t('PDP_ID')} value={callSummary.pdpId} />
                      <CallNotificationItem label={t('Call_Seeker_Name')} value={callSummary.callSeekerName} />
                      <CallNotificationItem label={t('EcclesiasticalStatus')} value={callSummary.ecclesiasticalStatus} />
                      <CallNotificationItem label={t('PresbyteryMembership')} value={callSummary.presbyteryOfMembership} />
                      <CallNotificationItem label={t('State_Zip')} value={`${callSummary.callSeekerState} ${callSummary.callSeekerZip}`} />

                      <CallNotificationTitle title={t('PDP.Preferences')} />
                      <CallNotificationItem label={t('Position_Types')} value={callSummary.positionType} />
                      <CallNotificationItem label={t('MDP.Experience_Required')} value={callSummary.experienceLevel} />
                      <CallNotificationItem label={t('Employment_Type')} value={callSummary.employmentType} />
                      <CallNotificationItem label={t('Position_Types')} value={callSummary.positionTitle} />
                      <CallNotificationItem label={t('PDP.Min_Expected_Salary')} value={callSummary.minEffectiveSalary} />
                      <CallNotificationItem label={t('Housing_Type')} value={callSummary.housingTypes} />

                      <CallNotificationTitle title={t('Education_Title')} />
                      <CallNotificationItem label={t('Training_Certification_Title')} value={callSummary.trainingCerts} />
                      <CallNotificationItem label={t('Language_Proficiency')} value={callSummary.languageProficiencies} />
                    </>
                  ) : (
                    <>
                      <CallNotificationItem label={t('Call_Seeker_Name')} value={callSummary.callSeekerName} />
                      <CallNotificationItem label={t('Phone')} value={callSummary.phoneOne} />
                      <CallNotificationItem label={t('Email')} value={callSummary.email} />
                      <CallNotificationItem label={t('Organization_of_Membership')} value={callSummary.organization} />
                      <CallNotificationItem label={t('EcclesiasticalStatus')} value={callSummary.ecclesiasticalStatus} />
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      );
    }

    if (loading) {
      return (
        <Spinner
          animation="border"
          size="sm"
        />
      );
    }

    return (
      <div>
        <h1 className="text-center mt-3">{t('Page_Unavailable')}</h1>
      </div>
    );
  };

  return <Container fluid className="mb-2">{renderCallSummary()}</Container>;
}

CallNotificationSummary.defaultProps = {
  id: '',
};

export default withUserAllowed([CLC_FUNCTION.manageOrganization, CLC_FUNCTION.callSeeker, CLC_FUNCTION.manageMDP].join(','))(CallNotificationSummary);
