import {
  Suspense,
  useRef,
  useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import MDPService from '../services/mdp.service';
import { ActionData } from '../types/mdp.types';
import { sleep } from '../utils';
import Header from './layout/Header';
import ViewForm from './ViewMDPForm';
import CallNotificationSummary from '../pages/CallNotificationSummary';
import useGeneratePDF from '../hooks/useGeneratePDF';
import PDPMatchDetails from './PDPMatchDetails';

interface SubmitCallNotificationModalProps {
  show: boolean;
  actionData: ActionData;
  callback: (_result: boolean) => void;
}

function SubmitCallNotificationModal({
  show,
  actionData,
  callback,
}: SubmitCallNotificationModalProps): JSX.Element {
  const { t } = useTranslation();
  const printNotificationRef = useRef<HTMLDivElement>(null);
  const printPDPRef = useRef<HTMLDivElement>(null);
  const printMDPRef = useRef<HTMLDivElement>(null);
  const [showSubmissionError, setShowSubmissionError] = useState<boolean>(false);
  const { generatePDF } = useGeneratePDF();

  return (
    <Modal
      show={show}
      onShow={async () => {
        await sleep(2000);
        // Generate the PDF
        const pdfs = [];
        if (printNotificationRef.current) {
          pdfs.push(printNotificationRef.current);
        }
        if (printPDPRef.current) {
          pdfs.push(printPDPRef.current);
        }
        if (printMDPRef.current) {
          pdfs.push(printMDPRef.current);
        }

        if (pdfs.length === 0) {
          callback(false);
          return;
        }
        const pdfBlob = await generatePDF(pdfs);
        if (pdfBlob) {
          // Convert pdfBlob to base64
          const reader = new FileReader();
          reader.readAsDataURL(pdfBlob);
          reader.onloadend = () => {
            MDPService.CompleteCallNotification({ ...actionData, pdfBlob: reader.result }).then((response: any) => {
              if (response.data.toString() === '-1') {
                setShowSubmissionError(true);
              } else {
                callback(false);
              }
            });
          };
        }
      }}
      onHide={() => callback(false)}
      animation={false}
      className={showSubmissionError ? 'overflow-hidden-error' : 'overflow-hidden'}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Notify_Call')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{t('Submitting')}</div>
        {showSubmissionError && (
          <div className="alert alert-danger" role="alert">
            {t('Submission_Error')}
          </div>
        )}
        {actionData.id > 0 && (
          <div className="submitting-pdf" ref={printNotificationRef}>
            <Suspense fallback={<div>{t('Loading')}</div>}>
              <Header />
              <CallNotificationSummary id={actionData.id.toString()} />
            </Suspense>
          </div>
        )}
        {actionData.mdpId > 0 && (
          <div className="submitting-pdf" ref={printMDPRef}>
            <Suspense fallback={<div>{t('Loading')}</div>}>
              <Header />
              <ViewForm mdpId={actionData.mdpId.toString()} />
            </Suspense>
          </div>
        )}
        {actionData.pdpId > 0 && (
          <div className="submitting-pdf" ref={printPDPRef}>
            <Suspense fallback={<div>{t('Loading')}</div>}>
              <Header />
              <PDPMatchDetails
                pdpId={actionData.pdpId}
                matchId={actionData.matchId}
                mdpId={actionData.mdpId.toString()}
              />
            </Suspense>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default SubmitCallNotificationModal;
