import React from 'react';
import { useTranslation } from 'react-i18next';
import { OptionalLinkData } from '../../types/pdp.types';

const PDPOptionalLinks: React.FC<{ optionalLinks: OptionalLinkData[] }> = ({ optionalLinks }) => {
  const { t } = useTranslation();

  return (
    <div className="border border-dark rounded-3 text-start mb-4">
      <div className="row">
        <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4">{t('PDP.Optional_Links')}</div>
      </div>
      <hr className="mdpdivider" />
      <div className="row">
        <div className="col-12">
          <div className="my-4  mx-3">
            {optionalLinks?.map((option: any) => (
              <div key={`optionallinks-${option.id}`} className="text-start my-4">
                <b>{option.linkTitle}</b>
                &nbsp;-&nbsp;
                {option.linkDescription}
                &nbsp;-&nbsp;
                <a
                  href={(option.linkUrl).startsWith('http') ? option.linkUrl : (`//${option.linkUrl}`)}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {option.linkUrl}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDPOptionalLinks;
