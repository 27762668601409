import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppliedPDP, PDPData } from '../../types/pdp.types';

interface LoggedInModalProps {
  show: boolean;
  onHide: () => void;
  list: PDPData[];
  setAppliedPDP: React.Dispatch<React.SetStateAction<AppliedPDP>>;
  submitAppliedPDP: () => void;
}

const LoggedInModal: React.FC<LoggedInModalProps> = ({
  show,
  onHide,
  list,
  setAppliedPDP,
  submitAppliedPDP,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide} animation={false} className="modalstyle" backdrop="static">
      {list.length > 0 ? (
        <>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="col-md-12 text-center my-5">
              <div className="title">{t('Active_PDPs')}</div>
            </div>
            <div className="col-md-12 text-center my-5">
              <Form.Select
                onChange={(e) => setAppliedPDP((p) => ({
                  ...p,
                  pdp: e.target.value.toString(),
                }))}
              >
                <option key="" value="">{t('Select_PDP')}</option>
                {list.map((pdp: PDPData) => (
                  <option key={`${pdp.id}-active-pdp`} value={pdp.id}>
                    {`${pdp.id}: ${pdp.positionTypeDescription}`}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-12 text-center">
              <Button className="px-5" variant="primary" onClick={submitAppliedPDP}>
                {t('OK')}
              </Button>
            </div>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="col-md-12 text-center my-5">
              <div className="title">{t('No_Active_PDPs')}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-12 text-center">
              <Button className="px-5" variant="primary" onClick={onHide}>
                {t('OK')}
              </Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default LoggedInModal;
