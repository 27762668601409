import React, {
  Suspense,
  useRef,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { CompletedCallData } from '../types/mdp.types';
import { sleep } from '../utils';
import Header from './layout/Header';
import CallNotificationSummary from '../pages/CallNotificationSummary';
import useGeneratePDF from '../hooks/useGeneratePDF';

interface DownloadCallNotificationModalProps {
  show: boolean;
  year: string;
  completedCalls: CompletedCallData[];
  callback: (_result: boolean) => void;
}

function DownloadCallNotificationModal({
  show,
  year,
  completedCalls,
  callback,
}: DownloadCallNotificationModalProps): JSX.Element {
  const { t } = useTranslation();
  const printNotificationRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const { generatePDF, downloadPDF } = useGeneratePDF();

  // Initialize refs for each completed call
  if (printNotificationRefs.current.length !== completedCalls.length) {
    printNotificationRefs.current = Array(completedCalls.length).fill(0).map((_, i) => printNotificationRefs.current[i] || React.createRef());
  }

  return (
    <Modal
      show={show}
      onShow={async () => {
        await sleep(2000);
        // Generate the PDF
        const pdfs = printNotificationRefs.current
          .filter((ref) => ref.current != null)
          .map((ref) => ref.current as HTMLDivElement);
        const pdfBlob = await generatePDF(pdfs);
        if (pdfBlob) {
          downloadPDF(pdfBlob, `CallNotifications-${year}.pdf`);
          callback(true);
        }
      }}
      onHide={() => callback(false)}
      animation={false}
      className="overflow-hidden"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {t('Call_Notifications')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{t('Downloading')}</div>
        <div className="submitting-pdf">
          {completedCalls.map((call, index) => (
            <div key={call.id} ref={printNotificationRefs.current[index]}>
              <Suspense fallback={<div>{t('Loading')}</div>}>
                <Header />
                <CallNotificationSummary id={call.id.toString()} />
              </Suspense>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DownloadCallNotificationModal;
