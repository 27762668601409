import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { userProfileState } from '../services/state.service';
import { UserProfile } from '../types/user.types';
import api from '../services/api.service';
import surveyImage from '../assets/images/survey_image.jpg';
import { CompetencyData, CompetencyDefaultData, CompetencyResponseData } from '../types/profile.types';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

type CompetencySurveyModalProps = {
  close: () => void,
  show: boolean,
}

function CompetencySurveyModal({
  close,
  show,
}: CompetencySurveyModalProps): JSX.Element {
  const { t } = useTranslation();
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const [competencyData, setCompetencyData] = useState<CompetencyData>(CompetencyDefaultData);

  useEffect(() => {
    if (show) {
      api.get('/competency/').then((response: CompetencyResponseData) => {
        setCompetencyData(response.data);
      });
    }
  }, [show]);

  const data = {
    labels: competencyData.labels,
    datasets: [
      {
        label: t('CompetencyStrength'),
        data: competencyData.values,
        backgroundColor: 'rgba(26, 67, 138, 0.2)',
        borderColor: 'rgba(26, 67, 138, 1)',
        borderWidth: 3,
        scales: {
          r: {
            angleLines: {
              display: false,
            },
            suggestedMin: 50,
            suggestedMax: 100,
          },
        },
      },
    ],
  };

  const options = {
    scales: {
      r: {
        min: 0.0,
        max: 1.0,
        ticks: {
          min: 0,
          max: 1.0,
          stepSize: 0.2,
        },
      },
    },
  };

  return (
    <Modal fullscreen show={show} onHide={close}>
      <Modal.Header closeButton />
      <Modal.Body className="text-center survey-modal">
        <div className="title">
          {t('ModalCompetencySurveyTitle')}
        </div>
        <div className="col-lg-8 col-12 mx-auto">
          {t('ModalCompetencySurveyDescription')}
        </div>
        <div className="col-sm-12 text-center mb-3">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://pcusa.org/about-pcusa/agencies-entities/interim-unified-agency/ministry-areas/call-process/church-leadership-connection/clc-survey-info"
          >
            {t('UnderstandingCompetencySurvey')}
          </a>
        </div>
        { userProfile.surveyCompleted
          ? (
            <div className="col-sm-12 col-md-4 col-lg-3 mx-auto">
              <Radar data={data} options={options} />
            </div>
          )
          : (
            <div className="col-12 mx-auto m-3">
              <img
                alt=""
                width="25%"
                src={surveyImage}
              />
            </div>
          )}
        <div className="col-lg-8 col-12 mx-auto mb-3">
          { !userProfile.surveyStarted && (t('ModalCompetencySurveyNew')) }
          { userProfile.surveyStarted && !userProfile.surveyCompleted && (t('ModalCompetencySurveyInProgress')
            .replace('%s', userProfile.surveyStarted)) }
          { !userProfile.surveyReopened && userProfile.surveyCompleted && (t('ModalCompetencySurveyCompleted')
            .replace('%s', userProfile.surveyCompleted).replace('%s', userProfile.surveyReset)) }
          { userProfile.surveyReopened && (t('ModalCompetencySurveyRetake')) }
        </div>
        <Link to="/survey" className="btn btn-primary">
          { !userProfile.surveyStarted && (t('TakeTheSurvey')) }
          { userProfile.surveyStarted && !userProfile.surveyCompleted && (t('ContinueSurvey')
            .replace('%s', userProfile.surveyStarted)) }
          { !userProfile.surveyReopened && userProfile.surveyCompleted && (t('ViewSurveyResponses')
            .replace('%s', userProfile.surveyCompleted).replace('%s', userProfile.surveyReset)) }
          { userProfile.surveyReopened && (t('RetakeSurvey')) }
        </Link>
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <Button variant="primary" onClick={close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default CompetencySurveyModal;
