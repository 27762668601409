import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function DissolvedCommitteeConfirmationModal({
  show,
  hide,
  committeeName,
} : {
  show: boolean,
  hide: () => void,
  committeeName: string,
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={hide}
      className="modalstyle"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Committee_Dissolved_Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="row">
          <div className="col-12">
            {t('Committee_Dissolved_Confirmation', { committeeName })}
          </div>
        </div>
        <br />
      </Modal.Body>
    </Modal>
  );
}

export default DissolvedCommitteeConfirmationModal;
