import React from 'react';
import { useTranslation } from 'react-i18next';
import SanitizeHTML from '../../services/html.service';
import { ContinuingEducationData, FormalEducationData, SkillData } from '../../types/pdp.types';

export interface EducationTrainingProps {
  formalEducation: FormalEducationData[],
  continuingEducation: ContinuingEducationData[],
  trainingCertification: [] | undefined,
  skills: SkillData[],
}

const EducationTraining: React.FC<{ educationTraining: EducationTrainingProps }> = ({ educationTraining }) => {
  const { t } = useTranslation();
  const {
    formalEducation,
    continuingEducation,
    trainingCertification,
    skills,
  } = educationTraining;

  return (
    <div className="border border-dark rounded-3 text-start mb-4">
      <div className="row">
        <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4">{t('Education_Training')}</div>
      </div>
      <hr className="mdpdivider" />
      <div className="row">
        <div className="col-10 my-4 mx-3">
          <div className="mb-2 reviewtitle">
            {t('Education_Title')}
          </div>
          {formalEducation?.map((option: any) => (
            <div key={`formal-edu-${option.id}`}>
              {`${option.credentials} - ${option.school},  (${option.start} ${option.current
                ? (t('- Current'))
                : (` - ${option.end}`)})`}
            </div>
          ))}
          <div className="my-4 mb-2 reviewtitle">
            {t('Continuing_Education')}
          </div>
          {continuingEducation?.map((option: any) => (
            <div key={`continuing-edu-${option.id}`}>
              <SanitizeHTML html={option.text} />
            </div>
          ))}
          <div className="mb-2 my-4 reviewtitle">
            {t('Training_Certification_Title')}
          </div>
          <div>
            {trainingCertification ? (
              trainingCertification?.map((option: any) => (
                <div key={`training-certification-${option.id}`}>
                  <SanitizeHTML
                    html={`${t(option.weekOne)} ${option.weekOne ? ' - ' : ''} ${option.weekOneLocation}`}
                  />
                  <SanitizeHTML
                    html={`${t(option.weekTwo)} ${option.weekOne ? ' - ' : ''} ${option.weekTwoLocation}`}
                  />
                  <SanitizeHTML html={option.cert} />
                </div>
              ))
            ) : (
              t('NotSpecified')
            )}
          </div>
          <div className="mb-2 my-4 reviewtitle">
            {t('Language_Proficiency')}
          </div>
          <div>
            {skills?.map((option: any) => (
              <div key={`language-edu-${option.id}`}>
                {option.language}
              </div>
            ))}
          </div>
          <div className="mb-2 my-4 reviewtitle">
            {t('Technical_Skills')}
          </div>
          <div>
            {skills?.map((option: any) => (
              <div key={`skill-edu-${option.id}`}>
                {option.technical}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationTraining;
