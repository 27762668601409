import api from './api.service';
import {
  MDPResponseData,
  MDPData,
  PDPMatchResponseData,
  ActionData,
  MatchActionResponse,
  MDPResultListResponseData,
  MDPDetailsResponseData,
  PDPMatchData,
  MDPResultListData,
  PDPMatchListResponseData,
  CompletedCallsResponseData,
  CallSummaryResponseData,
  MatchResponseData,
  MatchData,
} from '../types/mdp.types';

import {
  CongregationData,
  CongregationResponseData,
  PDPMatchProfileData,
  AppliedPDP,
} from '../types/pdp.types';

export interface IMDPPositionType {
  experienceLevel: string,
  mdp: number,
  positionType: number,
}

export interface RequirementsData {
  id: number | undefined,
  mdp: string | undefined,
  employmentType: string,
  employmentTypeDescription: string,
  positionType: string,
  positionTypeDescription: string,
  statementRequired: boolean,
  filingCouple: boolean,
  noMatchingWithinPresbytery: boolean,
  experienceLevel: string,
  experienceLevelDescription: string,
  title: string,
  deadline: Date | undefined,
  certificate: number[],
  othercert: string,
  language: number[],
  otherlang: string | undefined,
  initial: boolean,
}

export interface RequirementsResponseData {
  data: RequirementsData[],
}

export interface CompensationData {
  mdp: string | undefined,
  id: number | undefined,
  minimumExpectedSalary: number,
  maximumExpectedSalary: number,
  housingType: string,
  initial: boolean,
}

export interface CompensationResponseData {
  data: CompensationData[],
}

export interface IPositionTypeList {
  id: number | undefined,
  value: string,
  positionType: string,
  yokedPosition: boolean,
}

export interface IPositionTypeListResponse {
  data: {
    options: IPositionTypeList[],
  }
}

export interface ISelectOption {
  id: number | undefined,
  value: string
}

export interface ISelectOptionResponse {
  data: {
    options: ISelectOption[],
  }
}

export interface HousingTypeData {
  id: string,
  value: string,
}

export interface HousingTypeResponseData {
  data: {
    options: HousingTypeData[],
  }
}

export interface IExperienceLevel {
  id: string,
  value: string,
  displayName: string,
}

export interface IExperienceLevelResponse {
  data: {
    options: IExperienceLevel[],
  }
}

export interface EmploymentTypeData {
  id: string,
  value: string,
}

export interface LanguageData {
  id: string,
  description: string,
  abbreviation: string,
}

export interface LanguageResponseData {
  data: {
    options: LanguageData[],
  }
}

const Update = (mdp: MDPData): Promise<MDPResponseData> => (
  api.put(`/mdp/${mdp.id}/`, mdp)
);

export interface ValidData {
  positionrequirements: boolean,
  ministryrequirements: boolean,
  compensationandhousing: boolean,
  narratives: boolean,
  EEO: boolean,
  references: boolean,
  pncchair: boolean,
  review: boolean,
}

export const ValidDefaultData: ValidData = {
  positionrequirements: false,
  ministryrequirements: false,
  compensationandhousing: false,
  narratives: false,
  EEO: false,
  references: false,
  pncchair: false,
  review: false,
};

const GetMDPData = (mdpId: string): Promise<MDPResponseData> => (
  api.get(`/mdp/${mdpId}/`)
);

const GetMDPDetails = (mdpId: string): Promise<MDPDetailsResponseData> => (
  api.get(`/mdp/${mdpId}/details/`)
);

const GetPDPMatches = (mdpId: string): Promise<PDPMatchResponseData> => (
  api.get(`/mdp/${mdpId}/pdpmatches/`)
);

const GetPresbyteryMatches = (mdpId: string, isPresbytery: string): Promise<PDPMatchResponseData> => (
  api.get(`/mdp/${mdpId}/pdpmatches/?isPresbytery=${isPresbytery}`)
);

const GetPDPMatch = (mdpId: string): Promise<PDPMatchResponseData> => (
  api.get(`/mdp/${mdpId}/pdpmatches/`)
);

const UpdatePDPMatch = (pdpMatch: PDPMatchData): Promise<PDPMatchResponseData> => (
  api.put(`/mdp/${pdpMatch.mdpId}/pdpmatches/${pdpMatch.id}/`, pdpMatch)
);

const GetYokedMinistries = (mdpId: string): Promise<CongregationResponseData> => (
  api.get(`/yokedministries/${mdpId}/`)
);

const SubmitYokedMinistry = (organization: CongregationData, mdpId: string): Promise<void> => {
  const payload = new FormData();
  payload.append('pin', organization.pin.toString());
  return api.post(`/yokedministries/${mdpId}/ `, payload);
};

const DeleteYokedMinistry = (mdpId: string, pin: number): Promise<void> => {
  const payload = new FormData();
  payload.append('pin', pin.toString());
  return api.post(`/removeyokedministry/${mdpId}/`, payload);
};

const SubmitMatchAction = (action: ActionData): Promise<void> => {
  const payload = new FormData();
  payload.append('mdpId', action.mdpId.toString());
  payload.append('pdpId', action.pdpId.toString());
  payload.append('actionId', action.action.toString());
  payload.append('start', action.start);
  payload.append('actionKey', action.actionKey);
  return api.post('/action/', payload);
};

const SubmitReferralAction = (action: ActionData): Promise<void> => {
  const payload = new FormData();
  payload.append('mdpId', action.mdpId.toString());
  payload.append('pdpId', action.pdpId.toString());
  payload.append('statusKey', action.statusKey.toString());
  payload.append('accepted', action.accepted.toString());
  return api.post('/referralaction/', payload);
};

const GetActionOptions = (): Promise<MatchActionResponse> => (
  api.get('/action/')
);

const GetPDPMatchProfile = (pdpmatchId: number): Promise<PDPMatchProfileData> => (
  api.get(`pdpmatchprofile/${pdpmatchId}/`)
);

const GetPDPRematches = (mdpId: string): Promise<void> => (
  api.post(`/requestpdprematch/${mdpId}/`)
);

const GetSavedOpportunities = (): Promise<MDPResultListResponseData> => (
  api.get('/savedopportunities/')
);

const SubmitOpportunity = (mdp: MDPResultListData): Promise<string> => {
  const payload = new FormData();
  payload.append('id', mdp.id.toString());
  return api.post('savedopportunity/', payload);
};

const GetAppliedPDPs = (): Promise<PDPMatchListResponseData> => (
  api.get('appliedpdps/')
);

const SubmitAppliedPDPs = (pdplist: AppliedPDP): Promise<void> => {
  const payload = new FormData();
  payload.append('mdpId', pdplist.mdp);
  payload.append('pdp', pdplist.pdp);
  return api.post('appliedpdps/', payload);
};

const DeleteOpportunity = (mdpId: string): Promise<string> => (
  api.delete(`savedopportunity/${mdpId}`)
);

const saveCallCanceledReason = (mdpId: string, callCanceledReason: string): Promise<void> => {
  const payload = new FormData();
  payload.append('reason', callCanceledReason);
  payload.append('mdpId', mdpId);
  return api.post('savecallcanceledreason/', payload);
};
const SaveCallNotification = (mdpId: string, pdpId: string): Promise<number> => {
  const payload = new FormData();
  payload.append('mdpId', mdpId);
  payload.append('pdpId', pdpId);
  return api.post('callnotification/', payload).then((resp) => resp.data);
};

const GetCallNotifications = (): Promise<CompletedCallsResponseData> => (
  api.get('/callnotification/')
);

const CompleteCallNotification = (actionData: ActionData): Promise<void> => (
  api.put('completecallnotification/', actionData)
);

const GetCallSummary = (id: string): Promise<CallSummaryResponseData> => (
  api.get(`/callsummary/${id}/`)
);

const RemoveMDP = (mdpData: MDPData): Promise<void> => (
  api.put('removemdp/', mdpData)
);

const GetMatchData = (mdpId: string): Promise<MatchResponseData> => (
  api.get(`/mdp/${mdpId}/matches/`)
);

const UpdateMatchData = (matchData: MatchData): Promise<void> => (
  api.put(`/mdp/${matchData.mdp}/matches/${matchData.id}/`, matchData)
);

export default {
  Update,
  GetMDPData,
  GetPDPMatches,
  GetPresbyteryMatches,
  GetPDPMatch,
  GetPDPMatchProfile,
  UpdatePDPMatch,
  SubmitMatchAction,
  GetActionOptions,
  GetMDPDetails,
  SubmitReferralAction,
  GetYokedMinistries,
  SubmitYokedMinistry,
  DeleteYokedMinistry,
  GetPDPRematches,
  SubmitOpportunity,
  DeleteOpportunity,
  GetSavedOpportunities,
  SubmitAppliedPDPs,
  GetAppliedPDPs,
  saveCallCanceledReason,
  CompleteCallNotification,
  SaveCallNotification,
  GetCallNotifications,
  GetCallSummary,
  RemoveMDP,
  GetMatchData,
  UpdateMatchData,
};
