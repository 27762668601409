import Accordion from 'react-bootstrap/Accordion';
import {
  FaUsers,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { parseInt } from 'lodash';
import { Role } from '../types/user.types';
import { currentRoleState } from '../services/state.service';
import MDPService from '../services/mdp.service';
import { CompletedCallData, CompletedCallsResponseData } from '../types/mdp.types';
import CallCompletedYear from '../components/CallCompletedYear';

function CompletedCalls(): JSX.Element {
  const { t } = useTranslation();
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [completedCalls, setCompletedCalls] = useState<CompletedCallData[]>([]);
  const [completedCallYears, setCompletedCallYears] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  function CompletedCallYears(calls: CompletedCallData[]): void {
    const years: string[] = [];
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 2;

    calls.forEach((callcompleted) => {
      const callYear = parseInt(callcompleted.year, 10);
      if (callYear >= startYear && callYear <= currentYear && !years.includes(callcompleted.year)) {
        years.push(callcompleted.year);
      }
    });
    setCompletedCallYears(years.sort((a, b) => parseInt(b) - parseInt(a)));
  }

  useEffect(() => {
    if (currentRole.abbreviation) {
      if (currentRole.abbreviation === 'SC') {
        MDPService.GetCallNotifications()
          .then(
            (response: CompletedCallsResponseData) => {
              if (response.data.options.length > 0) {
                setCompletedCalls(response.data.options);
                CompletedCallYears(response.data.options);
              } else {
                setCompletedCalls([]);
              }
              setLoading(false);
            },
          )
          .catch(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [currentRole]);

  const renderCompletedCallsAccordion = () => (
    <Accordion
      className="profile-panel accordion mb-3 mx-auto col-lg-11"
      defaultActiveKey={['0']}
      alwaysOpen
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <FaUsers />
          <span className="accordion-header-label">
            {t('Calls_Completed')}
          </span>
        </Accordion.Header>
        <Accordion.Body>
          <div className="mb-3 mx-auto mobiletable col-lg-12">
            <Accordion
              className="accordion mb-3 mx-auto col-lg-12"
              defaultActiveKey={['0']}
              alwaysOpen
            >
              {completedCallYears.map((year, index) => (
                <CallCompletedYear
                  key={`active-search-year${year}`}
                  eventKey={index.toString()}
                  year={year}
                  completedCalls={completedCalls.filter(
                    (call) => new Date(call.date).getFullYear().toString() === year.toString(),
                  )}
                />
              ))}
            </Accordion>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <div className="text-center mt-3">
          <Spinner
            animation="border"
            size="sm"
          />
        </div>
      );
    }

    if (currentRole.abbreviation === 'SC') {
      if (completedCallYears.length > 0 && completedCalls.length > 0) {
        return renderCompletedCallsAccordion();
      }
      return <h1 className="text-center mt-3">{t('No_Completed_Calls')}</h1>;
    }
    return (
      <div>
        <h1 className="text-center mt-3">{t('Page_Unavailable')}</h1>
      </div>
    );
  };

  return <Container fluid>{renderContent()}</Container>;
}

export default CompletedCalls;
