import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  Button,
  Nav,
  Navbar,
  NavDropdown,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
import { useRecoilState, useSetRecoilState } from 'recoil';
import AuthService from '../../services/auth.service';
import UserService from '../../services/user.service';
import AlertModal from '../generic/AlertModal';
import api from '../../services/api.service';

import {
  isPortalLoginState,
  userProfileState,
  currentRoleState,
  orgPanelState,
} from '../../services/state.service';

import {
  UserProfile,
  Role,
  UserProfileDefaultValue,
  RoleDefaultValue,
} from '../../types/user.types';

type LoginLogoutProps = {
  logoutProcess: any,
  isPortalLogin: boolean,
  backToPortal: any,
  profile: any,
  loginIcon: object,
  t: any,
};

function LoginLogout({
  logoutProcess,
  isPortalLogin,
  backToPortal,
  profile,
  loginIcon,
  t,
}: LoginLogoutProps): JSX.Element {
  let url = '/signup';
  let Icon = FaSignInAlt;
  let text = t('SignUp');
  let onClick = logoutProcess;

  if (isPortalLogin) {
    url = '/';
    Icon = FaSignOutAlt;
    onClick = backToPortal;
    text = t('Navbar.BackToPortal');
  } else if (profile && profile?.isLoggedIn) {
    url = '/';
    Icon = FaSignOutAlt;
    const hijackReturnUrl = localStorage.getItem('hijack_return_url');
    if (hijackReturnUrl) {
      text = 'Release';
      onClick = () => {
        logoutProcess();
        window.location.href = hijackReturnUrl || '/';
      };
    } else {
      text = t('Navbar.Logout');
    }
  }

  return (
    <div className="login-button">
      <Link to={url} onClick={onClick}>
        <IconContext.Provider value={loginIcon}>
          <Icon />
        </IconContext.Provider>
        <span>{text}</span>
      </Link>
    </div>
  );
}
const MemoLoginLogout = React.memo(LoginLogout);

type UserNameProps = {
  profile: any,
  title: string,
  setCurrentRoleState: any,
};

function UserName({
  profile,
  title,
  setCurrentRoleState,
}: UserNameProps): JSX.Element {
  const navigate = useNavigate();
  const setRefreshPanel = useSetRecoilState(orgPanelState);

  function setActiveRole(role: Role): void {
    api.put('/user/profile/', { currentRole: role.id })
      .then(() => setCurrentRoleState(role))
      .then(() => navigate('/'))
      .then(() => setRefreshPanel((r: boolean) => !r));
  }

  if (profile && profile?.isLoggedIn) {
    return (
      <NavDropdown
        title={title}
        id="user-nav-dropdown"
        className="user-name d-inline-block"
      >
        {profile.roles.map((role: Role) => (
          <NavDropdown.Item
            key={role.name}
            onClick={() => setActiveRole(role)}
          >
            {role.name}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    );
  }
  return <div />;
}
const MemoUserName = React.memo(UserName);

function ContactUs(): JSX.Element {
  const { t } = useTranslation();
  const [showContactUs, setShowContactUs] = useState(false);

  return (
    <div>
      <AlertModal
        show={showContactUs}
        title={t('ContactUs')}
        description={t('ContactMessage')}
        closeLabel={t('OK')}
        callback={() => (setShowContactUs(!showContactUs))}
      />
      <Button
        variant="link"
        className="header-button"
        onClick={() => (setShowContactUs(!showContactUs))}
      >
        <span>{t('ContactUs')}</span>
      </Button>
    </div>
  );
}
const MemoContactUs = React.memo(ContactUs);

function Faqs(): JSX.Element {
  return (
    <div className="faq-button">
      <Link to="/faqs">
        <span>FAQs</span>
      </Link>
    </div>
  );
}
const MemoFaqs = React.memo(Faqs);

function OpportunitySearch(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="opp-search-button">
      <Link to="/opportunity-search">
        <span>{t('Opportunity_Search')}</span>
      </Link>
    </div>
  );
}
const MemoOpportunitySearch = React.memo(OpportunitySearch);

function ApplicantPositionReports(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="applicant-report-button">
      <Link to="/applicants-positions">
        <span>{t('Reports')}</span>
      </Link>
    </div>
  );
}
const MemoApplicantPositionReports = React.memo(ApplicantPositionReports);

function Header(): JSX.Element {
  const { t, i18n } = useTranslation();
  const [isPortalLogin, setIsPortalLoginState] = useRecoilState(isPortalLoginState);
  const [profile, setUserProfileState] = useRecoilState<UserProfile>(userProfileState);
  const [currentRole, setCurrentRoleState] = useRecoilState<Role>(currentRoleState);
  const [title, setTitle] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (profile.currentRole && profile.currentRole !== currentRole.id) {
      const role = profile.roles.find((p) => p.id === profile.currentRole);
      if (role) {
        setCurrentRoleState(role);
      }
    }
  }, [profile]);

  useEffect(() => {
    let name = `${profile?.firstName} ${profile?.lastName}`;
    if (currentRole.name !== '') {
      name += `, ${currentRole.name}`;
    }

    setTitle(name);
  }, [currentRole, profile, setTitle]);

  const logoutProcess = useCallback(() => {
    AuthService.Logout();
    setIsPortalLoginState(false);
    setUserProfileState(UserProfileDefaultValue);
    setCurrentRoleState(RoleDefaultValue);
    navigate('/login');
  }, [
    setIsPortalLoginState,
    setUserProfileState,
    setCurrentRoleState,
  ]);

  const backToPortal = (): void => {
    logoutProcess();
    window.location.href = process.env.REACT_APP_PORTAL_URL as string;
  };

  function ChangeLanguage(language: string): void {
    i18n.changeLanguage(language);
    if (profile && profile?.isLoggedIn) {
      UserService.UpdateProfileLanguage(language).then(() => {
        window.location.reload();
      });
    } else {
      localStorage.setItem('language', language);
      window.location.reload();
    }
  }

  const loginIcon = useMemo(() => ({ className: 'login-icon' }), []);

  return (
    <Navbar
      className="clc-nav"
      collapseOnSelect
      expand="xl"
      variant="dark"
    >
      <Navbar.Brand className="clc-logo">
        <Link to="/">
          <img
            alt=""
            src="/logo.png"
            width="60"
            height="60"
            className="d-inline-block align-top"
          />
          {' '}
          <div className="d-none d-sm-inline-block">
            <span className="clc-text-1">
              {t('Navbar.Brand.AppName')}
            </span>
            <br />
            <span className="clc-text-2">
              {t('Navbar.Brand.AppTagLine')}
            </span>
          </div>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle className="printNav" aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="printNav ms-auto">
          <>
            <MemoUserName
              profile={profile}
              title={title}
              setCurrentRoleState={setCurrentRoleState}
            />
            <NavDropdown
              title={t('Navbar.LanguageOptions')}
              id="basic-nav-dropdown"
              data-testid="basic-nav-dropdown"
              className="d-inline-block"
            >
              <NavDropdown.Item
                id="en_button"
                type="button"
                data-testid="en_button"
                onClick={() => ChangeLanguage('en')}
              >
                English
              </NavDropdown.Item>

              <NavDropdown.Item
                id="es_button"
                type="button"
                data-testid="es_button"
                onClick={() => ChangeLanguage('es')}
              >
                Español
              </NavDropdown.Item>

              <NavDropdown.Item
                id="ko_button"
                type="button"
                data-testid="ko_button"
                onClick={() => ChangeLanguage('ko')}
              >
                한국어
              </NavDropdown.Item>

            </NavDropdown>
            {!profile.isLoggedIn && (
              <MemoFaqs />
            )}
            {profile.isLoggedIn && (
              <>
                <MemoOpportunitySearch />
                <MemoApplicantPositionReports />
              </>
            )}
            <MemoContactUs />
            {(!window.location.pathname.includes('/expected')) && (
              <MemoLoginLogout
                logoutProcess={logoutProcess}
                isPortalLogin={isPortalLogin}
                backToPortal={backToPortal}
                profile={profile}
                loginIcon={loginIcon}
                t={t}
              />
            )}
          </>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
