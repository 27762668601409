import {
  useEffect,
  useState,
  Suspense,
  useCallback,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaLongArrowAltLeft, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import PDPService from '../services/pdp.service';
import {
  PDPMatchProfileData,
  PDPMatchProfileDefaultData,
  TrainingEducationDefaultData,
  PDPNarrativeData,
} from '../types/pdp.types';
import {
  PDPMatchData,
  PDPMatchDefaultData,
} from '../types/mdp.types';
import {
  CallingInfoData,
} from '../types/profile.types';
import { getFullName, tryParseInt } from '../utils';
import SuspenseLoading from './generic/SuspenseLoading';
import MDPNotesModal from './MDPNotesModal';
import MDPService from '../services/mdp.service';
import SanitizeHTML from '../services/html.service';
import ProfileInformation, { ProfileInformationProps } from './pdp/ProfileInformation';
import EducationTraining, { EducationTrainingProps } from './pdp/EducationTraining';
import WorkExperience from './pdp/WorkExperience';
import ServiceToTheChurch from './pdp/ServiceToTheChurch';
import StatementOfFaith from './pdp/StatementOfFaith';
import PDPNarrative from './pdp/PDPNarratives';
import PDPOptionalLinks from './pdp/PDPOptionalLinks';
import PDPReferences from './pdp/PDPReferences';
import SexualMisconduct from './pdp/SexualMisconduct';

interface PDPMatchDetailsProps {
  pdpId?: number,
  matchId?: number,
  mdpId?: string
}

function PDPMatchDetails({ pdpId, matchId, mdpId }: PDPMatchDetailsProps): JSX.Element {
  const { t } = useTranslation();
  const [pdpMatchData, setPDPMatchData] = useState<PDPMatchData>(PDPMatchDefaultData);
  const [pdpMatchProfile, setPDPMatchProfileState] = useState<PDPMatchProfileData>(PDPMatchProfileDefaultData);
  const [eccInformation, setECCInformation] = useState<CallingInfoData[]>([]);
  const [showMDPNotes, setShowMDPNotes] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [displayPrint, setDisplayPrint] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlData = {
    pdpId: pdpId || tryParseInt(params.get('pdp_id') || '') || 0,
    matchId: matchId || tryParseInt(params.get('match_id') || '') || 0,
    mdpId: mdpId || params.get('mdp_id') || '',
  };
  const [profileInfo, setProfileInfo] = useState<ProfileInformationProps | null>(null);
  const [educationInfo, setEducationInfo] = useState<EducationTrainingProps>(TrainingEducationDefaultData);

  useEffect(() => {
    Promise.all([
      MDPService.GetPDPMatchProfile(urlData.matchId),
      PDPService.GetPDPCallingInfo(urlData.matchId.toString()),
      MDPService.GetPDPMatch(urlData.mdpId),
    ]).then(([profile, callingInfo, matchData]) => {
      const profileData = profile.data.options as PDPMatchProfileData;
      const narratives: PDPNarrativeData[] = [];
      profileData.narratives.forEach((narrative: PDPNarrativeData) => {
        const trimmedNarrative = { ...narrative, positionTypes: '' };
        narratives.push(trimmedNarrative);
      });
      setPDPMatchProfileState({ ...profileData, narratives });
      setECCInformation(callingInfo.data.options);
      setPDPMatchData(matchData.data.filter((i) => i.id === urlData.matchId)[0]);
      setDisplayPrint(true);
    });
  }, [refreshData]);

  useEffect(() => {
    if (pdpMatchProfile.userProfile?.firstName && eccInformation.length > 0) {
      setProfileInfo({
        header: t('Personal_Information'),
        pdpId: pdpMatchProfile.pdpId.toString(),
        fullName: getFullName(pdpMatchProfile.userProfile),
        email: pdpMatchProfile.userProfile.email,
        pronouns: pdpMatchProfile.userProfile.pronouns,
        addressOne: pdpMatchProfile.userProfile.addressOne,
        fullAddress: pdpMatchProfile.userProfile.fullAddress,
        phoneOne: pdpMatchProfile.userProfile.phoneOne,
        phoneTwo: pdpMatchProfile.userProfile.phoneTwo,
        eccStatusDescription: eccInformation[0].eccStatusDescription,
        presbyteryNonOrdainedDescription: eccInformation[0].presbyteryNonOrdainedDescription,
        congregationDescription: eccInformation[0].congregationDescription,
        presbyteryDescription: eccInformation[0].presbyteryDescription,
        ordinationDate: eccInformation[0].ordinationDate,
      });
    }
  }, [pdpMatchProfile, eccInformation]);

  useEffect(() => {
    setEducationInfo({
      formalEducation: pdpMatchProfile.formalEducation,
      trainingCertification: pdpMatchProfile.trainingCertification,
      continuingEducation: pdpMatchProfile.continuingEducation,
      skills: pdpMatchProfile.skills,
    });
  }, [pdpMatchProfile]);

  const closeNewEdit = useCallback((result: boolean, data: PDPMatchData): void => {
    if (result && data) {
      MDPService.UpdatePDPMatch(data).then(() => {
        setRefreshData((r: boolean) => !r);
        setShowMDPNotes(false);
      });
    } else {
      setShowMDPNotes(false);
    }
  }, []);

  function RenderPreferences(): JSX.Element {
    return (
      <div className="row">
        <div className="col-6">
          <div className="my-4 mx-3">
            <div className="mb-2 reviewtitle">
              {t('PDP.Employment_Type')}
            </div>
            {pdpMatchProfile.employmentType}
          </div>
        </div>
        <div className="col-6">
          <div className="my-4  mx-3">
            <div className="mb-2 reviewtitle">
              {t('PDP.Min_Expected_Salary')}
            </div>
            {pdpMatchProfile.minExpectedSalary}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <MDPNotesModal
        matchNote={pdpMatchData}
        show={showMDPNotes}
        saveMDPNotes={closeNewEdit}
      />
      <div className="mt-4 col-lg-10 col-11 mx-auto">
        <Link to={location.pathname.replace('pdpdetails', 'matches')} className="dashboard-link">
          <FaLongArrowAltLeft />
          <span>{t('Back_To_Matching_Results')}</span>
        </Link>
        {
          displayPrint && (
            <div className="text-end">
              <Button
                className="mb-3 ms-auto printbutton"
                variant="primary"
                size="sm"
                active
                onClick={() => (window.print())}
              >
                <FaEdit />
                {` ${t('Print_Btn')}`}
              </Button>
            </div>
          )
        }
        <div className="mt-3 title text-center">
          {t('Matching_PDP_Details')}
          <br />
          ID#&nbsp;
          { urlData.pdpId }
        </div>
        <div className="mx-auto col-12">
          <PDPNarrative narratives={pdpMatchProfile.narratives || []} />
          {profileInfo?.pdpId && <ProfileInformation profileInfo={profileInfo} />}

          {/* Preferences */}
          <div className="panel-header">
            <div className="row">
              <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4">
                {t('PDP.Preferences')}
              </div>
            </div>
            <hr className="mdpdivider" />
            <Suspense fallback={<SuspenseLoading />}>
              <RenderPreferences />
            </Suspense>
          </div>

          <EducationTraining educationTraining={educationInfo} />
          <WorkExperience workExperience={pdpMatchProfile.workExperience} />
          <ServiceToTheChurch service={pdpMatchProfile.service} />
          {pdpMatchProfile.statementOfFaith && (
            <StatementOfFaith statementOfFaith={pdpMatchProfile.statementOfFaith} />
          )}
          {pdpMatchProfile.optionalLinks?.length > 0 && (
            <PDPOptionalLinks optionalLinks={pdpMatchProfile.optionalLinks} />
          )}
          {pdpMatchProfile.reference?.length > 0 && (
            <PDPReferences references={pdpMatchProfile.reference} />
          )}
          {eccInformation.length > 0 && (
            <SexualMisconduct sexualMisconduct={eccInformation[0]} />
          )}

          {/* MDP Notes */}
          <div className="border border-dark rounded-3 text-start mb-4 mdp-notes">
            <div className="row">
              <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4 d-flex justify-content-between align-items-center">
                {t('MDP_Notes')}
                <Button
                  type="button"
                  className="me-4 ms-auto createbutton note-btn"
                  variant="primary"
                  size="sm"
                  active
                  onClick={() => { setShowMDPNotes(true); }}
                >
                  {t('Edit_Note')}
                </Button>
              </div>
            </div>
            <hr className="mdpdivider" />
            <div className="my-4 mx-3">
              <div className="row">
                {pdpMatchProfile?.note ? (
                  <div className="mb-2 reviewtitle">
                    <SanitizeHTML html={pdpMatchProfile.note.replaceAll('\n', '<br>')} />
                  </div>
                ) : (
                  <div className="mb-2 reviewtitle text-center">
                    {t('CommitteeNotes_Placeholder')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PDPMatchDetails.defaultProps = {
  pdpId: 0,
  matchId: 0,
  mdpId: '',
};

export default PDPMatchDetails;
