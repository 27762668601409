import { IData, SelectOption } from './generic.types';
import { MIN_SALARY } from './constants';
import { CompensationData, IPositionTypeList, RequirementsData } from '../services/mdp.service';
import { ReferenceData } from './pdp.types';
import { MinistryProfile } from './ministry.types';
import { UserProfile } from './user.types';

export interface MDPData {
  id: string,
  organization: number | undefined,
  organizationName: string,
  organizationType: string,
  committee: number | undefined,
  submitted: boolean,
  authorized: boolean,
  acceptEEO: boolean,
  clerkApproval: boolean,
  comApproval: boolean,
  clcApproval: boolean,
  positionType: string,
  positionTitle: string,
  employmentType: string,
  hasMatches: boolean,
  released: string,
  city: string,
  state: string,
  zip: string,
  callingStart: string
  openTo: string,
  archived: boolean,
  isExternalCall: boolean,
  isRemoved: boolean,
}

export interface MDPResultListData {
  id: string,
  positionType: number,
  positionTitle: string,
  experienceLevel: string,
  employmentType: string,
  organizationName: string,
  organizationType: string,
  presbytery: string,
  city: string,
  state: string,
  communityType: string,
  congregationSize: string,
  minimumExpectedSalary: number,
  clerkApproval: string,
  comApproval: string,
  status: string,
  deadline: string,
  released: string,
  pageNumber: number,
}

export interface MDPResponseData {
  data: MDPData,
}

export const MDPResultListDefaultData: MDPResultListData = {
  id: '',
  positionType: 0,
  positionTitle: '',
  employmentType: '',
  experienceLevel: '',
  organizationName: '',
  organizationType: '',
  presbytery: '',
  city: '',
  state: '',
  communityType: '',
  congregationSize: '',
  minimumExpectedSalary: 0,
  clerkApproval: '',
  comApproval: '',
  status: '',
  deadline: '',
  released: '',
  pageNumber: 1,
};

export const MDPDefaultData: MDPData = {
  id: '',
  organization: undefined,
  organizationName: '',
  organizationType: '',
  committee: undefined,
  submitted: false,
  authorized: false,
  acceptEEO: false,
  clerkApproval: false,
  comApproval: false,
  clcApproval: false,
  positionType: '',
  positionTitle: '',
  employmentType: '',
  hasMatches: false,
  released: '',
  city: '',
  state: '',
  zip: '',
  callingStart: '',
  openTo: '',
  archived: false,
  isExternalCall: false,
  isRemoved: false,
};

export interface FavoriteData {
  id: string
}

export const FavoriteDefaultData: FavoriteData = {
  id: '',
};

export interface FavoriteResponseData {
  data: FavoriteData[]
}

export interface MDPListResponseData {
  data: MDPData[],
}

export interface MDPResultListResponseData {
  data: {
    options: MDPResultListData[],
    totalPages: number,
    totalResults: number
  }
}

export interface MDPDetailsResponseData {
  data: MDPResultListData[],
}

export interface MinistryRequirementsData {
  id: number | undefined,
  vision: string,
  tasks: string,
  initial: boolean,
}

export interface MinistryRequirementsResponseData {
  data: MinistryRequirementsData[],
}

export interface PNCChairData extends IData {
  id: number,
  mdp: string | undefined,
  name: string | undefined,
  prefPhone: string | undefined,
  altPhone: string | undefined,
  fax: string | undefined,
  email: string | undefined,
  addressOne: string | undefined,
  addressTwo: string | undefined,
  city: string | undefined,
  state: string | undefined,
  zip: number | undefined,
}

export interface SelfReferralContactData extends IData {
  EPDetails: SelfReferralData | undefined,
  COMDetails: SelfReferralData | undefined,
}

export interface SelfReferralData extends IData {
  name: string,
  phone: string,
  email: string,
}

export const PNCChairDefaultData: PNCChairData = {
  id: 0,
  mdp: undefined,
  name: undefined,
  prefPhone: undefined,
  altPhone: undefined,
  fax: undefined,
  email: undefined,
  addressOne: undefined,
  addressTwo: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
};

export const SelfReferralContactDefaultData:SelfReferralContactData = {
  EPDetails: undefined,
  COMDetails: undefined,
};

export interface SelfReferralContactResponseData {
  data: {
    options: SelfReferralContactData
  }
}

export interface PNCChairResponseData {
  data: PNCChairData[],
}

export interface ImportPNCCHairResponseData {
  data: {
    options: PNCChairData,
  }
}

export interface MDPReferenceData extends IData {
  refId: number | undefined,
  mdp: number | undefined,
  name: string,
  relation: string,
  phone: string,
  email: string,
}

export const MDPReferenceDefaultData: MDPReferenceData = {
  refId: undefined,
  mdp: undefined,
  name: '',
  relation: '',
  phone: '',
  email: '',
};

export interface MDPReferenceResponseData {
  data: MDPReferenceData[],
}

export interface CertificateData {
  id: string,
  description: string,
}

export interface CertificateResponseData {
  data: {
    options: CertificateData[],
  }
}

export interface MDPNarrativeData {
  id: number,
  mdp: number,
  question: string,
  displayOrder: number,
  response: string | undefined,
}

export interface MDPNarrativesResponseData {
  data: MDPNarrativeData[]
}

export interface PositionTypes {
  id: number,
}

export interface MatchData {
  id: number,
  mdp: number,
  employmentType: string,
  matchLanguages: string[],
  positionTypes: IPositionTypeList[] | number[],
  maximumSalary: number,
  matchCharacteristics: MatchCharacteristicData[],
  disableNoMatchingWithinPresbytery: boolean,
  noMatchingWithinPresbytery: boolean,
  matchWithinPresbytery: boolean,
  matchWithinState: boolean,
  pdps: PDPMatchData[],
}

export interface MatchResponseData {
  data: MatchData[]
}

export const MatchDefaultData: MatchData = {
  id: 0,
  mdp: 0,
  employmentType: '',
  positionTypes: [],
  matchLanguages: [],
  maximumSalary: MIN_SALARY,
  matchCharacteristics: [],
  disableNoMatchingWithinPresbytery: false,
  noMatchingWithinPresbytery: false,
  matchWithinState: false,
  matchWithinPresbytery: false,
  pdps: [],
};

export interface PDPMatchData {
  id: number,
  pdp: number,
  callSeekerName: string,
  match: number,
  organizationName: string,
  city: string,
  state: string,
  employmentType: string,
  mdpEmploymentType: string,
  positionTypes: [],
  mdpPositionType: string,
  mdpPositionTitle: string,
  mdpExperienceLevel: string,
  statusId: number,
  status: string,
  statusKey: string,
  score: number,
  mdpId: number,
  note: string,
  referredBy: string,
  selfReferred: boolean,
  selectedAction: number,
  selectedActionKey: string,
  callingStart: string,
  createdAt: string,
  compatibilityLevel: number,
  newMatch: boolean,
  experienceLevelSortOrder: number,
}

export interface PDPMatchResponseData {
  data: PDPMatchData[]
}

export interface PDPMatchListResponseData {
  data: {
    'options': PDPMatchData[]
  }
}

export const PDPMatchDefaultData: PDPMatchData = {
  id: 0,
  pdp: 0,
  callSeekerName: '',
  match: 0,
  organizationName: '',
  city: '',
  state: '',
  employmentType: '',
  mdpEmploymentType: '',
  positionTypes: [],
  mdpPositionType: '',
  mdpPositionTitle: '',
  mdpExperienceLevel: '',
  statusId: 0,
  status: '',
  statusKey: '',
  score: 0,
  mdpId: 0,
  note: '',
  referredBy: '',
  selfReferred: false,
  selectedAction: 0,
  selectedActionKey: '',
  callingStart: '',
  createdAt: '',
  compatibilityLevel: 0,
  newMatch: true,
  experienceLevelSortOrder: 0,
};

export interface MatchCharacteristicData {
  id: number,
  characteristic: number,
  characteristicType: string,
  desiredScore: number,
  description: string,
  question: string,
}

export interface PresbyteryMatchingOptionData {
  id: number,
  matchingChoice: string | undefined,
  matchByCommittee: (number | undefined)[],
  matchByOrdainedPositions: boolean | undefined,
}

export const PresbyteryMatchingOptionDefaultData: PresbyteryMatchingOptionData = {
  id: 0,
  matchingChoice: '',
  matchByCommittee: [],
  matchByOrdainedPositions: undefined,
};

export interface PresbyteryMatchingOptionResponseData {
  data: {
    options: PresbyteryMatchingOptionData,
  }
}

export const MatchCharacteristicDefaultData: MatchCharacteristicData = {
  id: 0,
  characteristic: 0,
  characteristicType: '',
  desiredScore: 0,
  description: '',
  question: '',
};

export interface MatchActionResponse {
  data: {
    options: MatchAction[],
  }
}

export interface MatchAction {
  id: number,
  description: string,
  actionKey: string,
}

export const MatchActionDefaultData: MatchAction = {
  id: 0,
  description: '',
  actionKey: '',
};

export interface ActionData {
  id: number,
  mdpId: number,
  matchId: number,
  pdpId: number,
  action: number,
  actionKey: string,
  statusKey: string,
  accepted: string,
  start: string,
  pdfBlob: string | ArrayBuffer | null,
}

export const ActionDefaultData: ActionData = {
  id: 0,
  mdpId: 0,
  matchId: 0,
  pdpId: 0,
  action: 0,
  actionKey: '',
  statusKey: '',
  accepted: '',
  start: '',
  pdfBlob: null,
};

export interface ActionDataResponseData {
  data: {
    options: ActionData[],
  }
}

export interface OpportunitySearchQueryData {
  positionTypes: IPositionTypeList[] | number[],
  experienceLevel: number,
  congregationSize: number,
  communityType: number,
  employmentType: number,
  minExpectedSalary: number,
  housingType: number,
  city: string,
  states: SelectOption[] | number[],
  presbytery: SelectOption[] | string,
  church: string,
  organization: string,
  intercultural: string,
  pageNumber: number
  sortMDPs: boolean
}

export interface ViewMDPData {
  requirements: RequirementsData,
  compensation: CompensationData,
  ministryRequirements: MinistryRequirementsData,
  references: ReferenceData,
  chairContact: PNCChairData,
  ministryProfile: MinistryProfile,
  narratives: MDPNarrativeData[],
  yokedMinistries: MinistryProfile[],
  releasedDate: string,
}

export interface ViewMDPResponseData {
  data: ViewMDPData,
}

export interface ReferData {
  pdp_id: string,
  mdp_id: string,
}

export interface PresbyteryInfoData {
  presbytery: string,
  users: UserProfile[] | undefined,
}

export const PresbyteryInfoDefaultData: PresbyteryInfoData = {
  presbytery: '',
  users: [],
};

export interface PresbyteryInfoResponseData {
  data: {
    options: PresbyteryInfoData,
  }
}

export interface MDPOptionalLinkData extends IData {
  id: number,
  mdp: number,
  linkTitle: string,
  linkDescription: string,
  linkUrl: string,
}

export interface MDPOptionalLinkResponseData {
  data: MDPOptionalLinkData[],
}

export interface CallCompletedData {
  pdpId: string,
  mdpId: string,
  isCalled: boolean,
}

export const CallCompletedDefaultData: CallCompletedData = {
  pdpId: '',
  mdpId: '',
  isCalled: false,
};

export interface PDPCalledData {
  pdpId: number,
  isPDPCalled: boolean | undefined,
  startDate: string | undefined,
}

export interface CompletedCallData {
  id: number
  mdpId: number
  date: string
  organization: string
  callSeeker: string
  year: string
}

export interface CompletedCallsResponseData {
  data: {
    options: CompletedCallData[],
  }
}

export interface AppliedMDP {
  mdpId: string,
}

export interface ActiveMDPListResponseData {
  data: {
    options: MDPData[],
  }
}

export interface ExternalCallData {
  mdpId: string | undefined,
  orgName: string | undefined,
  orgCity: string | undefined,
  orgState: string | undefined,
  orgZip: string | undefined,
  callStartDate: string | undefined,
  firstName: string | undefined,
  middleName: string | undefined,
  lastName: string | undefined,
  email: string | undefined,
  phoneOne: string | undefined,
  ecclesiasticalStatus: string | undefined,
  organization: string | undefined,
}

export const ExternalCallDefaultData: ExternalCallData = {
  mdpId: '',
  orgName: '',
  orgCity: '',
  orgState: '',
  orgZip: '',
  callStartDate: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phoneOne: '',
  ecclesiasticalStatus: '',
  organization: '',
};

export interface CallSummaryResponseData {
  data: CallSummaryData,
}

export interface CallSummaryData {
  positionLanguages: string[];
  trainingCerts: string[];
  languageProficiencies: string[];
  housingTypes: string[];
  mdpHousingType: string
  employmentType: string;
  positionTitle: string;
  minEffectiveSalary: string;
  callingStartDate: string;
  releasedDate: string;
  minExpectedSalary: string;
  maxExpectedSalary: string;
  experienceLevel: string;
  employmentStatus: string;
  positionType: string;
  congregationInfo: string;
  presbytery: string;
  synod: string;
  id: string;
  mdpId: string;
  pdpId: string;
  callSeekerName: string;
  email: string;
  ecclesiasticalStatus: string;
  organization: string;
  callSeekerCity: string;
  callSeekerState: string;
  callSeekerZip: string;
  birthYear: string;
  gender: string;
  race: string;
  phoneOne: string;
  ministryName: string;
  stateZip: string;
  communityType: string;
  organizationSize: string;
  interculturalComposition: string;
  presbyteryOfMembership: string;
}

export const CallSummaryDefaultData: CallSummaryData = {
  positionLanguages: [],
  trainingCerts: [],
  languageProficiencies: [],
  housingTypes: [],
  mdpHousingType: '',
  employmentType: '',
  positionTitle: '',
  minEffectiveSalary: '',
  callingStartDate: '',
  releasedDate: '',
  minExpectedSalary: '',
  maxExpectedSalary: '',
  experienceLevel: '',
  employmentStatus: '',
  positionType: '',
  congregationInfo: '',
  presbytery: '',
  synod: '',
  id: '',
  mdpId: '',
  pdpId: '',
  callSeekerName: '',
  email: '',
  ecclesiasticalStatus: '',
  organization: '',
  callSeekerCity: '',
  callSeekerState: '',
  callSeekerZip: '',
  birthYear: '',
  gender: '',
  race: '',
  phoneOne: '',
  ministryName: '',
  stateZip: '',
  communityType: '',
  organizationSize: '',
  interculturalComposition: '',
  presbyteryOfMembership: '',
};
