import {
  Suspense,
  useEffect,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../types/generic.types';
import { currentRoleState, allPositionTypeListState } from '../services/state.service';
import { Role } from '../types/user.types';
import { CLC_FUNCTION } from '../types/constants';
import SuspenseLoading from './generic/SuspenseLoading';
import { IPositionTypeList } from '../services/mdp.service';
import AutoFillSelect from './AutoFillSelect';

function MDPPositionTypeTable({ control }:{ control: any }): JSX.Element {
  const { t } = useTranslation();
  const positionTypeList = useRecoilValue<IPositionTypeList[]>(allPositionTypeListState);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [advancedSearch, setAdvancedSearch] = useState<boolean>(false);

  useEffect(() => {
    setAdvancedSearch(currentRole.functions.indexOf(CLC_FUNCTION.advancedSearch) > -1);
  }, [currentRole]);

  return (
    <Suspense fallback={<SuspenseLoading />}>
      <div>
        <AutoFillSelect
          id="positionTypes"
          label={t('Position_Types')}
          placeholder={t('Select_Position')}
          options={positionTypeList.map((p) => ({ id: p.id, value: p.value })) as SelectOption[]}
          control={control}
          multiple
          readOnly={!advancedSearch}
          required
        />
      </div>
    </Suspense>
  );
}

export default MDPPositionTypeTable;
