import React from 'react';
import { useTranslation } from 'react-i18next';
import { CallingInfoData, PersonalInfoData } from '../../types/profile.types';
import { AttestationPDPProfileData } from '../../types/pdp.types';

const SexualMisconduct: React.FC<{
  sexualMisconduct: CallingInfoData | AttestationPDPProfileData | PersonalInfoData
}> = ({ sexualMisconduct }) => {
  const { t } = useTranslation();
  const {
    noComplaint,
    comments,
    certificationAccepted,
  } = sexualMisconduct;

  return (
    <div className="border border-dark rounded-3 text-start mb-4">
      <div className="row">
        <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4">
          {t('PDP.Misconduct_Cert_Title')}
        </div>
      </div>
      <hr className="mdpdivider" />
      <div className="row">
        <div className="m-3 col-12 text-wrap">
          {noComplaint ? (
            <div>{t('PDP.Misconduct_Certify')}</div>
          ) : (
            <>
              <div>{t('PDP.Misconduct_Unable')}</div>
              <br />
              <div>{comments}</div>
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="m-3 col-12 text-wrap">
          {certificationAccepted && (
            <div>{t('PDP.Misconduct_Certification')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SexualMisconduct;
