import {
  useState,
  useEffect,
  useCallback,
  Suspense,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  MDPData,
  MDPListResponseData,
  MatchData,
  MatchResponseData,
  PDPMatchResponseData,
  ReferData,
  MatchDefaultData,
} from '../types/mdp.types';
import api from '../services/api.service';
import AddButton from '../components/generic/AddButton';
import ViewButton from '../components/generic/ViewButton';
import PrintButton from '../components/generic/PrintButton';
import ReferButton from '../components/generic/ReferButton';
import ManageMatchingModal from '../components/ManageMatchingModal';
import {
  currentRoleState,
  matchState,
  mdpPanelState,
} from '../services/state.service';
import MDPService from '../services/mdp.service';
import withUserAllowed from '../components/layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import { Role } from '../types/user.types';
import AlertModal from '../components/generic/AlertModal';
import ReferModal from '../components/ReferModal';
import CancelCallButton from '../components/generic/CancelCallButton';
import CancelCallNotificationModal from '../components/CancelCallNotificationModal';
import { isCallPending, isCallCompleted } from '../utils';
import SuspenseLoading from '../components/generic/SuspenseLoading';

function MDPMidCouncilPanel(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setMatch] = useRecoilState<MatchData>(matchState);
  const [currentMatch, setCurrentMatch] = useState<MatchData>(MatchDefaultData);
  const [list, setList] = useState<MDPData[]>([]);
  const [refreshList, setRefreshList] = useState(false);
  const [showNoMatchModal, setShowNoMatchModal] = useState(false);
  const [showManageMatchModal, setShowManageMatchModal] = useState(false);
  const [showReferModal, setShowReferModal] = useState(false);
  const [showPDPReferredModal, setShowPDPReferredModal] = useState(false);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [advancedSearch, setAdvancedSearch] = useState<boolean>(false);
  const [currentMDP, setCurrentMDP] = useState<string>('');
  const [refreshPanel, setRefreshPanel] = useRecoilState(mdpPanelState);
  const [showCancelCallModal, setShowCancelCallModal] = useState(false);
  const [cancelCallOrg, setCancelCallOrg] = useState<string>('');

  useEffect(() => {
    setAdvancedSearch(currentRole.functions.indexOf(CLC_FUNCTION.advancedSearch) > -1);
  }, [currentRole]);

  useEffect(() => {
    if (currentRole.organizationId) {
      api.get(`/mdp/?organization=${currentRole.organizationId}`).then(
        (response: MDPListResponseData) => {
          if (response.data.length > 0) {
            setList(response.data.sort((a, b) => (parseInt(a.id, 10) - parseInt(b.id, 10))));
          } else {
            setList([]);
          }
        },
      );
    }
  }, [currentRole, refreshList, refreshPanel]);

  const closeNewEdit = useCallback((result: boolean, match: MatchData): void => {
    if (result && match) {
      if (match.id > 0) {
        MDPService.UpdateMatchData(match).then(() => {
          MDPService.GetPDPMatches(match.mdp.toString())
            .then((response: PDPMatchResponseData) => {
              if (response.data.length === 0) {
                setShowNoMatchModal(true);
              } else {
                setShowManageMatchModal(false);
                setRefreshList((r: boolean) => !r);
              }
            });
        });
      }
    } else {
      setShowManageMatchModal(false);
    }
  }, []);

  const closeRefer = useCallback((result: boolean, referral: ReferData): void => {
    if (result && referral.pdp_id && referral.mdp_id) {
      const payload = new FormData();
      payload.append('pdp_id', referral.pdp_id);
      payload.append('mdp_id', referral.mdp_id);
      api.post('/refer/', payload).then(() => {
        setShowReferModal(false);
        setCurrentMDP('');
        setShowPDPReferredModal(true);
        setRefreshList((r: boolean) => !r);
      });
    } else {
      setShowReferModal(false);
      setCurrentMDP('');
    }
  }, []);

  const refer = (id: string): void => {
    setCurrentMDP(id);
    setShowReferModal(true);
  };

  const getMatches = (id: string): void => {
    api.get(`/mdp/${id}/matches/`).then(
      (response: MatchResponseData) => {
        if (response.data) {
          const result = response.data[0];
          const matchData = {
            ...result,
            matchCharacteristics: result.matchCharacteristics.sort((a, b) => a.characteristic - b.characteristic),
          };
          setMatch(matchData);
          setCurrentMatch(matchData);
          setShowManageMatchModal(true);
        }
      },
    );
  };

  const saveCallCanceledReason = useCallback((isSave: boolean, reason: string, mdpId: string): void => {
    if (isSave) {
      MDPService.saveCallCanceledReason(mdpId, reason).then(() => {
        setRefreshList((r: boolean) => !r);
        setRefreshPanel((r: boolean) => !r);
      });
    }
    setShowCancelCallModal(false);
  }, []);

  function isCalled(mdp: MDPData): boolean {
    if (isCallCompleted(mdp.callingStart)) {
      return true;
    }

    if (isCallPending(mdp.callingStart)) {
      return true;
    }

    return false;
  }

  function renderMatchButtons(option: MDPData): any {
    return option.hasMatches ? (
      <>
        {(!isCallCompleted(option.callingStart) || option.callingStart === '') && (
          <ViewButton
            label=""
            handler={() => (
              navigate(`/mdp/${(option.id)}/matches/`)
            )}
          />
        )}
        {(!isCalled(option) || option.callingStart === '') && (
          <ReferButton
            label=""
            handler={() => {
              refer(option.id);
            }}
          />
        )}
      </>
    ) : (
      <>
        <AddButton
          label=""
          handler={() => {
            getMatches(option.id);
          }}
        />
        <ReferButton
          label=""
          handler={() => {
            refer(option.id);
          }}
        />
      </>
    );
  }

  function renderPrintButton(id: string): any {
    return (
      <PrintButton
        label=""
        handler={() => (
          navigate(`/mdp/${id}/view/`)
        )}
      />
    );
  }

  function renderCancelCallButton(option: MDPData): JSX.Element {
    return (
      <CancelCallButton
        label=""
        handler={() => {
          setShowCancelCallModal(true);
          setCurrentMDP(option.id);
          setCancelCallOrg(option.organizationName);
        }}
      />
    );
  }

  function getStatus(mdp: MDPData): string {
    if (!mdp.submitted) {
      return t('InProgress');
    }

    if (mdp.submitted && !mdp.authorized) {
      return t('Submitted');
    }

    if (isCallCompleted(mdp.callingStart)) {
      return t('Calling_Notify_Completed');
    }

    if (isCallPending(mdp.callingStart)) {
      return t('Calling_Notify_Pending');
    }

    return t('Released');
  }

  function renderPDPTable(pdpList: MDPData[], advSearch: boolean): JSX.Element {
    return (
      <tbody>
        {pdpList?.map((option: MDPData) => (
          <tr key={option.id} className="dividinglines">
            <td>{option.id}</td>
            <td>
              {option.positionType ? option.positionType : ''}
              {option.positionTitle ? ` (${option.positionTitle})` : ''}
            </td>
            <td>{option.organizationName ? option.organizationName : '' }</td>
            <td>{getStatus(option)}</td>
            <td>
              <div>
                {renderPrintButton(option.id)}
                {advSearch && renderMatchButtons(option)}
                {(currentRole.functions.indexOf(CLC_FUNCTION.manageMatching) > -1
                  && isCallPending(option.callingStart)) && renderCancelCallButton(option)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-12">
      <AlertModal
        show={showNoMatchModal}
        title={t('NoMatch')}
        description={t('NoMatchDescription')}
        closeLabel={t('OK')}
        callback={() => setShowNoMatchModal(!showNoMatchModal)}
      />
      <AlertModal
        show={showPDPReferredModal}
        title={t('PDP_Referred')}
        description={t('PDP_Referred_Description')}
        closeLabel={t('OK')}
        callback={() => setShowPDPReferredModal(!showPDPReferredModal)}
      />
      <ReferModal
        mdp={currentMDP}
        callback={closeRefer}
        show={showReferModal}
        hide={() => { setShowReferModal(false); setRefreshList((r: boolean) => !r); }}
      />
      <CancelCallNotificationModal
        mdpId={currentMDP}
        show={showCancelCallModal}
        description={t('Cancel_Call_Warning_MDP', { organization: cancelCallOrg })}
        callback={saveCallCanceledReason}
      />
      <Suspense fallback={<SuspenseLoading />}>
        <ManageMatchingModal
          callback={closeNewEdit}
          show={showManageMatchModal}
          hide={() => setShowManageMatchModal(false)}
          currentMatch={currentMatch}
        />
      </Suspense>
      <div className="border border-dark rounded-3">
        <Table responsive hover className="mb-3 linktable">
          <thead>
            <tr className="linktableheader">
              <th>{t('Links_ID')}</th>
              <th>{t('PDP.Position')}</th>
              <th>{t('Organization')}</th>
              <th>{t('Status')}</th>
              <th>{t('Links_Table_Actions')}</th>
            </tr>
          </thead>
          { renderPDPTable(list, advancedSearch) }
        </Table>
      </div>
    </div>
  );
}

export default withUserAllowed(CLC_FUNCTION.approveMDP)(MDPMidCouncilPanel);
