import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type MatchResponseModalProps = {
  show: boolean,
  hide: () => void,
  showMatchResponse: string,
}

function MatchResponseModal({ show, hide, showMatchResponse }: MatchResponseModalProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={hide}
      animation={false}
      className="modalstyle"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Request_Rematch_Results')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-md-12 text-center my-5">
          {showMatchResponse}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-md-12 text-center">
          <Button
            className="px-5 "
            variant="primary"
            onClick={hide}
          >
            {t('OK')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default MatchResponseModal;
