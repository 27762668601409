import Form from 'react-bootstrap/Form';

const getElement = (text: string): JSX.Element => {
  if (typeof text === 'string' && text.indexOf('http') > -1) {
    return <a href={text}>{text}</a>;
  }

  return <span>{text}</span>;
};

// Form.Text is used as the label to match the FloatingLabel style
function DisplayField({ label, text }: { label: string, text: string }): JSX.Element {
  return (
    <Form.Group className="text-start">
      <Form.Text>
        {label}
      </Form.Text>
      {label && <br />}
      <Form.Label>
        {getElement(text)}
      </Form.Label>
    </Form.Group>
  );
}

export default DisplayField;
