import {
  Modal,
  Form,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import {
  MDPData,
  ExternalCallData,
  ExternalCallDefaultData,
} from '../types/mdp.types';

export interface MDPOrgData {
  ministryName: string,
  city: string,
  state: string,
  zip: string,
}

function NotifyExternalCallModal({
  show,
  hide,
  mdpList,
  callback,
}:{
  show: boolean,
  hide: () => void,
  mdpList: MDPData[],
  callback: (_result: boolean, _externalCallData: ExternalCallData) => void,
}): JSX.Element {
  const { t } = useTranslation();
  const [valid, setValid] = useState<boolean>(false);
  const [externalCallInfo, setExternalCallInfo] = useState<ExternalCallData>(ExternalCallDefaultData);
  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: {
      errors,
    },
  } = useForm<ExternalCallData>({
    defaultValues: externalCallInfo,
  });
  const currentFirstName = watch('firstName');
  const currentMiddleName = watch('middleName');
  const currentLastName = watch('lastName');
  const currentEmail = watch('email');
  const currentPhoneOne = watch('phoneOne');
  const currentOrganization = watch('organization');
  const currentEcclesiasticalStatus = watch('ecclesiasticalStatus');
  const currentCallingStartDate = watch('callStartDate');

  const onSubmit: SubmitHandler<ExternalCallData> = () => {
    callback(true, externalCallInfo);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    const selectedMDP = mdpList.find((mdp: MDPData) => parseInt(mdp.id, 10) === parseInt(value, 10));
    if (selectedMDP) {
      setExternalCallInfo((e) => ({
        ...e,
        mdpId: value,
        orgName: selectedMDP?.organizationName,
        orgCity: selectedMDP?.city,
        orgState: selectedMDP?.state,
        orgZip: selectedMDP?.zip,
        callStartDate: selectedMDP?.callingStart,
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phoneOne: '',
        organization: '',
        ecclesiasticalStatus: '',
      }));
    } else {
      setExternalCallInfo(ExternalCallDefaultData);
    }
  };

  useEffect(() => {
    setExternalCallInfo((e) => ({
      ...e,
      firstName: currentFirstName,
      middleName: currentMiddleName,
      lastName: currentLastName,
      email: currentEmail,
      phoneOne: currentPhoneOne,
      organization: currentOrganization,
      ecclesiasticalStatus: currentEcclesiasticalStatus,
      callStartDate: currentCallingStartDate,
    }));
  }, [currentFirstName, currentMiddleName, currentLastName, currentEmail,
    currentPhoneOne, currentOrganization, currentEcclesiasticalStatus, currentCallingStartDate]);

  useEffect(() => {
    if (
      externalCallInfo?.mdpId
      && externalCallInfo?.orgName
      && externalCallInfo?.orgCity
      && externalCallInfo?.orgState
      && externalCallInfo?.orgZip
      && externalCallInfo?.callStartDate
      && externalCallInfo?.firstName
      && externalCallInfo?.lastName
      && externalCallInfo?.email
      && externalCallInfo?.phoneOne
      && externalCallInfo?.organization
      && externalCallInfo?.ecclesiasticalStatus
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [externalCallInfo]);

  function handleClose(result: boolean): void {
    if (result) {
      callback(result, externalCallInfo);
    } else {
      setExternalCallInfo(ExternalCallDefaultData);
      hide();
    }
    reset();
  }

  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      className="modalstyle"
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Notify_Outside_Call_Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div>
          <Form className="mx-3 my-3" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <div className="col-12 offset-md-3 col-md-6 mb-2">
                  <Form.Label>
                    <div className="title">{t('Select_MDP')}</div>
                  </Form.Label>
                  <Form.Select
                    {...register('mdpId', { required: true })}
                    onChange={handleSelectChange}
                  >
                    <option key="" value="">{t('Select_MDP_Default')}</option>
                    {(mdpList?.filter((m) => m.authorized === true
                      && m.submitted === true
                      && m.archived === false).map((mdp: MDPData) => (
                      mdp.authorized && (
                        <option key={`${mdp.id}-active-mdp`} value={mdp.id}>
                          {`${mdp.id}: ${mdp.positionType}`}
                        </option>
                      )
                    )))}
                  </Form.Select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Label className="bodyheader mt-3">{t('Organization')}</Form.Label>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3 required">
                    {t('Ministry_Name')}
                  </Form.Label>
                  <Form.Control
                    value={externalCallInfo?.orgName}
                    disabled
                    {...register('orgName')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3">
                    {t('City')}
                  </Form.Label>
                  <Form.Control
                    value={externalCallInfo?.orgCity}
                    disabled
                    {...register('orgCity')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3">
                    {t('State')}
                  </Form.Label>
                  <Form.Control
                    value={externalCallInfo?.orgState}
                    disabled
                    {...register('orgState')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3">
                    {t('Zip_Code')}
                  </Form.Label>
                  <Form.Control
                    value={externalCallInfo?.orgZip}
                    disabled
                    {...register('orgZip')}
                  />
                </Form.Group>
                <Form.Group>
                  <Col md={6}>
                    <Form.Label className=" bodyheader mt-3 required">
                      {t('Call_Start_Date')}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      isInvalid={!!errors.callStartDate}
                      {...register('callStartDate', { required: true })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.callStartDate && errors.callStartDate.message
                        ? errors.callStartDate.message
                        : t('Call_Start_Date_Required')}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Label className="bodyheader mt-3">{t('Call_Seeker_Information')}</Form.Label>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3 required">
                    {t('First_Name')}
                  </Form.Label>
                  <Form.Control
                    {...register('firstName', { required: true })}
                    isInvalid={!!errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName && errors.firstName.message
                      ? errors.firstName.message
                      : t('FirstNameRequired')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3">
                    {t('Middle_Name')}
                  </Form.Label>
                  <Form.Control
                    {...register('middleName')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3 required">
                    {t('Last_Name')}
                  </Form.Label>
                  <Form.Control
                    {...register('lastName', { required: true })}
                    isInvalid={!!errors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName && errors.lastName.message
                      ? errors.lastName.message
                      : t('LastNameRequired')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3 required">
                    {t('Phone_Number')}
                  </Form.Label>
                  <Form.Control
                    {...register('phoneOne', { required: true })}
                    isInvalid={!!errors.phoneOne}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phoneOne && errors.phoneOne.message
                      ? errors.phoneOne.message
                      : t('PhoneOneRequired')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3 required">
                    {t('Email')}
                  </Form.Label>
                  <Form.Control
                    {...register('email', { required: true })}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email && errors.email.message
                      ? errors.email.message
                      : t('EmailRequired')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3 required">
                    {t('Organization_of_Membership')}
                  </Form.Label>
                  <Form.Control
                    {...register('organization', { required: true })}
                    isInvalid={!!errors.organization}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.organization && errors.organization.message
                      ? errors.organization.message
                      : t('Organization_Required')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="bodyheader mt-3 required">
                    {t('EcclesiasticalStatus')}
                  </Form.Label>
                  <Form.Control
                    {...register('ecclesiasticalStatus', { required: true })}
                    isInvalid={!!errors.ecclesiasticalStatus}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.ecclesiasticalStatus && errors.ecclesiasticalStatus.message
                      ? errors.ecclesiasticalStatus.message
                      : t('EccStatusRequired')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <Button
          variant="danger"
          onClick={() => handleClose(false)}
        >
          {t('PDP.Close')}
        </Button>
        <Button
          variant="primary"
          onClick={() => handleClose(true)}
          disabled={!valid}
        >
          {t('Panel_Modal_Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default withUserAllowed(CLC_FUNCTION.manageMDP)(NotifyExternalCallModal);
