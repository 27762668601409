import {
  Dispatch,
  SetStateAction,
} from 'react';
import { useRecoilValue } from 'recoil';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, Form, Button } from 'react-bootstrap';
import {
  MDPData,
  PDPMatchData,
  MatchAction,
  ActionData,
} from '../types/mdp.types';
import { FormatLocalDate, tryParseInt } from '../utils';
import {
  STATUS_CHOICES,
  PDP_REQUIRES_ACTIONS,
  PDP_DISCERNMENT_ACTIONS,
  PDP_NOT_CONSIDERED_ACTIONS,
} from '../types/constants';
import { Role } from '../types/user.types';
import { currentRoleState } from '../services/state.service';
import MDPService from '../services/mdp.service';

type PDPMatchRowProp = {
  index: number,
  displayCallSeekerName?: boolean,
  match: PDPMatchData,
}

type MDPMatchTableProp = {
  displayName?: boolean,
  filteredPDPs: PDPMatchData[],
  pdpMatches: PDPMatchData[],
  setPDPMatches: Dispatch<SetStateAction<PDPMatchData[]>>,
  setActionData: Dispatch<SetStateAction<ActionData>>,
  mdp: MDPData,
  matchActionOptions: MatchAction[],
  setShowConfirmSubmit: Dispatch<SetStateAction<boolean>>,
  setShowNotifyCall: Dispatch<SetStateAction<boolean>>,
  setShowMDPNotes: Dispatch<SetStateAction<boolean>>,
  setSelectedMatch: Dispatch<SetStateAction<PDPMatchData | undefined>>,
  state: string,
}

function MDPMatchTable({
  displayName,
  filteredPDPs,
  pdpMatches,
  setPDPMatches,
  setActionData,
  mdp,
  matchActionOptions,
  setShowConfirmSubmit,
  setShowNotifyCall,
  setSelectedMatch,
  setShowMDPNotes,
  state,
}: MDPMatchTableProp): JSX.Element {
  const { t } = useTranslation();

  const handleActionDisplay = (list: MatchAction[], match: PDPMatchData) :MatchAction[] => {
    const invitedCount = pdpMatches.filter((p) => p.statusKey === STATUS_CHOICES.invitedToApply).length;
    const callingDate = new Date(match.callingStart);
    const current = new Date();
    let outputList = [...list];

    if (match.statusKey === STATUS_CHOICES.invitedToApply) {
      outputList = outputList.filter(
        (i) => i.actionKey === PDP_REQUIRES_ACTIONS.invitedToApply.willNotConsider,
      );
    } else if (match.statusKey === STATUS_CHOICES.requiresPresbyteryAction) {
      outputList = outputList.filter(
        (i) => i.actionKey === PDP_REQUIRES_ACTIONS.referPDP.refer
        || i.actionKey === PDP_REQUIRES_ACTIONS.invitedToApply.willNotConsider,
      );
    } else if (match.statusKey === STATUS_CHOICES.notConsideredByPresbytery) {
      outputList = outputList.filter(
        (i) => i.actionKey === PDP_REQUIRES_ACTIONS.referPDP.refer,
      );
    } else if (match.statusKey === STATUS_CHOICES.requiresAction && match.selfReferred) {
      outputList = outputList.filter(
        (i) => i.actionKey === PDP_REQUIRES_ACTIONS.acceptedReferral.acceptReferral
        || i.actionKey === PDP_REQUIRES_ACTIONS.invitedToApply.willNotConsider,
      );
    } else if (match.statusKey === STATUS_CHOICES.referred && match.referredBy) {
      outputList = outputList.filter(
        (i) => i.actionKey === PDP_REQUIRES_ACTIONS.acceptedReferral.acceptReferral
        || i.actionKey === PDP_REQUIRES_ACTIONS.invitedToApply.willNotConsider,
      );
    } else if (match.statusKey === STATUS_CHOICES.acceptedinvitation && match.selfReferred) {
      outputList = outputList.filter(
        (i) => i.actionKey === PDP_DISCERNMENT_ACTIONS.acceptedInvitation.beginConsideration
        || i.actionKey === PDP_DISCERNMENT_ACTIONS.acceptedInvitation.willNotConsider,
      );
    } else if (match.statusKey === STATUS_CHOICES.requiresAction) {
      outputList = outputList.filter(
        (i) => (invitedCount < 10 && i.actionKey === PDP_REQUIRES_ACTIONS.requiresAction.invited)
        || i.actionKey === PDP_REQUIRES_ACTIONS.requiresAction.willNotConsider,
      );
    } else if (match.statusKey === STATUS_CHOICES.acceptedinvitation
      || match.statusKey === STATUS_CHOICES.referred) {
      outputList = outputList.filter(
        (i) => i.actionKey === PDP_DISCERNMENT_ACTIONS.acceptedInvitation.beginConsideration
        || i.actionKey === PDP_DISCERNMENT_ACTIONS.acceptedInvitation.willNotConsider,
      );
    } else if (match.statusKey === STATUS_CHOICES.acceptedCall) {
      outputList = outputList.filter(
        (i) => i.actionKey === '',
      );
    } else if (match.statusKey === STATUS_CHOICES.beingConsidered) {
      outputList = outputList.filter(
        (i) => i.actionKey === PDP_DISCERNMENT_ACTIONS.beingConsidered.willNotConsiderFurther
        || i.actionKey === PDP_DISCERNMENT_ACTIONS.beingConsidered.notifyACall,
      );
    } else if (match.statusKey === STATUS_CHOICES.notConsidered) {
      outputList = outputList.filter(
        (i) => i.actionKey === PDP_NOT_CONSIDERED_ACTIONS.notConsidered.invited,
      );
    } else if (match.statusKey === STATUS_CHOICES.noLongerConsidered) {
      outputList = outputList.filter(
        (i) => i.actionKey === PDP_NOT_CONSIDERED_ACTIONS.noLongerConsidered.reconsider,
      );
    } else if (match.statusKey === STATUS_CHOICES.declinedWithdrawn) {
      outputList = outputList.filter(
        (i) => i.actionKey === '',
      );
    } else if (match.statusKey === STATUS_CHOICES.callCompleted) {
      if (callingDate && (callingDate > current)) {
        outputList = outputList.filter(
          (i) => i.actionKey === PDP_DISCERNMENT_ACTIONS.callPending.willNotConsiderFurther,
        );
      } else {
        outputList = outputList.filter(
          (i) => i.actionKey === '',
        );
      }
    }

    return outputList;
  };

  function PDPMatchRow({ index, displayCallSeekerName, match }: PDPMatchRowProp): JSX.Element {
    const options = handleActionDisplay(matchActionOptions, match);
    const position = pdpMatches.find((pt) => pt.pdp === match.pdp);
    const location = useLocation();
    const currentRole = useRecoilValue<Role>(currentRoleState);

    const handleActionChange = (pdpId: number, actionId: number | undefined, status: string): void => {
      setPDPMatches((p: any) => (
        p.map((pt: any) => {
          if (pt.pdp === position?.pdp) {
            return { ...pt, selectedAction: actionId, selectedActionKey: status };
          }
          return pt;
        })
      ));
    };

    const matchActionMenu = options?.map((option: MatchAction) => (
      <option key={`match-${option.id}`} value={option.id}>
        {option.description || ''}
      </option>
    ));

    function renderMatchStatus(matchData: PDPMatchData): string {
      const callingDate = new Date(matchData.callingStart);
      const current = new Date();

      if (matchData.statusKey === STATUS_CHOICES.referred) {
        return `${t('Referred_By')} ${matchData.referredBy}`;
      }

      if (matchData.statusKey === STATUS_CHOICES.acceptedinvitation && matchData.selfReferred) {
        return t('Self_Referred');
      }

      if (matchData.status === STATUS_CHOICES.callCompleted) {
        if (callingDate && (callingDate > current)) {
          return t('Calling_Notify_Pending');
        }
        return t('Calling_Notify_Completed');
      }

      if (matchData.referredBy) {
        return `${matchData.status} - ${t('Referred_By')} ${matchData.referredBy}`;
      }

      if (matchData.selfReferred) {
        return `${matchData.status} - ${t('Self_Referred')}`;
      }

      return matchData.status;
    }

    function submitAction(pos: PDPMatchData | undefined): void {
      if (pos) {
        setActionData((p : any) => ({
          ...p,
          mdpId: mdp.id,
          pdpId: pos.pdp,
          matchId: pos.id,
          action: pos.selectedAction,
          actionKey: pos.selectedActionKey,
        }));

        if (pos?.selectedActionKey === PDP_DISCERNMENT_ACTIONS.beingConsidered.notifyACall) {
          setShowNotifyCall(true);
        } else {
          setShowConfirmSubmit(true);
        }
      }
    }

    const renderCompatibilityCircle = (level: number) => {
      switch (level) {
        case 1:
          return <span className="circle green-circle" />;
        case 2:
          return <span className="circle yellow-circle" />;
        case 3:
          return <span className="circle red-circle" />;
        default:
          return null;
      }
    };

    const viewedMatch = () => {
      MDPService.UpdatePDPMatch({ ...match, newMatch: false });
    };

    return (
      <tr key={`pdp-${match.id}`} className={`${match.newMatch && 'new-match'} dividinglines`}>
        <td>
          {index}
        </td>
        <td>
          <Link
            onClick={viewedMatch}
            to={location.pathname
              .replace('matches', 'pdpdetails')
              .concat(`?pdp_id=${match.pdp}&match_id=${match.id}&mdp_id=${match.mdpId}&state=${state}`)}
          >
            {displayCallSeekerName ? (
              <span>
                {`${match.pdp} - ${match.callSeekerName}`}
              </span>
            ) : (
              <span>
                {match.pdp}
              </span>
            )}
          </Link>
        </td>
        <td>{match.createdAt > '1900-01-01' ? FormatLocalDate(match.createdAt) : ''}</td>
        <td>
          <table>
            <tbody>
              {match.positionTypes.length > 0 ? (
                match.positionTypes.map((list) => (
                  <tr key={`position-type-${list}`}>
                    <td>{list}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>{t('No_Experience')}</td>
                </tr>
              )}
            </tbody>
          </table>
        </td>
        <td>{renderMatchStatus(match)}</td>
        <td>
          {renderCompatibilityCircle(match.compatibilityLevel)}
        </td>
        <td>
          {currentRole.abbreviation !== 'PNC Member' && (
            <>
              <div className="d-inline-block mb-1 me-3">
                <Form.Group>
                  <Form.Select
                    value={position?.selectedAction || ''}
                    onChange={(e) => handleActionChange(
                      match.pdp,
                      tryParseInt(e.target.value),
                      e.target.options[e.target.selectedIndex].text,
                    )}
                  >
                    {match.statusKey !== (STATUS_CHOICES.callCompleted || STATUS_CHOICES.acceptedCall)
                      ? (
                        <>
                          <option key="" value="">
                            {t('Select_Action')}
                          </option>
                          {matchActionMenu}
                        </>
                      ) : (
                        <option key="" value="">
                          {t('No_Available_Actions')}
                        </option>
                      )}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="d-inline-block mb-1 me-3">
                <Button
                  onClick={() => submitAction(position)}
                  className="createbutton"
                  variant="primary"
                  disabled={
                    position?.selectedAction === undefined
                    || position?.selectedAction === 0
                  }
                  active
                >
                  {`${t('Submit_Action')}`}
                </Button>
              </div>
            </>
          )}
          <div className="d-inline-block mb-1 me-3">
            <Button
              type="button"
              className="me-4 ms-auto createbutton note-btn"
              variant="primary"
              active
              onClick={() => {
                setSelectedMatch(match);
                setShowMDPNotes(true);
              }}
            >
              {t('View_Edit_Notes')}
            </Button>
          </div>
        </td>
      </tr>
    );
  }

  PDPMatchRow.defaultProps = {
    displayCallSeekerName: false,
  };

  return (
    <Table responsive hover className="mb-3 linktable">
      <thead>
        <tr className="linktableheader">
          <th>
            #
          </th>
          {displayName ? (
            <th className="col-2">
              {`${t('PDP_ID')} - ${t('Name')}`}
            </th>
          ) : (
            <th className="col-1">
              {t('PDP_ID')}
            </th>
          )}
          <th className="col-1 nowrap">
            {t('Date_Match')}
          </th>
          <th className="col-2">
            {t('ExperienceLevel')}
          </th>
          <th className="col-2">
            {t('Match_Status')}
          </th>
          <th className="col-1">
            {t('CS')}
          </th>
          <th className="col-4">
            {t('Match_Action')}
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredPDPs?.map((match: PDPMatchData, index: number) => (
          <PDPMatchRow
            index={index + 1}
            displayCallSeekerName={displayName}
            key={`recent-matches-${match.id}`}
            match={match}
          />
        ))}
      </tbody>
    </Table>
  );
}

MDPMatchTable.defaultProps = {
  displayName: false,
};

export default MDPMatchTable;
