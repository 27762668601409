import CompensationAndHousing from '../components/CompensationAndHousing';
import EqualEmploymentOpportunity from '../components/EEO';
import MinistryRequirements from '../components/MinistryRequirements';
import PositionRequirements from '../components/PositionRequirements';
import MDPOptionalLinks from '../components/MDPOptionalLinks';
import PNCChair from '../components/PNCChair';
import MDPReferences from '../components/MDPReferences';
import ReviewMDP from '../components/ReviewMDP';
import MDPNarrative from '../components/MDPNarratives';
import CompatibilitySurvey from '../components/CompatibilitySurvey';

export const MDPSteps = [
  {
    key: 'positionRequirements',
    label: 'Position_Requirements',
    isValid: false,
    StepComponent: PositionRequirements,
  },
  {
    key: 'ministryRequirements',
    label: 'Ministry_Requirements',
    isValid: false,
    StepComponent: MinistryRequirements,
  },
  {
    key: 'compensationAndHousing',
    label: 'MDP.Compensation_Housing',
    isValid: false,
    StepComponent: CompensationAndHousing,
  },
  {
    key: 'narratives',
    label: 'Narratives',
    isValid: false,
    StepComponent: MDPNarrative,
  },
  {
    key: 'compatibilitySurvey',
    label: 'Compatibility_Survey',
    isValid: false,
    StepComponent: CompatibilitySurvey,
  },
  {
    key: 'mdpoptionallinks',
    label: 'MDP.Optional_Links',
    isValid: false,
    StepComponent: MDPOptionalLinks,
  },
  {
    key: 'equalEmploymentOpportunity',
    label: 'EEO_Title',
    isValid: false,
    StepComponent: EqualEmploymentOpportunity,
  },
  {
    key: 'references',
    label: 'PDP.References',
    isValid: false,
    StepComponent: MDPReferences,
  },
  {
    key: 'pncChair',
    label: 'PNC_Chair',
    isValid: false,
    StepComponent: PNCChair,
  },
  {
    key: 'review',
    label: 'PDP.Review',
    isValid: false,
    StepComponent: ReviewMDP,
  },
];
