import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SelectField from '../formcontrols/SelectField';
import InputField from '../formcontrols/InputField';
import {
  generateOptions,
  generateSalaryTooltipContent,
  tryParseInt,
} from '../../utils';
import {
  CommunityTypeData,
  EmploymentTypeData,
  HousingTypeData,
} from '../../types/pdp.types';
import {
  CongregationSizeData,
  PresbyteryData,
} from '../../types/ministry.types';
import { SelectOption } from '../../types/generic.types';
import {
  allPositionTypeListState,
  communityTypesState,
  congregationSizesState,
  employmentTypeOptionsState,
  experienceLevelOptionsState,
  housingTypeOptionsState,
  statesListOptionsState,
  presbyteryOptionsState,
} from '../../services/state.service';
import { OpportunitySearchQueryData } from '../../types/mdp.types';
import { IExperienceLevel, IPositionTypeList } from '../../services/mdp.service';
import { GAP_SALARY, MAX_SALARY, MIN_SALARY } from '../../types/constants';
import AutoFillSelect from '../AutoFillSelect';

interface OpportunitySearchFormProps {
  results: number;
  sort: boolean;
  methods: any;
  setSort: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const OpportunitySearchForm: React.FC<OpportunitySearchFormProps> = ({
  results,
  sort,
  methods,
  setSort,
  setRefresh,
}) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { register, handleSubmit, control } = methods;
  const employmentTypes = useRecoilValue<EmploymentTypeData[]>(employmentTypeOptionsState);
  const congregationSizes = useRecoilValue<CongregationSizeData[]>(congregationSizesState);
  const communityTypes = useRecoilValue<CommunityTypeData[]>(communityTypesState);
  const positionTypes = useRecoilValue<IPositionTypeList[]>(allPositionTypeListState);
  const housingTypes = useRecoilValue<HousingTypeData[]>(housingTypeOptionsState);
  const states = useRecoilValue<SelectOption[]>(statesListOptionsState);
  const experienceLevels = useRecoilValue<IExperienceLevel[]>(experienceLevelOptionsState);
  const presbyteries = useRecoilValue<PresbyteryData[]>(presbyteryOptionsState);

  const generateSalaryOptions = (): JSX.Element[] => (
    [
      <option key="" value="0">
        {t('SelectOne')}
      </option>,
    ].concat(
      Array.from(
        { length: (MAX_SALARY - MIN_SALARY) / GAP_SALARY + 1 },
        (_, i) => MIN_SALARY + i * GAP_SALARY,
      ).map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      )),
    )
  );

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    let url = '?';
    const fieldToQueryParam = {
      positionTypes: 'positionTypes',
      experienceLevel: 'experienceLevel',
      minExpectedSalary: 'minExpectedSalary',
      city: 'city',
      states: 'states',
      presbytery: 'presbytery',
      church: 'church',
      intercultural: 'intercultural',
      organization: 'organization',
      employmentType: 'employmentType',
      housingType: 'housingType',
      congregationSize: 'congregationSize',
      communityType: 'communityType',
    };

    Object.entries(fieldToQueryParam).forEach(([field, queryParam]) => {
      let value = data[field as keyof OpportunitySearchQueryData];
      if (field === 'positionTypes') {
        value = data[field as keyof OpportunitySearchQueryData].map((p: IPositionTypeList) => p.id).join(',');
      }

      if (field === 'states') {
        value = data[field as keyof OpportunitySearchQueryData].map((p: SelectOption) => p.id).join(',');
      }

      if (field === 'presbytery') {
        value = data[field as keyof OpportunitySearchQueryData].map((p: SelectOption) => p.value).join(',');
      }

      if (value !== undefined && value !== '' && value !== 0 && value !== '0') {
        url = url.concat(`${queryParam}=${value}&`);
      }
    });

    history(url.slice(0, -1));
  };

  const sortByRelasedDate = (): void => {
    setSort(!sort);
    setRefresh((r: boolean) => !r);
  };

  const filterExperienceLevels = () => {
    const list = [...experienceLevels];
    list.splice(list.findIndex((p) => p.value === 'First Ordained Call'), 1);
    list.splice(list.findIndex((p) => p.value === 'No Experience'), 1);
    return list;
  };

  return (
    <>
      <div className="mb-3 my-3 mx-auto mobiletable col-lg-12">
        <div>
          <Form id="opportunitysearch-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-xs-12 col-sm-4 my-2">
                <AutoFillSelect
                  id="positionTypes"
                  label={t('PDP.Position_Type_Title')}
                  placeholder={t('Select_Position')}
                  options={positionTypes.map((p) => ({ id: p.id, value: p.value })) as SelectOption[]}
                  control={control}
                  multiple
                />
              </div>
              <div className="col-xs-12 col-sm-4 my-2">
                <SelectField
                  label={t('ExperienceLevel')}
                  options={generateOptions(
                    filterExperienceLevels(),
                    'experience-level',
                    'id',
                    'value',
                    { value: '0', label: t('Select_Experience') },
                  )}
                  register={register('experienceLevel')}
                />
              </div>
              <div className="col-xs-12 col-sm-4 my-2">
                <SelectField
                  label={t('Employment_Status')}
                  options={generateOptions(
                    employmentTypes,
                    'employment-type',
                    'id',
                    'description',
                    { value: '0', label: t('Select_Employment') },
                  )}
                  register={register('employmentType')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-4 my-2">
                <SelectField
                  label={t('Congregation_Size')}
                  options={generateOptions(
                    congregationSizes,
                    'congregation-size',
                    'id',
                    'description',
                    { value: '0', label: t('Select_Congregation_Size') },
                  )}
                  register={register('congregationSize')}
                />
              </div>
              <div className="col-xs-12 col-sm-4 my-2">
                <SelectField
                  label={t('Community_Type')}
                  options={generateOptions(
                    communityTypes,
                    'community-type',
                    'id',
                    'description',
                    { value: '0', label: t('Select_Community_Type') },
                  )}
                  register={register('communityType')}
                />
              </div>
              <div className="col-xs-12 col-sm-4 my-2">
                <SelectField
                  label={t('Housing_Type')}
                  options={generateOptions(
                    housingTypes,
                    'housing-type',
                    'id',
                    'housingType',
                    { value: '0', label: t('Select_Housing_Type') },
                  )}
                  register={register('housingType')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-3 my-2">
                <AutoFillSelect
                  id="presbytery"
                  label={t('Presbytery')}
                  placeholder={t('SelectPresbytery')}
                  options={presbyteries.map((p) => ({ id: tryParseInt(p.number), value: p.name })) as SelectOption[]}
                  control={control}
                />
              </div>
              <div className="col-xs-12 col-sm-3 my-2">
                <InputField
                  name={t('Church')}
                  register={register('church')}
                />
              </div>
              <div className="col-xs-12 col-sm-3 my-2">
                <InputField
                  name={t('City')}
                  register={register('city')}
                />
              </div>
              <div className="col-xs-12 col-sm-3 my-2">
                <AutoFillSelect
                  id="states"
                  label={t('State')}
                  placeholder={t('Select_State')}
                  options={states}
                  control={control}
                  multiple
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-3 my-2">
                <SelectField
                  label={t('InterculturalMinistry')}
                  options={[
                    <option key="" value="">
                      {t('SelectOne')}
                    </option>,
                    <option key="yes" value="true">
                      {t('Yes')}
                    </option>,
                    <option key="no" value="false">
                      {t('No')}
                    </option>,
                  ]}
                  register={register('intercultural')}
                />
              </div>
              <div className="col-xs-12 col-sm-3 my-2">
                <SelectField
                  label={t('Min_Effective_Salary')}
                  options={generateSalaryOptions()}
                  register={register('minExpectedSalary')}
                  tooltip={generateSalaryTooltipContent(t('Effective_Salary_Definition'))}
                />
              </div>
              <div className="col-xs-12 col-sm-3 my-2">
                <SelectField
                  label={t('Organization')}
                  options={[
                    <option key="" value="">
                      {t('SelectOne')}
                    </option>,
                    <option key="congregation" value="congregation">
                      {t('Congregation')}
                    </option>,
                    <option key="presbytery" value="presbytery">
                      {t('Presbytery')}
                    </option>,
                    <option key="synod" value="synod">
                      {t('Synod')}
                    </option>,
                    <option key="other" value="other">
                      {t('Other')}
                    </option>,
                  ]}
                  register={register('organization')}
                />
              </div>
              <div className="col-xs-12 col-sm-3 my-2">
                <Button
                  type="submit"
                  variant="primary"
                  className="searchButton"
                >
                  {results > 0 ? t('Update_Search') : t('Search')}
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 offset-sm-9 col-sm-3">
                <Form.Check
                  checked={sort}
                  type="switch"
                  id="custom-switch"
                  label={t('Sort_By_Relased_Date')}
                  onChange={() => sortByRelasedDate()}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
      <hr className="mdpdivider my-3" />
    </>
  );
};

export default OpportunitySearchForm;
