import React from 'react';
import { useTranslation } from 'react-i18next';
import DisplayField from '../generic/DisplayField';
import { FormatLocalDate } from '../../utils';

export interface ProfileInformationProps {
  header: string;
  pdpId: string;
  fullName: string;
  email: string;
  pronouns: string;
  addressOne: string;
  fullAddress: string;
  phoneOne: string;
  phoneTwo: string;
  eccStatusDescription: string | undefined;
  presbyteryNonOrdainedDescription?: string;
  congregationDescription?: string;
  presbyteryDescription?: string;
  ordinationDate?: string;
}

const ProfileInformation: React.FC<{ profileInfo: ProfileInformationProps }> = ({ profileInfo }) => {
  const { t } = useTranslation();
  const {
    header,
    fullName,
    email,
    pronouns,
    addressOne,
    fullAddress,
    phoneOne,
    phoneTwo,
    eccStatusDescription,
    presbyteryNonOrdainedDescription = '',
    congregationDescription = '',
    presbyteryDescription = '',
    ordinationDate = '',
  } = profileInfo;

  const renderDisplayField = (label: string, text: string | undefined) => (
    <div className="col-sm-4">
      <DisplayField label={label} text={text || ''} />
    </div>
  );

  return (
    <div className="border border-dark rounded-3 text-start mb-4">
      <div className="row">
        <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4">{header}</div>
      </div>
      <hr className="mdpdivider" />
      <div className="row m-2">
        {renderDisplayField(t('Name'), fullName)}
        {renderDisplayField(t('Email'), email)}
        {renderDisplayField(t('Pronouns'), pronouns)}
        {renderDisplayField(t('Address'), addressOne ? fullAddress : '')}
        {renderDisplayField(t('Phone_Number'), phoneOne)}
        {renderDisplayField(t('Secondary_Phone'), phoneTwo)}
      </div>

      <div className="row m-2">
        {renderDisplayField(t('EcclesiasticalStatus'), eccStatusDescription)}
        {presbyteryNonOrdainedDescription ? (
          <>
            {renderDisplayField(t('PresbyteryMembership'), presbyteryNonOrdainedDescription)}
            {renderDisplayField(t('Congregation'), congregationDescription)}
          </>
        ) : (
          <>
            {renderDisplayField(t('PresbyteryMembership'), presbyteryDescription)}
            {ordinationDate && renderDisplayField(
              t('OrdinationDate'),
              FormatLocalDate(ordinationDate),
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileInformation;
