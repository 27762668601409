import {
  Modal,
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';

function ExternalCallInstructionsModal({
  show,
  hide,
  callback,
}:{
  show: boolean,
  hide: () => void,
  callback: () => void,
}): JSX.Element {
  const { t } = useTranslation();

  function handleClose(result: boolean): void {
    if (result) {
      callback();
    } else {
      hide();
    }
  }

  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      className="modalstyle"
      backdrop="static"
      size="sm"
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <div>
          {t('External_Call_Instructions')}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <Button
          variant="danger"
          onClick={() => handleClose(false)}
        >
          {t('Go_Back')}
        </Button>
        <Button
          variant="primary"
          onClick={() => handleClose(true)}
        >
          {t('Continue')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default withUserAllowed(CLC_FUNCTION.manageMDP)(ExternalCallInstructionsModal);
