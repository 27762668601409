import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSetRecoilState } from 'recoil';
import Modal from 'react-bootstrap/Modal';
import {
  Link,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FaEnvelope } from 'react-icons/fa';
import AcceptButton from './generic/AcceptButton';
import DeclineButton from './generic/DeclineButton';
import ConfirmModal from './generic/ConfirmModal';
import {
  PDPMatchData,
  ActionData,
  ActionDefaultData,
  PDPMatchListResponseData,
} from '../types/mdp.types';
import {
  pdpPanelState,
} from '../services/state.service';
import api from '../services/api.service';
import MDPService from '../services/mdp.service';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION, STATUS_CHOICES } from '../types/constants';
import CLCInput from './generic/CLCInput';
import CancelCallNotificationModal from './CancelCallNotificationModal';
import { isCallPending } from '../utils';

function OpportunityReferralsAccordionItem({
  eventKey,
}:{
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const [list, setList] = useState<PDPMatchData[]>([]);
  const [actionData, setActionData] = useState<ActionData>(ActionDefaultData);
  const [refresh, setRefresh] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showDecline, setShowDecline] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showNotifyCall, setShowNotifyCall] = useState(false);
  const [currentMDP, setCurrentMDP] = useState<string>('');
  const [showCancelCallModal, setShowCancelCallModal] = useState(false);
  const setRefreshPanel = useSetRecoilState(pdpPanelState);

  useEffect(() => {
    api.get('/opportunityreferrals/').then(
      (response: PDPMatchListResponseData) => {
        setList(response.data.options);
      },
    );
  }, [refresh]);

  const handleActionChange = (mdpId: number, pdpId: number, accepted: string, statusKey?: string, actionKey?: string): void => {
    setActionData((p : any) => ({
      ...p,
      mdpId,
      pdpId,
      statusKey,
      accepted,
      actionKey,
    }));
  };

  const showAcceptInvite = useCallback((result: boolean): void => {
    if (result && actionData.statusKey) {
      MDPService.SubmitReferralAction(actionData).then(() => {
        setRefresh((r: boolean) => !r);
        setRefreshPanel((r: boolean) => !r);
        setShowAccept(false);
      });
    } else {
      setShowAccept(false);
    }
  }, [actionData]);

  const showDeclineInvite = useCallback((result: boolean): void => {
    if (result && actionData.statusKey) {
      MDPService.SubmitReferralAction(actionData).then(() => {
        setRefresh((r: boolean) => !r);
        setRefreshPanel((r: boolean) => !r);
        setShowDecline(false);
      });
    } else {
      setShowDecline(false);
    }
  }, [actionData]);

  const showWithdrawInvite = useCallback((result: boolean): void => {
    if (result && actionData.statusKey) {
      MDPService.SubmitReferralAction(actionData).then(() => {
        setRefresh((r: boolean) => !r);
        setRefreshPanel((r: boolean) => !r);
        setShowWithdraw(false);
      });
    } else {
      setShowWithdraw(false);
    }
  }, [actionData]);

  const submitNotifyCall = (result: boolean) : void => {
    if (result && actionData) {
      MDPService.SubmitMatchAction(actionData).then(() => {
        setRefresh((r: boolean) => !r);
        setRefreshPanel((r: boolean) => !r);
      });
    }
    setShowNotifyCall(false);
  };

  const saveCallCanceledReason = useCallback((isSave: boolean, reason: string, mdpId: string): void => {
    if (isSave) {
      MDPService.saveCallCanceledReason(mdpId, reason).then(() => {
        setRefresh((r: boolean) => !r);
        setRefreshPanel((r: boolean) => !r);
      });
    }
    setShowCancelCallModal(false);
  }, []);

  function renderMatchStatus(matchData: PDPMatchData): string {
    const callingDate = new Date(matchData.callingStart);
    const current = new Date();

    if (matchData.status === STATUS_CHOICES.callCompleted) {
      if (callingDate && (callingDate > current)) {
        return t('Calling_Notify_Pending');
      }
      return t('Calling_Notify_Completed');
    }
    if (matchData.status === STATUS_CHOICES.referred && matchData.referredBy) {
      return `${t('Referred_By')} ${matchData.referredBy}`;
    }
    if ((matchData.status === STATUS_CHOICES.requiresAction
        || matchData.status === STATUS_CHOICES.requiresPresbyteryAction)
      && matchData.selfReferred) {
      return t('Self_Referred');
    }
    return matchData.status;
  }

  function renderCancelCallButton(option: PDPMatchData): JSX.Element {
    return (
      <Button
        className="mb-3 createbutton"
        variant="primary"
        size="sm"
        onClick={() => {
          setShowCancelCallModal(true);
          setCurrentMDP(option.mdpId.toString());
        }}
      >
        {t('Cancel_Call_Title')}
      </Button>
    );
  }

  function renderReferralActions(option: PDPMatchData): JSX.Element {
    if (option.statusKey === STATUS_CHOICES.beingConsidered
      || (option.statusKey === STATUS_CHOICES.requiresAction && option.selfReferred)
    ) {
      return (
        <Button
          className="mb-3 createbutton"
          variant="primary"
          size="sm"
          onClick={() => {
            handleActionChange(option.mdpId, option.pdp, 'False', option.statusKey);
            setShowWithdraw(true);
          }}
        >
          {t('Withdraw_Consideration')}
        </Button>
      );
    }

    if (option.statusKey === STATUS_CHOICES.acceptedinvitation) {
      return (
        <Button
          className="mb-3 createbutton"
          variant="primary"
          size="sm"
          onClick={() => {
            handleActionChange(option.mdpId, option.pdp, 'False', option.statusKey);
            setShowWithdraw(true);
          }}
        >
          {t('Withdraw_Application')}
        </Button>
      );
    }

    if (option.statusKey === STATUS_CHOICES.invitedToApply) {
      return (
        <>
          <AcceptButton
            label=""
            handler={() => {
              handleActionChange(option.mdpId, option.pdp, 'True', option.statusKey);
              setShowAccept(true);
            }}
          />
          <DeclineButton
            label=""
            handler={() => {
              handleActionChange(option.mdpId, option.pdp, 'False', option.statusKey);
              setShowDecline(true);
            }}
          />
        </>
      );
    }

    if (option.statusKey === STATUS_CHOICES.callCompleted && isCallPending(option.callingStart)) {
      return (
        <>
          {renderCancelCallButton(option)}
        </>
      );
    }
    return (
      <td />
    );
  }

  function renderReferrals(): JSX.Element {
    return (
      <tbody>
        {list?.map((option: PDPMatchData) => (
          <tr key={`mdp-${option.id}`} className="dividinglines">
            <td>
              <Link to={`/mdp/${option.mdpId}/view/`} target="_blank">
                {option.mdpId}
              </Link>
            </td>
            <td>
              {option.mdpPositionType ? option.mdpPositionType : ''}
              {option.mdpPositionTitle ? ` (${option.mdpPositionTitle})` : ''}
            </td>
            <td>{option.mdpExperienceLevel ? option.mdpExperienceLevel : ''}</td>
            <td>{option.mdpEmploymentType ? option.mdpEmploymentType : ''}</td>
            <td>{option.organizationName ? option.organizationName : '' }</td>
            <td>{option.city ? option.city : '' }</td>
            <td>{option.state ? option.state : '' }</td>
            <td>{renderMatchStatus(option)}</td>
            <td>
              {renderReferralActions(option)}
              <Modal
                show={showNotifyCall}
                onHide={() => setShowNotifyCall(false)}
                animation={false}
                className="modalstyle"
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {t('Notify_Call')}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="col-md-12 text-center my-5">
                    <div>
                      {t('Notify_Call_Description')}
                    </div>
                    <div className="mx-auto my-5 col-xs-12 col-sm-4">
                      <CLCInput
                        data={actionData}
                        formLabel={t('PDP.Start_Date')}
                        stateSetter={setActionData}
                        inputName="start"
                        inputType="date"
                        maxLength={500}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="col-md-12 text-center">
                    <Button
                      className="px-5 "
                      variant="primary"
                      onClick={() => submitNotifyCall(true)}
                    >
                      {t('OK')}
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal>
              <ConfirmModal
                show={showAccept}
                title={t('PDP.Confirm')}
                description={t('Confirm_Accept_Invite')}
                yesLabel={t('Accept_Invite')}
                noLabel={t('PDP.Cancel')}
                callback={showAcceptInvite}
              />
              <ConfirmModal
                show={showDecline}
                title={t('PDP.Confirm')}
                description={t('Confirm_Decline_Invite')}
                yesLabel={t('Decline_Invite')}
                noLabel={t('PDP.Cancel')}
                callback={showDeclineInvite}
              />
              <ConfirmModal
                show={showWithdraw}
                title={t('PDP.Confirm')}
                description={t('Confirm_Withdraw_Invite')}
                yesLabel={t('Withdraw_Consideration')}
                noLabel={t('PDP.Cancel')}
                callback={showWithdrawInvite}
              />
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaEnvelope />
        <span className="accordion-header-label">
          {t('Opportunity_Referrals')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="mb-3 mx-auto mobiletable col-lg-12">
          <CancelCallNotificationModal
            mdpId={currentMDP}
            show={showCancelCallModal}
            description={t('Cancel_Call_Warning_PDP')}
            callback={saveCallCanceledReason}
          />
          <div className="border border-dark rounded-3">
            <Table responsive hover className="mb-3 linktable">
              <thead>
                <tr className="linktableheader">
                  <th>{t('Links_ID')}</th>
                  <th>{t('Position_Type_Title')}</th>
                  <th>{t('ExperienceLevel')}</th>
                  <th>{t('Employment_Type')}</th>
                  <th>{t('Organization')}</th>
                  <th>{t('City')}</th>
                  <th>{t('State')}</th>
                  <th>{t('Status')}</th>
                  <th>{t('Links_Table_Actions')}</th>
                </tr>
              </thead>
              {list && renderReferrals()}
            </Table>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed(CLC_FUNCTION.callSeeker)(OpportunityReferralsAccordionItem);
