import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const useGeneratePDF = () => {
  const updateColumnWidths = (element: HTMLElement) => {
    const columnElements = element.querySelectorAll('.pdp-references');
    columnElements.forEach((columnElement) => {
      columnElement.classList.add('pdp-references-print');
    });
    return element;
  };

  const resetColumnWidths = (element: HTMLElement) => {
    const columnElements = element.querySelectorAll('.pdp-references');
    columnElements.forEach((columnElement) => {
      columnElement.classList.remove('pdp-references-print');
    });
    return element;
  };

  const hidePrintElements = (element: HTMLElement) => {
    const printElements = element.querySelectorAll('.printbutton, .printNav, .dashboard-link, .note-btn, .mdp-notes');
    printElements.forEach((printElement) => {
      printElement.classList.add('d-none');
    });
    return element;
  };

  const showPrintElements = (element: HTMLElement) => {
    const printElements = element.querySelectorAll('.printbutton, .printNav, .dashboard-link, .note-btn, .mdp-notes');
    printElements.forEach((printElement) => {
      printElement.classList.remove('d-none');
    });
    return element;
  };

  const reduceFontSize = (element: HTMLElement) => {
    const reduceFontElements = element.querySelectorAll('.print-content');
    reduceFontElements.forEach((reduceFontElement) => {
      reduceFontElement.classList.add('shrink-font');
    });
    return element;
  };

  const restoreFontSize = (element: HTMLElement) => {
    const restoreFontElements = element.querySelectorAll('.print-content');
    restoreFontElements.forEach((restoreFontElement) => {
      restoreFontElement.classList.remove('shrink-font');
    });
    return element;
  };

  const clearMargins = (pdf: jsPDF, margin: number) => {
    pdf.setFillColor(255, 255, 255);
    pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), margin, 'F'); // Top margin
    pdf.rect(0, 0, margin, pdf.internal.pageSize.getHeight(), 'F'); // Left margin
    pdf.rect(0, pdf.internal.pageSize.getHeight() - margin, pdf.internal.pageSize.getWidth(), margin, 'F'); // Btm
    pdf.rect(pdf.internal.pageSize.getWidth() - margin, 0, margin, pdf.internal.pageSize.getHeight(), 'F'); // Rgt
  };

  const generatePDF = async (inputs: HTMLElement[] | null | undefined) => {
    /* eslint-disable-next-line new-cap */
    const pdf = new jsPDF('p', 'mm', 'letter');
    const margin = 10;
    const imgWidth = pdf.internal.pageSize.getWidth() - (2 * margin);
    const pageHeight = pdf.internal.pageSize.getHeight() - (2 * margin);

    if (inputs) {
      const canvasPromises = inputs.map(async (input) => {
        let element = input;
        if (element) {
          element = hidePrintElements(element);
          element = reduceFontSize(element);
          element = updateColumnWidths(element);
          const canvas = await html2canvas(element);
          element = showPrintElements(element);
          element = restoreFontSize(element);
          element = resetColumnWidths(element);
          return canvas;
        }
        return null;
      });

      const canvases = await Promise.all(canvasPromises);
      canvases.forEach((canvas, index) => {
        if (canvas) {
          const imgData = canvas.toDataURL('image/png');
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = margin;

          if (index > 0) {
            pdf.addPage();
          }
          pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight, '', 'FAST');
          // clear out content at the margin
          clearMargins(pdf, margin);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight + margin;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight, '', 'FAST');
            clearMargins(pdf, margin);
            heightLeft -= pageHeight;
          }
        }
      });
      const pdfBlob = pdf.output('blob');
      return pdfBlob;
    }
    return null;
  };

  const downloadPDF = (pdfBlob: Blob, filename: string) => {
    const url = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  };

  return { generatePDF, downloadPDF };
};

export default useGeneratePDF;
