import {
  Dispatch,
  SetStateAction,
} from 'react';
import {
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Pagination({
  totalResults,
  totalPages,
  currentPage,
  setCurrentPage,
}: {
  totalResults: number,
  totalPages: number,
  currentPage: number,
  setCurrentPage: Dispatch<SetStateAction<number>>,
}): JSX.Element {
  const { t } = useTranslation();

  const renderPagination = (): JSX.Element[] => {
    const pages = [];
    const maxVisiblePages = 3;
    for (let i = 1; i <= totalPages; i += 1) {
      if (i === 1 || totalPages === 1
          || (i >= currentPage - maxVisiblePages && i <= currentPage + maxVisiblePages)
      ) {
        pages.push(
          <Button
            key={i}
            onClick={() => setCurrentPage(i)}
            className={i === currentPage ? 'active' : ''}
          >
            {i}
          </Button>,
        );
      } else if (i === currentPage - maxVisiblePages - 1 || i === currentPage + maxVisiblePages + 1) {
        pages.push(<Button key={`ellipsis-${i}`}>...</Button>);
      }
    }
    return pages;
  };

  return (
    <div className="pagination pagination-wrap">
      <Button
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
      >
        {t('First')}
      </Button>
      <Button
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {t('Previous')}
      </Button>
      {renderPagination()}
      <Button
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {t('Next')}
      </Button>
      <Button
        onClick={() => setCurrentPage(totalPages)}
        disabled={currentPage === totalPages}
      >
        {t('Last')}
      </Button>
      <div className="text-start fw-bold mx-3 mt-auto">
        {`${totalResults} ${t('Total_Results')}`}
      </div>
    </div>
  );
}

export default Pagination;
