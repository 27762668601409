import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: [
    'span', 'a', 'b', 'i', 'em', 'strong', 'p',
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol',
    'li', 'div', 'br',
  ],
  allowedAttributes: {
    span: ['class'],
    a: ['href', 'name', 'target'],
  },
  allowedIframeHostnames: [],
};

const sanitize = (dirty: any, options: any): any => ({
  __html: sanitizeHtml(
    dirty,
    { ...defaultOptions, ...options },
  ),
});

const replaceFormatting = (html: any): any => {
  if (!html) return html;
  const formatted = html.replace(/\n/g, '<br />').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
  return formatted;
};

function SanitizeHTML({ html, options }: any): JSX.Element {
  return (
    <div dangerouslySetInnerHTML={sanitize(replaceFormatting(html), options)} />
  );
}

export default SanitizeHTML;
