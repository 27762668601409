import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { CompletedCallData } from '../types/mdp.types';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import CompletedCallTable from './CompletedCallTable';
import DownloadCallNotificationModal from './DownloadCallNotificationModal';

function CallCompletedYear({
  eventKey,
  year,
  completedCalls,
}: {
  eventKey: string,
  year: number,
  completedCalls: CompletedCallData[],
}): JSX.Element {
  const { t } = useTranslation();
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <span className="accordion-header-label">
          {year.toString()}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="d-flex">
          <Button
            className="mb-3 ms-auto printbutton"
            variant="primary"
            size="sm"
            active
            onClick={() => setShowDownloadModal(true)}
          >
            {`${t('Download')}`}
          </Button>
        </div>
        <CompletedCallTable
          completedCalls={completedCalls}
        />
        <DownloadCallNotificationModal
          show={showDownloadModal}
          year={year.toString()}
          completedCalls={completedCalls}
          callback={() => setShowDownloadModal(false)}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed([CLC_FUNCTION.manageOrganization].join(','))(CallCompletedYear);
