import {
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import Accordion from 'react-bootstrap/Accordion';
import { useRecoilValue, useRecoilState } from 'recoil';
import MDPService from '../services/mdp.service';
import {
  MDPData,
  MDPResponseData,
  PDPMatchData,
  PDPMatchResponseData,
  MatchAction,
  MatchActionResponse,
  ActionData,
  ActionDefaultData,
  PresbyteryMatchingOptionData,
  PresbyteryMatchingOptionResponseData,
  MatchData,
  MatchResponseData,
  MatchDefaultData,
} from '../types/mdp.types';
import {
  mdpState,
  currentRoleState,
  presbyteryMatchingState,
  matchState,
} from '../services/state.service';
import ConfirmModal from '../components/generic/ConfirmModal';
import MDPMatchTable from '../components/MDPMatchTable';
import { Role } from '../types/user.types';
import {
  STATUS_CHOICES,
  PDP_DISCERNMENT_ACTIONS,
  MINISTRY_ORG_TYPE,
  PRESBYTERY_MATCHING_OPTIONS,
  POSITIONTYPE_OPENTO_OPTIONS,
} from '../types/constants';
import api from '../services/api.service';
import CLCInput from '../components/generic/CLCInput';
import { canRequestRematch, FormatLocalDate } from '../utils';
import SubmitCallNotificationModal from '../components/SubmitCallNotificationModal';
import MatchCount from '../components/MatchCount';
import MDPNotesModal from '../components/MDPNotesModal';
import HelpPopup from '../components/generic/HelpPopup';
import RequestRematchModal from '../components/RequestRematchModal';
import MatchResponseModal from '../components/MatchResponseModal';
import SuspenseLoading from '../components/generic/SuspenseLoading';

function Matches(): JSX.Element {
  const params = useParams();
  const { t } = useTranslation();
  const [mdp, setMDP] = useRecoilState<MDPData>(mdpState);
  const [, setMatch] = useRecoilState<MatchData>(matchState);
  const [currentMatch, setCurrentMatch] = useState<MatchData>(MatchDefaultData);
  const [pdpMatches, setPDPMatches] = useState<PDPMatchData[]>([]);
  const [MatchActionOptions, setMatchActionOptionsData] = useState<MatchAction[]>([]);
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const [actionData, setActionData] = useState<ActionData>(ActionDefaultData);
  const [refresh, setRefresh] = useState(false);
  const [showMatchNotification, setShowMatchNotification] = useState(false);
  const [showMatchResponse, setShowMatchResponse] = useState(String);
  const [showRequestRematchModal, setShowRequestRematchModal] = useState(false);
  const [showNotifyCall, setShowNotifyCall] = useState(false);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [matchingOptions, setMatchingOptions] = useRecoilState<PresbyteryMatchingOptionData>(presbyteryMatchingState);
  const [ShowSubmitting, setShowSubmitting] = useState(false);
  const [showMDPNotes, setShowMDPNotes] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<PDPMatchData>();
  const [sortBy, setSortBy] = useState<string>('1');

  const sortPDPMatches = (pdpMatchList: PDPMatchData[]): PDPMatchData[] => {
    switch (sortBy) {
      case '2':
        return pdpMatchList.sort((a, b) => b.score - a.score);
      case '3':
        return pdpMatchList.sort((a, b) => b.experienceLevelSortOrder - a.experienceLevelSortOrder);
      case '4':
        return pdpMatchList.sort((a, b) => a.pdp - b.pdp);
      case '5':
        return pdpMatchList.sort((a, b) => a.statusKey.localeCompare(b.statusKey));
      default:
        return pdpMatchList.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    }
  };

  const filterPresbyteryActionPDPs = (pdpMatchlist: PDPMatchData[]) :PDPMatchData[] => {
    let outputList = [...pdpMatchlist];
    outputList = outputList.filter(
      (i) => i.statusKey === STATUS_CHOICES.requiresPresbyteryAction,
    );
    return sortPDPMatches(outputList);
  };

  const filterPrebyteryNotConsideredPDPs = (pdpMatchlist: PDPMatchData[]) :PDPMatchData[] => {
    let outputList = [...pdpMatchlist];
    outputList = outputList.filter(
      (i) => i.statusKey === STATUS_CHOICES.notConsideredByPresbytery,
    );
    return sortPDPMatches(outputList);
  };

  const filterRecentPDPs = (pdpMatchlist: PDPMatchData[]) :PDPMatchData[] => {
    let outputList = [...pdpMatchlist];
    outputList = outputList.filter(
      (i) => i.statusKey === STATUS_CHOICES.invitedToApply
      || i.statusKey === STATUS_CHOICES.referred
      || i.statusKey === STATUS_CHOICES.requiresAction
      || (i.selfReferred === true && i.statusKey === STATUS_CHOICES.requiresAction)
      || ((i.referredBy !== '' && i.referredBy !== null) && i.statusKey === STATUS_CHOICES.requiresAction),
    );

    return sortPDPMatches(outputList);
  };

  const filterConsideredPDPs = (pdpMatchList: PDPMatchData[]) :PDPMatchData[] => {
    let outputList = [...pdpMatchList];
    outputList = outputList.filter(
      (i) => i.statusKey === STATUS_CHOICES.beingConsidered
      || i.statusKey === STATUS_CHOICES.acceptedinvitation
      || i.statusKey === STATUS_CHOICES.acceptedCall
      || i.statusKey === STATUS_CHOICES.offeredCall
      || i.statusKey === STATUS_CHOICES.callCompleted
      || i.statusKey === STATUS_CHOICES.callPending,
    );

    return sortPDPMatches(outputList);
  };

  const filterNonConsideredPDPs = (pdpMatchlist: PDPMatchData[]) :PDPMatchData[] => {
    let outputList = [...pdpMatchlist];
    outputList = outputList.filter(
      (i) => i.statusKey === STATUS_CHOICES.notConsidered
      || i.statusKey === STATUS_CHOICES.noLongerConsidered
      || i.statusKey === STATUS_CHOICES.declinedWithdrawn
      || i.statusKey === STATUS_CHOICES.rejectedCall,
    );

    return sortPDPMatches(outputList);
  };

  useEffect(() => {
    if (params.id) {
      MDPService.GetMDPData(params.id)
        .then((response: MDPResponseData) => {
          setMDP(response.data);
        });
      MDPService.GetPDPMatches(params.id)
        .then((response: PDPMatchResponseData) => {
          setPDPMatches(response.data);
        });
      MDPService.GetActionOptions()
        .then((response: MatchActionResponse) => {
          setMatchActionOptionsData(response.data.options);
        });
      MDPService.GetMatchData(params.id).then(
        (response: MatchResponseData) => {
          if (response.data) {
            const result = response.data[0];
            const matchData = {
              ...result,
              matchCharacteristics: result.matchCharacteristics.sort((a, b) => a.characteristic - b.characteristic),
            };
            setMatch(matchData);
            setCurrentMatch(matchData);
          }
        },
      );
      api.get(`presbyterymatching/${currentRole.organizationId}/`)
        .then(
          (response: PresbyteryMatchingOptionResponseData) => {
            if (response.data.options) {
              setMatchingOptions(response.data.options);
            }
          },
        );
    }
  }, [params.id, setMDP, refresh, currentRole]);

  const submitActionData = async (result: boolean) : Promise<any> => {
    if (result && actionData) {
      MDPService.SubmitMatchAction(actionData).then(() => {
        if (actionData.pdpId && actionData.actionKey === PDP_DISCERNMENT_ACTIONS.beingConsidered.notifyACall) {
          MDPService.SaveCallNotification(mdp.id, actionData.pdpId.toString()).then((resp) => {
            actionData.id = resp;
            setShowSubmitting(true);
          });
        }
        MDPService.GetPDPMatches(actionData.mdpId.toString())
          .then((response: PDPMatchResponseData) => {
            setPDPMatches(response.data);
          });
      });
    }
    if (actionData.actionKey === PDP_DISCERNMENT_ACTIONS.beingConsidered.notifyACall) {
      setShowNotifyCall(false);
    } else {
      setShowConfirmSubmit(false);
    }
  };

  const generateRematch = useCallback((result: boolean, match: MatchData): void => {
    if (result && match) {
      MDPService.UpdateMatchData(match).then(() => {
        setShowRequestRematchModal(false);
      }).then(() => {
        MDPService.GetPDPRematches(match.mdp.toString())
          .then((response: any) => {
            if (response.data === '0') {
              setShowMatchResponse(t('No_Match_Found') + response.data);
            } else {
              setShowMatchResponse(t('Matches_Found') + response.data);
            }

            setShowMatchNotification(true);
            setRefresh((r: boolean) => !r);
          });
      });
    } else {
      setShowRequestRematchModal(false);
    }
  }, []);

  const closeNewEdit = useCallback((result: boolean, data: PDPMatchData): void => {
    if (result && data) {
      MDPService.UpdatePDPMatch(data).then(() => {
        setShowMDPNotes(false);
        setSelectedMatch(undefined);
        setRefresh((r: boolean) => !r);
      });
    } else {
      setShowMDPNotes(false);
      setSelectedMatch(undefined);
    }
  }, []);

  function renderCompatibityScoreLegend(): JSX.Element {
    return (
      <div className="text-center mb-1">
        <span>
          {t('Compatibility_Score')}
          :
          <span className="circle green-circle me-1" />
          <span className="mb-1">{t('High')}</span>
          <span className="circle yellow-circle me-1" />
          <span className="mb-1">{t('Medium')}</span>
          <span className="circle red-circle me-1" />
          <span className="mb-1">{t('Low')}</span>
        </span>
      </div>
    );
  }

  function requestLimitReached(): boolean {
    return pdpMatches.filter((i) => i.status === 'Requires action'
      || i.status === 'Invited to apply').length >= 25;
  }

  return (
    <div className="container-fluid">
      <ConfirmModal
        show={showConfirmSubmit}
        title={t('PDP.Confirm')}
        description={t('Match_Confirmation')}
        yesLabel={t('PDP.Confirm')}
        noLabel={t('PDP.Cancel')}
        callback={submitActionData}
      />
      <SubmitCallNotificationModal
        show={ShowSubmitting}
        actionData={actionData}
        callback={setShowSubmitting}
      />
      <Modal
        show={showNotifyCall}
        onHide={() => setShowNotifyCall(false)}
        animation={false}
        className="modalstyle"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('Notify_Call')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 text-center my-5">
            <div>
              {t('Notify_Call_Description')}
            </div>
            <div className="mx-auto my-5 col-xs-12 col-sm-4">
              <CLCInput
                data={actionData}
                formLabel={t('PDP.Start_Date')}
                stateSetter={setActionData}
                inputName="start"
                inputType="date"
                maxLength={500}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-12 text-center">
            <Button
              className="px-5"
              variant="primary"
              disabled={!actionData.start}
              onClick={() => submitActionData(true)}
            >
              {t('OK')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Suspense fallback={<SuspenseLoading />}>
        <RequestRematchModal
          callback={generateRematch}
          show={showRequestRematchModal}
          hide={() => setShowRequestRematchModal(false)}
          mdp={mdp}
          currentMatch={currentMatch}
        />
      </Suspense>
      <MatchResponseModal
        show={showMatchNotification}
        hide={() => setShowMatchNotification(false)}
        showMatchResponse={showMatchResponse}
      />
      {selectedMatch && (
        <MDPNotesModal
          matchNote={selectedMatch}
          show={showMDPNotes}
          saveMDPNotes={closeNewEdit}
        />
      )}

      <div className="container-fluid">
        <div className="profile-panel accordion mb-3 mx-auto col-lg-11">
          <Link to="/" className="dashboard-link">
            <FaLongArrowAltLeft />
            <span>{t('Back_to_Dashboard')}</span>
          </Link>
          <div className="title text-center">
            {t('Matching_PDP_Results')}
          </div>
          <div className="mb-3 my-3 mx-auto mobiletable col-lg-12">
            <div className="row">
              {canRequestRematch(currentRole, matchingOptions, mdp) && (
                <div className="col-12">
                  <div className="d-flex">
                    <Button
                      onClick={() => setShowRequestRematchModal(true)}
                      disabled={requestLimitReached()}
                      className="mb-3 ms-auto createbutton d-flex"
                      variant="primary"
                      size="sm"
                      active
                    >
                      {`${t('Request_Rematch')}`}
                    </Button>
                    {requestLimitReached() && (
                      <HelpPopup
                        helpKey="tooltip-request-limit"
                        placement="left"
                        trigger={['hover', 'focus']}
                        content={t('Request_Limit_Reached')}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* InfoHeader */}
            <div className="matching-block p-3 mt-3 mb-3">
              <table>
                <tbody>
                  <tr>
                    <td className="fw-bold pe-3">
                      {t('View_Matches_MDP_ID')}
                      :
                    </td>
                    <td>
                      {mdp?.id}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold pe-3">
                      {t('Organization_Name')}
                      :
                    </td>
                    <td>
                      { `${mdp?.organizationName} (${mdp?.city}, ${mdp?.state})` }
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold pe-3">
                      {t('View_Matches_MDP_Position_Title')}
                      :
                    </td>
                    <td>
                      {mdp.positionType ? mdp.positionType : ''}
                      {mdp.positionTitle ? ` (${mdp.positionTitle})` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold pe-3">
                      {t('Released_Date')}
                      :
                    </td>
                    <td>
                      {mdp?.released ? FormatLocalDate(mdp.released) : ''}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Form.Group className="text-end">
              <Form.Label className="fw-bold pe-2">
                {t('Sort_By')}
                :
              </Form.Label>
              <Form.Select
                className="d-inline-block w-auto"
                onChange={(e) => setSortBy(e.target.value)}
              >
                <option value="1">{t('Date_Match')}</option>
                <option value="2">{t('Compatibility_Score')}</option>
                <option value="3">{t('ExperienceLevel')}</option>
                <option value="4">{t('PDP_ID')}</option>
                <option value="5">{t('Match_Status')}</option>
              </Form.Select>
            </Form.Group>
            {/* Presbytery Matching */}
            {/* Current Recent PDP Matches */}
            {(currentRole.organizationType === MINISTRY_ORG_TYPE.presbytery
              && matchingOptions.matchingChoice !== PRESBYTERY_MATCHING_OPTIONS.none
              && (matchingOptions.matchByCommittee?.includes(mdp?.committee)
              || (matchingOptions.matchByOrdainedPositions
                && (mdp.openTo === POSITIONTYPE_OPENTO_OPTIONS.ordained
                  || mdp.openTo === POSITIONTYPE_OPENTO_OPTIONS.either)))) && (
                  <>
                    {renderCompatibityScoreLegend()}
                    <Accordion
                      defaultActiveKey={['0', '1', '2']}
                      alwaysOpen
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Button style={{ background: '#D1FFBD' }}>
                          <span className="accordion-header-label">
                            {t('Requires_Presbytery_Action')}
                          </span>
                        </Accordion.Button>
                        <Accordion.Body>
                          <MatchCount
                            count={filterPresbyteryActionPDPs(pdpMatches).length}
                            max={25}
                          />
                          <div className="panel-header">
                            <ConfirmModal
                              show={showConfirmSubmit}
                              title={t('PDP.Confirm')}
                              description={t('Match_Confirmation')}
                              yesLabel={t('PDP.Confirm')}
                              noLabel={t('PDP.Cancel')}
                              callback={submitActionData}
                            />
                            <div>
                              <MDPMatchTable
                                filteredPDPs={filterPresbyteryActionPDPs(pdpMatches)}
                                pdpMatches={pdpMatches}
                                setPDPMatches={setPDPMatches}
                                setActionData={setActionData}
                                mdp={mdp}
                                matchActionOptions={MatchActionOptions}
                                setShowConfirmSubmit={setShowConfirmSubmit}
                                setShowNotifyCall={setShowNotifyCall}
                                setShowMDPNotes={setShowMDPNotes}
                                setSelectedMatch={setSelectedMatch}
                                state="requires presbytery action"
                              />
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* PDPs no longer considered */}
                      <Accordion.Item eventKey="2">
                        <Accordion.Button style={{ background: '#D1FFBD' }}>
                          <span className="accordion-header-label">
                            {t('Presbytery_Not_Considered')}
                          </span>
                        </Accordion.Button>
                        <Accordion.Body>
                          <div className="panel-header">
                            <ConfirmModal
                              show={showConfirmSubmit}
                              title={t('PDP.Confirm')}
                              description={t('Match_Confirmation')}
                              yesLabel={t('PDP.Confirm')}
                              noLabel={t('PDP.Cancel')}
                              callback={submitActionData}
                            />
                            <div>
                              <MDPMatchTable
                                filteredPDPs={filterPrebyteryNotConsideredPDPs(pdpMatches)}
                                pdpMatches={pdpMatches}
                                setPDPMatches={setPDPMatches}
                                setActionData={setActionData}
                                mdp={mdp}
                                matchActionOptions={MatchActionOptions}
                                setShowConfirmSubmit={setShowConfirmSubmit}
                                setShowNotifyCall={setShowNotifyCall}
                                setShowMDPNotes={setShowMDPNotes}
                                setSelectedMatch={setSelectedMatch}
                                state="not considered by prebytery"
                              />
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <br />
                    <br />
                    <br />
                  </>
            )}
          </div>
        </div>

        {/* Regular Matching */}
        <div className="profile-panel accordion mb-3 mx-auto col-lg-11">
          <div className="mb-3 my-3 mx-auto mobiletable col-lg-12">
            {/* PDP Match Results Panels */}
            {/* Current Recent PDP Matches */}
            {renderCompatibityScoreLegend()}
            <Accordion
              defaultActiveKey={['0', '1', '2']}
              alwaysOpen
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span className="accordion-header-label">
                    {t('PDP_Matches_Recent')}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <MatchCount
                    count={filterRecentPDPs(pdpMatches).length}
                    max={25}
                  />
                  <div className="panel-header">
                    <ConfirmModal
                      show={showConfirmSubmit}
                      title={t('PDP.Confirm')}
                      description={t('Match_Confirmation')}
                      yesLabel={t('PDP.Confirm')}
                      noLabel={t('PDP.Cancel')}
                      callback={submitActionData}
                    />
                    <div>
                      <MDPMatchTable
                        filteredPDPs={filterRecentPDPs(pdpMatches)}
                        pdpMatches={pdpMatches}
                        setPDPMatches={setPDPMatches}
                        setActionData={setActionData}
                        mdp={mdp}
                        matchActionOptions={MatchActionOptions}
                        setShowConfirmSubmit={setShowConfirmSubmit}
                        setShowNotifyCall={setShowNotifyCall}
                        setShowMDPNotes={setShowMDPNotes}
                        setSelectedMatch={setSelectedMatch}
                        state="requires action"
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* PDP Matches in review */}
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <span className="accordion-header-label">
                    {t('PDP_Matches_Review')}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <MatchCount
                    count={filterConsideredPDPs(pdpMatches).length}
                    max={10}
                  />
                  <div className="panel-header">
                    <ConfirmModal
                      show={showConfirmSubmit}
                      title={t('PDP.Confirm')}
                      description={t('Match_Confirmation')}
                      yesLabel={t('PDP.Confirm')}
                      noLabel={t('PDP.Cancel')}
                      callback={submitActionData}
                    />
                    <div>
                      <MDPMatchTable
                        displayName
                        filteredPDPs={filterConsideredPDPs(pdpMatches)}
                        pdpMatches={pdpMatches}
                        setPDPMatches={setPDPMatches}
                        setActionData={setActionData}
                        mdp={mdp}
                        matchActionOptions={MatchActionOptions}
                        setShowConfirmSubmit={setShowConfirmSubmit}
                        setShowNotifyCall={setShowNotifyCall}
                        setShowMDPNotes={setShowMDPNotes}
                        setSelectedMatch={setSelectedMatch}
                        state="discernment"
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* PDPs no longer considered */}
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <span className="accordion-header-label">
                    {t('PDP_Matches_Not_Considered')}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="panel-header">
                    <ConfirmModal
                      show={showConfirmSubmit}
                      title={t('PDP.Confirm')}
                      description={t('Match_Confirmation')}
                      yesLabel={t('PDP.Confirm')}
                      noLabel={t('PDP.Cancel')}
                      callback={submitActionData}
                    />
                    <div>
                      <MDPMatchTable
                        filteredPDPs={filterNonConsideredPDPs(pdpMatches)}
                        pdpMatches={pdpMatches}
                        setPDPMatches={setPDPMatches}
                        setActionData={setActionData}
                        mdp={mdp}
                        matchActionOptions={MatchActionOptions}
                        setShowConfirmSubmit={setShowConfirmSubmit}
                        setShowNotifyCall={setShowNotifyCall}
                        setShowMDPNotes={setShowMDPNotes}
                        setSelectedMatch={setSelectedMatch}
                        state="not considered"
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Matches;
