import {
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Form,
} from 'react-bootstrap';
import {
  MIN_SCORE,
  MAX_SCORE,
  GAP_SCORE,
} from '../types/constants';
import { useStepValid } from '../hooks/useStepValid';
import MDPService from '../services/mdp.service';
import {
  MatchData,
  MatchDefaultData,
  MatchCharacteristicData,
  MatchResponseData,
} from '../types/mdp.types';
import { mdpStepState } from '../services/state.service';

function CompatibilitySurvey(): JSX.Element {
  const { t } = useTranslation();
  const [match, setMatch] = useState<MatchData>(MatchDefaultData);
  const hasFetchedData = useRef(false);
  const params = useParams();

  const requiredFields = useMemo(() => [], []);
  useStepValid('compatibilitySurvey', match, requiredFields, mdpStepState);

  useEffect(() => {
    if (params.id && !hasFetchedData.current) {
      MDPService.GetMatchData(params.id).then(
        (response: MatchResponseData) => {
          if (response.data) {
            const result = response.data[0];
            const matchData = {
              ...result,
              matchCharacteristics: result.matchCharacteristics.sort((a, b) => a.characteristic - b.characteristic),
            };
            setMatch(matchData);
          }
        },
      );
    }
  }, [params.id]);

  useEffect(() => {
    if (match.id > 0) {
      MDPService.UpdateMatchData(match);
    }
  }, [match]);

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-8">
      <div className="row">
        <div className="col-xs-12">
          <div className="title">{t('Compatibility_Survey')}</div>
          <div className="mb-2">{t('MDPCompatibilitySurveyDescription')}</div>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://pcusa.org/about-pcusa/agencies-entities/interim-unified-agency/ministry-areas/call-process/church-leadership-connection/clc-survey-info"
          >
            {t('UnderstandingCompetencySurvey')}
          </a>
        </div>
      </div>
      {match.matchCharacteristics?.map((option: MatchCharacteristicData) => (
        <div key={option.question + option.characteristic} className="my-3">
          {option.question !== 'None' && (
            <Form.Group className="text-start">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <span className="match-characteristics-label">{option.question}</span>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="row">
                    <div className="col-5">{t('Survey_Slider_Less_Desired')}</div>
                    <div className="col-5 text-end">{t('Survey_Slider_Highly_Desired')}</div>
                  </div>
                  <div className="row">
                    <div className="col-10">
                      <Form.Range
                        value={option.desiredScore}
                        min={MIN_SCORE}
                        max={MAX_SCORE}
                        step={GAP_SCORE}
                        onChange={(e: any) => setMatch((p: any) => ({
                          ...p,
                          matchCharacteristics:
                          p.matchCharacteristics.map((mc: any) => (mc.id === option.id ? ({
                            ...mc, desiredScore: parseInt(e.target.value, 10),
                          }) : (mc))),
                        }))}
                      />
                    </div>
                    <div className="col-2">
                      <span className="desired-score-label">{`${option.desiredScore}`}</span>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </Form.Group>
          )}
        </div>
      ))}
    </div>
  );
}

export default CompatibilitySurvey;
