import { useTranslation } from 'react-i18next';

type MatchCountProps = {
  count: number,
  max: number,
}

function MatchCount({
  count,
  max,
}: MatchCountProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={`${count >= max && 'text-danger'} text-end`}>
      {`${count} / ${max} ${t('Matches')}`}
    </div>
  );
}

export default MatchCount;
