import {
  useState,
  ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import SanitizeHTML from '../services/html.service';

type CancelCallNotificationModalProps = {
  mdpId: string,
  show: boolean,
  description: string,
  callback: (_isSave: boolean, _reason: string, _mdpId: string) => void,
}

function CancelCallNotificationModal({
  mdpId,
  show,
  description,
  callback,
}: CancelCallNotificationModalProps): JSX.Element {
  const { t } = useTranslation();
  const [callCanceledReason, setCallCanceledReason] = useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);

  function handleSubmitClick() {
    if (callCanceledReason.trim() === '') {
      setValidated(true);
    } else {
      setShowConfirmation(true);
    }
  }

  function handleConfirmClick() {
    callback(true, callCanceledReason, mdpId);
  }

  function handleClose() {
    setCallCanceledReason('');
    setShowConfirmation(false);
    setValidated(false);
    callback(false, '', mdpId);
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modalstyle"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('Cancel_Call_Title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated}>
          <Form.Group className="mb-3">
            <div className="cancel-call-label">
              <Form.Label className="mb-4">
                <SanitizeHTML
                  html={description}
                />
              </Form.Label>
              <Form.Label>{t('Reason')}</Form.Label>
              <Form.Group className="text-start col-6">
                <Form.Control
                  style={{ width: 450, height: 62 }}
                  as="textarea"
                  rows={6}
                  maxLength={1500}
                  value={callCanceledReason || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setCallCanceledReason(e.target.value)}
                  readOnly={showConfirmation}
                  required
                  isInvalid={validated && callCanceledReason.trim() === ''}
                />
                <Form.Control.Feedback type="invalid">
                  {t('FieldRequired')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Form.Group>
          {showConfirmation ? (
            <>
              <div className="my-4 text-center fw-bold">
                <p>{t('Are_You_Sure')}</p>
              </div>
              <div className="my-4 text-center">
                <p>{t('Cancel_Call_Support')}</p>
              </div>
            </>
          ) : (
            <div className="my-4 text-center">
              <p>{t('Cancel_Call_Support')}</p>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {showConfirmation ? (
          <>
            <Button variant="secondary" onClick={() => setShowConfirmation(false)}>
              {t('No')}
            </Button>
            <Button variant="danger" onClick={handleConfirmClick}>
              {t('Yes')}
            </Button>
          </>
        ) : (
          <>
            <Button variant="danger" onClick={handleClose}>
              {t('Go_Back')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={handleSubmitClick}
            >
              {t('Submit')}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CancelCallNotificationModal;
