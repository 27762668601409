import {
  useCallback,
  useRef,
  useState,
} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {
  Button,
  Modal,
  Form,
} from 'react-bootstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import {
  FaTrash,
  FaEdit,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION, MINISTRY_ORG_TYPE } from '../types/constants';
import { SearchCommitteeData } from '../types/ministry.types';
import api from '../services/api.service';
import ConfirmModal from './generic/ConfirmModal';
import SearchCommitteeMembers from './SearchCommitteeMembers';
import MDPPanel from '../pages/MDPPanel';
import { currentRoleState, mdpPanelState } from '../services/state.service';
import { Role } from '../types/user.types';
import SanitizeHTML from '../services/html.service';
import DissolvedCommitteeConfirmationModal from './DissolvedCommitteeConfirmationModal';

type SearchCommitteeForm = {
  id: number,
  organization: number,
  name: string,
}

function SearchCommittee({
  eventKey,
  committee,
  updateCallback,
}: {
  eventKey: string,
  committee: SearchCommitteeData,
  updateCallback: () => void,
}): JSX.Element {
  const { t } = useTranslation();
  const [currentCommittee, setCurrentCommittee] = useState<SearchCommitteeData>(committee);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [showRename, setShowRename] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDissolved, setShowDissolved] = useState(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const setRefreshPanel = useSetRecoilState(mdpPanelState);
  const {
    handleSubmit,
    register,
    formState: {
      errors,
    },
  } = useForm<SearchCommitteeForm>({
    defaultValues: {
      id: committee.id,
      organization: committee.organization,
      name: committee.name,
    },
  });

  function Rename(): JSX.Element {
    const handleClose = (): void => setShowRename(false);
    const handleShow = (): void => setShowRename(true);

    const onSubmit: SubmitHandler<SearchCommitteeForm> = (data: SearchCommitteeForm) => {
      const payload = new FormData();
      payload.append('organization', data.organization.toString());
      payload.append('name', data.name.toString());
      return api.patch(`/searchcommittee/${data.id}/`, payload)
        .then(() => {
          setShowRename(false);
          updateCallback();
        });
    };

    return (
      <>
        <Button
          onClick={handleShow}
          className="mb-3 ms-3 createbutton"
          variant="primary"
          size="sm"
          active
        >
          <FaEdit />
          {` ${t('RenameCommittee')}`}
        </Button>

        <Modal
          show={showRename}
          onHide={handleClose}
          animation={false}
          className="modalstyle"
          backdrop="static"
        >
          <Modal.Header closeButton />
          <Modal.Body className="text-center">
            <div className="mx-auto mt-3">
              <div className="title">{t('RenameCommittee')}</div>
            </div>
            <Form className="mx-auto col-8" onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" {...register('id')} />
              <input type="hidden" {...register('organization')} />
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Committee_Name')}
                </Form.Label>
                <Form.Control
                  {...register('name', { required: true })}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name && errors.name.message
                    ? errors.name.message
                    : t('NameRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
                aria-label="submit"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button variant="primary" onClick={() => submitRef.current?.click()}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function Dissolve(): JSX.Element {
    const handleDelete = useCallback((result: boolean): void => {
      if (result && currentCommittee.id) {
        const payload = new FormData();
        payload.append('active', 'False');
        api.patch(`/searchcommittee/${currentCommittee.id}/`, payload)
          .then(() => {
            setShowDelete(false);
            setShowDissolved(true);
            setRefreshPanel((r: boolean) => !r);
          });
      }
      setShowDelete(false);
    }, [currentCommittee.id]);

    const handleShow = useCallback((com: SearchCommitteeData): void => {
      setCurrentCommittee(com);
      setShowDelete(true);
    }, []);

    return (
      <>
        <Button
          onClick={() => handleShow(committee)}
          className="mb-3 ms-3 createbutton"
          variant="secondary"
          size="sm"
          active
        >
          <FaTrash />
          {` ${t('DissolveCommittee')}`}
        </Button>

        <ConfirmModal
          show={showDelete}
          title={t('DissolveCommittee')}
          description={<SanitizeHTML html={t('Dissolve_Committee_Confirmation_Message')} />}
          yesLabel={t('Proceed_With_Dissolution')}
          noLabel={t('PDP.Cancel')}
          callback={handleDelete}
        />
        <DissolvedCommitteeConfirmationModal
          show={showDissolved}
          hide={() => { setShowDissolved(false); updateCallback(); }}
          committeeName={currentCommittee.name}
        />
      </>
    );
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <span className="accordion-header-label">
          {committee.organizationType === MINISTRY_ORG_TYPE.congregation ? (
            `${committee.name} (${committee.congregationOrg})`
          ) : (
            committee.name
          )}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="d-flex flex-row-reverse">
          {(currentRole.functions.includes(CLC_FUNCTION.manageSearchCommittees)
          || currentRole.functions.includes(CLC_FUNCTION.createSearchCommittee)) && Dissolve()}
          {(currentRole.functions.includes(CLC_FUNCTION.manageSearchCommittees)
          || currentRole.functions.includes(CLC_FUNCTION.createSearchCommittee)) && Rename()}
        </div>
        <SearchCommitteeMembers
          committee={committee}
          updateCallback={updateCallback}
        />
        <MDPPanel
          committee={committee}
          organization={undefined}
          updateCallback={updateCallback}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed([
  CLC_FUNCTION.createSearchCommittee,
  CLC_FUNCTION.manageSearchCommittees,
  CLC_FUNCTION.viewMatches,
].join(','))(SearchCommittee);
