import {
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Button,
} from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CongregationInfoData } from '../types/ministry.types';

export interface SessionClerkRolesModalProps {
  congregationInfo: CongregationInfoData[];
  show: boolean;
  hide: () => void;
  callback: (_congregationId: number[]) => void;
}

function SessionClerkRolesModal({
  congregationInfo,
  show,
  hide,
  callback,
} : SessionClerkRolesModalProps): JSX.Element {
  const { t } = useTranslation();
  const [congreationOptions, setCongregationOptions] = useState<number[]>([]);

  return (
    <Modal
      show={show}
      onHide={hide}
      size="lg"
      centered
    >
      <ModalHeader closeButton>
        <h4>{t('Remove_COS_Roles')}</h4>
      </ModalHeader>
      <ModalBody>
        <p>{t('Remove_COS_Roles_Description')}</p>
        <div>
          {congregationInfo && congregationInfo.map((congregation) => (
            <Form.Check
              type="checkbox"
              id={`congregation-${congregation.id}`}
              label={congregation.info}
              key={congregation.id}
              onChange={() => (
                setCongregationOptions((prev: any) => {
                  if (prev.includes(congregation.id)) {
                    return prev.filter((id: number) => id !== congregation.id);
                  }
                  return [...prev, congregation.id];
                })
              )}
            />
          ))}
        </div>
      </ModalBody>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={hide}
        >
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-2"
          onClick={() => callback(congreationOptions)}
        >
          {t('PDP.Delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SessionClerkRolesModal;
