import React from 'react';
import { useTranslation } from 'react-i18next';
import { WorkData, WorkExperienceModalData } from '../../types/pdp.types';
import { FormatLocalDate } from '../../utils';
import SanitizeHTML from '../../services/html.service';

const WorkExperience: React.FC<{ workExperience: WorkExperienceModalData[] | WorkData[] }> = ({ workExperience }) => {
  const { t } = useTranslation();

  return (
    <div className="border border-dark rounded-3 text-start mb-4">
      <div className="row">
        <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4">
          {t('PDP.Work_Experience')}
        </div>
      </div>
      <hr className="mdpdivider" />
      <div className="row">
        <div className="col-6">
          <div className="my-4 mx-3">
            {workExperience?.map((work: any) => (
              <div key={`workexp-${work.id}`} className="text-start my-4">
                <b>{FormatLocalDate(work.start)}</b>
                &nbsp;-&nbsp;
                <b>{work.end ? FormatLocalDate(work.end) : t('Present')}</b>
                <br />
                {work.organization}
                &nbsp;&nbsp;&nbsp;
                {work.position}
                <br />
                {work.name}
                <br />
                {work.city && (
                  <>
                    {work.city}
                    ,&nbsp;
                  </>
                )}
                {work.state}
                <br />
                {work.community && work.size && (
                  <>
                    (
                    {work.community}
                    ,&nbsp;
                    {work.size}
                    )
                    <br />
                  </>
                )}
                <SanitizeHTML html={work.responsibilities} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
