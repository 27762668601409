import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface NotLoggedInModalProps {
  show: boolean;
  onHide: () => void;
}

const NotLoggedInModal: React.FC<NotLoggedInModalProps> = ({ show, onHide }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide} animation={false} className="modalstyle" backdrop="static">
      <Modal.Body>
        <div className="col-md-12 text-center my-5">
          {t('Opportunity_Search_Not_Logged_In')}
        </div>
        <div className="col-md-12 text-center my-5">
          <a href="/login">
            {t('Click_Here')}
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-md-12 text-center">
          <Button className="px-5" variant="primary" onClick={onHide}>
            {t('OK')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default NotLoggedInModal;
