import Accordion from 'react-bootstrap/Accordion';
import { Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  currentRoleState,
} from '../services/state.service';
import {
  Role,
} from '../types/user.types';
import SanitizeHTML from '../services/html.service';
import api from '../services/api.service';
import { SystemNotificationData, SystemNotificationsResponse } from '../types/generic.types';
import { FormatLocalDate } from '../utils';

function SystemNotificationAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [systemNotifications, setSystemNotifications] = useState<SystemNotificationData[]>([]);

  useEffect(() => {
    if (currentRole.id) {
      api.get('/systemnotifications/').then(
        (response: SystemNotificationsResponse) => {
          setSystemNotifications(response.data.options);
        },
      );
    }
  }, [currentRole.id]);

  return systemNotifications.length ? (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <span className="accordion-header-label">
          {t('Notifications')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <Table responsive hover className="mb-3 linktable">
          <tbody>
            {systemNotifications.map((notification: SystemNotificationData) => (
              <tr key={notification.message} className="dividinglines">
                <td width="15%">{FormatLocalDate(notification.date)}</td>
                <td>
                  <SanitizeHTML html={notification.message} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  ) : (
    <>
      &nbsp;
    </>
  );
}

export default SystemNotificationAccordionItem;
