import { ChangeEvent } from 'react';
import { MIN_SALARY } from './constants';
import { IData } from './generic.types';
import { UserProfile } from './user.types';

export interface PDPListResponseData {
  data: PDPData[],
}

export interface ActivePDPListResponseData {
  data:{
    options: PDPData[],
  }
}

export interface AppliedPDP {
  mdp: string,
  pdp: string,
}

export const AppliedPDPDefault: AppliedPDP = {
  mdp: '',
  pdp: '',
};

export interface PDPResponseData {
  data: PDPData,
}

export interface IPDPPositionType {
  experienceLevel: string,
  pdp: number,
  positionType: number,
}

export interface IPDPEmploymentType {
  id: number,
  description: string,
}

export interface PDPData extends IData {
  id: number,
  employmentType: IPDPEmploymentType | undefined,
  interculturalMinistries: boolean | undefined,
  submitted: boolean,
  advancedSearch: boolean | undefined,
  housingTypes: number[],
  housingTypeDescription: string,
  filingCouple: boolean | undefined,
  clergyCouple: number | undefined,
  minimumExpectedSalary: number | undefined,
  positionTypes: IPDPPositionType[],
  positionTypeDescription: string,
  authorized: boolean | undefined,
  active: boolean,
  noMatchingWithinPresbytery: boolean,
  matchLanguage: string,
}

export interface GeographicalOptionData {
  id: number,
  pdp: number,
  isValid: boolean | undefined,
  geoLocation: string | undefined,
  searchByState: number [],
  searchByPresbytery: number [],
}

export const GeographicalOptionDefaultData: GeographicalOptionData = {
  id: 0,
  pdp: 0,
  isValid: undefined,
  geoLocation: '',
  searchByPresbytery: [],
  searchByState: [],
};

export interface GeographicalOptionResponseData {
  data: GeographicalOptionData[]
}

export const PDPDefaultData: PDPData = {
  id: 0,
  employmentType: undefined,
  interculturalMinistries: undefined,
  submitted: false,
  advancedSearch: undefined,
  housingTypes: [],
  housingTypeDescription: '',
  filingCouple: undefined,
  clergyCouple: undefined,
  minimumExpectedSalary: MIN_SALARY,
  positionTypes: [],
  positionTypeDescription: '',
  authorized: undefined,
  active: false,
  noMatchingWithinPresbytery: false,
  matchLanguage: 'en',
};

export interface IPositionType {
  id: number,
  experienceLevel: string,
  positionType: string
}

export interface IPositionTypeResponse {
  data: {
    options: IPositionType[],
  }
}

export interface HousingTypeData {
  id: string,
  value: string,
}

export interface HousingTypeResponseData {
  data: {
    options: HousingTypeData[],
  }
}

export interface IExperienceLevel {
  id: string,
  value: string
  displayName: string,
}

export interface IExperienceLevelResponse {
  data: {
    options: IExperienceLevel[],
  }
}

export interface EmploymentTypeData {
  id: string,
  description: string,
}

export interface EmploymentTypeResponseData {
  data: {
    options: EmploymentTypeData[],
  }
}

export interface SizeRangeData {
  id: string,
  value: string
}

export interface SizeRangeResponseData {
  data: {
    options: SizeRangeData[],
  }
}

export interface CommunityTypeData {
  id: string,
  value: string
}

export interface CommunityTypeResponseData {
  data: {
    options: CommunityTypeData[],
  }
}

export interface WorkData extends IData {
  id: number,
  pdp: number,
  organization: string,
  position: string,
  name: string,
  size: string,
  city: string,
  state: string,
  community: string,
  start: string,
  end: string | undefined,
  current: boolean | undefined,
  responsibilities: string,
}

export interface WorkResponseData {
  data: WorkData[],
}

export interface ValidData {
  preferences: boolean,
  workexperience: boolean,
  servicetothechurch: boolean,
  statementoffaith: boolean,
  narratives: boolean,
  optionallinks: boolean,
  references: boolean,
  misconduct: boolean,
  review: boolean,
}

export const ValidDefaultData: ValidData = {
  preferences: false,
  workexperience: false,
  servicetothechurch: false,
  statementoffaith: false,
  narratives: false,
  optionallinks: false,
  references: false,
  misconduct: false,
  review: false,
};

export interface ServiceData extends IData {
  id: number,
  pdp: number,
  presbytery: string,
  presbyteryname: string,
  congregation: number | null,
  congregationname: string,
  organization: string,
  description: string,
  position: string,
  start: string | undefined,
  end: string | undefined,
  current: boolean,
}

export interface ServiceResponseData {
  data: ServiceData[],
}

export interface CongregationData {
  pin: number,
  name: string,
  presbytery: string,
  city: string,
  state: string,
}

export const CongregationDefaultData: CongregationData = {
  pin: 0,
  name: '',
  presbytery: '',
  city: '',
  state: '',
};

export interface CongregationResponseData {
  data: {
    congregations: CongregationData[],
  }
}

export interface SynodData {
  number: string,
  name: string,
}

export interface SynodResponseData {
  data: {
    synods:SynodData[],
  }
}

export interface PresbyteryData {
  number: string,
  name: string,
}

export interface PresbyteryResponseData {
  data: {
    presbyteries: PresbyteryData[],
  }
}

export const statementDefault: StatementModalData = {
  id: 0,
  text: '',
};

export const optionalLinkDefault: OptionalLinkModalData = {
  id: 0,
  linkTitle: '',
  linkDescription: '',
  linkUrl: '',
};

export const workExperienceDefault: WorkExperienceModalData = {
  id: 0,
  organization: '',
  position: '',
  name: '',
  size: '',
  country: '',
  city: '',
  state: '',
  community: '',
  start: '',
  end: '',
  current: false,
  responsibilities: '',
};

export const serviceDefault: ServiceModalData = {
  id: 0,
  presbytery: '',
  presbyteryname: '',
  congregation: null,
  congregationname: '',
  position: '',
  organization: '',
  description: '',
  start: undefined,
  end: undefined,
  current: false,
};

export const FormalEducationDefaultData: FormalEducationData = {
  id: 0,
  credentials: '',
  school: '',
  city: '',
  state: '',
  start: '',
  end: '',
  current: false,
};

export interface StatementModalData {
  id: number,
  text: string | undefined,
}

export interface StatementModalResponseData {
  data: StatementModalData[],
}

export interface OptionalLinkModalData extends IData {
  id: number,
  linkTitle: string,
  linkDescription: string,
  linkUrl: string,
}

export interface OptionalLinkModalResponseData {
  data: OptionalLinkModalData[],
}

export interface WorkExperienceModalData extends IData {
  id: number,
  organization: string,
  position: string,
  name: string,
  size: string,
  country: string,
  city: string,
  state: string,
  community: string,
  start: string,
  end: string | undefined,
  current: boolean | undefined,
  responsibilities: string,
}

export interface WorkExperienceModalResponseData {
  data: WorkExperienceModalData[],
}

export interface OptionalLinkData extends IData {
  id: number,
  pdp: number,
  linkTitle: string,
  linkDescription: string,
  linkUrl: string,
}

export interface OptionalLinkResponseData {
  data: OptionalLinkData[],
}

export interface ServiceModalData extends IData {
  id: number,
  presbytery: string,
  presbyteryname: string,
  congregation: number | null,
  congregationname: string,
  organization: string,
  description: string,
  position: string,
  start: string | undefined,
  end: string | undefined,
  current: boolean,
}

export interface ServiceModalResponseData {
  data: ServiceModalData[],
}

export interface FormalEducationData extends IData {
  id: number,
  credentials: string,
  school: string,
  city: string,
  state: string,
  start: string,
  end: string | undefined,
  current: boolean | undefined,
}

export interface FormalEducationResponseData {
  data: FormalEducationData[],
}

export interface ContinuingEducationData extends IData {
  id: number,
  text: string,
}

export const ContinuingEducationDefaultData: ContinuingEducationData = {
  id: 0,
  text: '',
};

export interface ContinuingEducationResponseData {
  data: ContinuingEducationData[],
}

export interface PDPNarrativeData {
  id: number,
  pdp: number,
  question: string,
  positionTypes: string,
  displayOrder: number,
  response: string | undefined,
}

export interface PDPNarrativesResponseData {
  data: PDPNarrativeData[]
}

export interface ReferenceData extends IData {
  id: number,
  pdp: number,
  name: string,
  relation: string,
  phone: string,
  email: string,
}

export interface ReferenceResponseData {
  data: ReferenceData[],
}

export interface SkillData extends IData {
  id: number,
  language: string,
  technical: string,
}

export const SkillDefaultData: SkillData = {
  id: 0,
  language: '',
  technical: '',
};

export interface SkillResponseData {
  data: SkillData[],
}

export interface ProficiencyData {
  id: number,
  value: string
}

export interface ProficiencyResponseData {
  data: {
    options: ProficiencyData[],
  }
}

export interface Skill {
  value: string,
}

export interface Proficiency {
  value: string,
}

export const DefaultSkillData: Skill = {
  value: '',
};

export const DefaultProficiencyData: Proficiency = {
  value: '',
};

/* eslint-disable no-unused-vars */
export interface InputValidationProps {
  field: string,
  option: ReferenceData,
  edit: (
    e: ChangeEvent<HTMLInputElement>,
    identifier: number,
    val: string,
    field: string,
  ) => void,
  save: (ref: ReferenceData) => void,
  pattern: RegExp,
  errorMessage: string,
}

export interface TrainingEducationData {
  formalEducation: FormalEducationData[],
  continuingEducation: ContinuingEducationData[],
  trainingCertification: [] | undefined,
  skills: SkillData[],
}

export const TrainingEducationDefaultData: TrainingEducationData = {
  formalEducation: [],
  continuingEducation: [],
  trainingCertification: [],
  skills: [],
};

export interface TrainingEducationResponseData {
  data: {
    options: TrainingEducationData,
  }
}

export interface PDPMatchProfileData extends IData {
  id: number,
  userId: number,
  matchId: number
  pdpId: number,
  mdpId: number,
  reference: ReferenceData[],
  employmentType: string,
  minExpectedSalary: string,
  geoLocation: GeographicalOptionData[],
  interculturalMinistries: boolean | undefined,
  narratives: PDPNarrativeData[]
  note: string,
  optionalLinks: OptionalLinkData[],
  positionTypes: IPositionType[],
  service: ServiceModalData[],
  workExperience: WorkExperienceModalData[],
  userProfile: UserProfile | undefined,
  formalEducation: FormalEducationData[],
  continuingEducation: ContinuingEducationData[],
  trainingCertification: [] | undefined,
  statementOfFaith: string,
  skills: SkillData[],
}

export const PDPMatchProfileDefaultData: PDPMatchProfileData = {
  id: 0,
  userId: 0,
  matchId: 0,
  pdpId: 0,
  mdpId: 0,
  reference: [],
  employmentType: '',
  minExpectedSalary: '',
  geoLocation: [],
  interculturalMinistries: undefined,
  narratives: [],
  note: '',
  optionalLinks: [],
  positionTypes: [],
  service: [],
  workExperience: [],
  userProfile: undefined,
  formalEducation: [],
  continuingEducation: [],
  trainingCertification: [],
  statementOfFaith: '',
  skills: [],
};

export interface PDPMatchProfileResponseData {
  data: PDPMatchProfileData[],
}

export interface AttestationPDPProfileData extends IData {
  id: number,
  userId: number,
  matchId: number
  pdpId: number,
  mdpId: number,
  reference: ReferenceData[],
  employmentType: string,
  minExpectedSalary: string,
  geoLocation: GeographicalOptionData[],
  interculturalMinistries: boolean | undefined,
  narratives: PDPNarrativeData[]
  note: string,
  optionalLinks: OptionalLinkData[],
  positionTypes: IPositionType[],
  service: ServiceModalData[],
  workExperience: WorkExperienceModalData[],
  userProfile: UserProfile | undefined,
  formalEducation: FormalEducationData[],
  continuingEducation: ContinuingEducationData[],
  trainingCertification: [] | undefined,
  housingType: HousingTypeData[],
  noComplaint: boolean | undefined,
  comments: string,
  certification: boolean | undefined,
  skills: SkillData[],
}

export const AttestationPDPProfileDefaultData: AttestationPDPProfileData = {
  id: 0,
  userId: 0,
  matchId: 0,
  pdpId: 0,
  mdpId: 0,
  reference: [],
  employmentType: '',
  minExpectedSalary: '',
  geoLocation: [],
  interculturalMinistries: undefined,
  narratives: [],
  note: '',
  optionalLinks: [],
  positionTypes: [],
  service: [],
  workExperience: [],
  userProfile: undefined,
  formalEducation: [],
  continuingEducation: [],
  trainingCertification: [],
  housingType: [],
  noComplaint: undefined,
  comments: '',
  certification: undefined,
  skills: [],
};

/* eslint-enable no-unused-vars */
