import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import api from '../services/api.service';
import { tryParseInt } from '../utils';
import {
  MDPResultListData,
  MDPDetailsResponseData,
  MDPResultListDefaultData,
  MDPResultListResponseData,
  OpportunitySearchQueryData,
  ViewMDPResponseData,
  PDPMatchData,
  PDPMatchListResponseData,
} from '../types/mdp.types';
import MDPService, {
  RequirementsData,
  LanguageData,
  IPositionTypeList,
} from '../services/mdp.service';
import PDPService from '../services/pdp.service';
import { MinistryProfile, PresbyteryData } from '../types/ministry.types';
import {
  ministryProfileState,
  userProfileState,
  languageOptionsState,
  allPositionTypeListState,
  statesListOptionsState,
  presbyteryOptionsState,
} from '../services/state.service';
import {
  PDPData,
  ActivePDPListResponseData,
  AppliedPDPDefault,
  AppliedPDP,
} from '../types/pdp.types';
import { UserProfile } from '../types/user.types';
import AlertModal from '../components/generic/AlertModal';
import OpportunitySearchForm from '../components/opportunitysearch/OpportunitySearchForm';
import LoggedInModal from '../components/opportunitysearch/LoggedInModal';
import NotLoggedInModal from '../components/opportunitysearch/NotLoggedInModal';
import MDPResults from '../components/opportunitysearch/MDPResults';
import OpportunitySearchHeader from '../components/opportunitysearch/OpportunitySearchHeader';
import { SelectOption } from '../types/generic.types';

function OpportunitySearch(): JSX.Element {
  const { t } = useTranslation();
  const [mdpDetails, setMDPDetails] = useState<MDPResultListData>(MDPResultListDefaultData);
  const [mdpResults, setMDPResults] = useState<MDPResultListData[]>([]);
  const [ministryProfile, setMinistryProfileState] = useRecoilState<MinistryProfile>(ministryProfileState);
  const [requirements, setRequirements] = useState<RequirementsData>();
  const [refresh, setRefresh] = useState(false);
  const [list, setList] = useState<PDPData[]>([]);
  const [sort, setSort] = useState<boolean>(true);
  const [pdpMatchList, setPDPMatchList] = useState<PDPMatchData[]>([]);
  const [appliedPDP, setAppliedPDP] = useState<AppliedPDP>(AppliedPDPDefault);
  const location = useLocation();
  const [results, setResults] = useState<number>(0);
  const [active, setActive] = useState('');
  const [showNotLoggedInDialogue, setShowNotLoggedInDialogue] = useState(false);
  const [showLoggedInDialogue, setShowLoggedInDialogue] = useState(false);
  const [showSuccessDialogue, setShowSuccessDialogue] = useState(false);
  const [showEccRestrictedDialogue, setShowEccRestrictedDialogue] = useState(false);
  const [showStatementOfFaithDialogue, setShowStatementOfFaithDialogue] = useState(false);
  const [showPositionTypeDialogue, setShowPositionTypeDialogue] = useState(false);
  const [statementOfFaithError, setStatementOfFaithError] = useState<boolean>(false);
  const [showDeadlineDialogue, setShowDeadlineDialogue] = useState(false);
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const languageOptions = useRecoilValue<LanguageData[]>(languageOptionsState);
  const currency = Intl.NumberFormat('en-US');
  const methods = useForm<OpportunitySearchQueryData>();
  const positionTypes = useRecoilValue<IPositionTypeList[]>(allPositionTypeListState);
  const statesListOptions = useRecoilValue<SelectOption[]>(statesListOptionsState);
  const presbyteries = useRecoilValue<PresbyteryData[]>(presbyteryOptionsState);
  const presbyteryOptions = presbyteries.map((p) => ({ id: tryParseInt(p.number) || 0, value: p.name }));

  function getMDPDetail(id: string): void {
    MDPService.GetMDPDetails(id).then(
      (response: MDPDetailsResponseData) => {
        if (response.data) {
          setMDPDetails(response.data[0]);
        }
      },
    );
    api.get(`/viewmdp/${id}/`).then(
      (response: ViewMDPResponseData) => {
        if (response.data) {
          setRequirements(response.data.requirements);
          setMinistryProfileState(response.data.ministryProfile);
        }
      },
    );
  }

  function applyToMDP(mdpId: string, deadline: string): void {
    const mdpDeadline = new Date(deadline);
    mdpDeadline.setMinutes(mdpDeadline.getMinutes() + mdpDeadline.getTimezoneOffset());
    const current = new Date();
    if (current < mdpDeadline || deadline === '') {
      if (userProfile.isLoggedIn) {
        setShowLoggedInDialogue(true);
        PDPService.GetActivePDPList().then(
          (response: ActivePDPListResponseData) => {
            setList(response.data.options);
            setAppliedPDP((p) => ({
              ...p,
              mdp: mdpId,
            }));
          },
        );
      } else {
        setShowNotLoggedInDialogue(true);
      }
    } else {
      setShowDeadlineDialogue(true);
    }
  }

  function submitAppliedPDP(): void {
    if (appliedPDP.pdp) {
      MDPService.SubmitAppliedPDPs(appliedPDP).then(
        (response: any) => {
          setRefresh((r: boolean) => !r);
          if (response.data.toString() === '-1') {
            setShowEccRestrictedDialogue(true);
          } else if (response.data.toString() === '-2') {
            setStatementOfFaithError(true);
            setShowStatementOfFaithDialogue(true);
          } else if (response.data.toString() === '-3') {
            setShowPositionTypeDialogue(true);
          } else {
            setStatementOfFaithError(false);
            setShowSuccessDialogue(true);
          }
        },
      );
    }
    setShowLoggedInDialogue(false);
    setAppliedPDP((p) => ({ ...p, pdp: '' }));
  }

  const SubmitOpportunitySearchQuery = (query: OpportunitySearchQueryData): Promise<MDPResultListResponseData> => {
    const payload = new FormData();
    payload.append('positionTypes', query.positionTypes.join(','));
    payload.append('experienceLevel', query.experienceLevel.toString());
    payload.append('congregationSize', query.congregationSize.toString());
    payload.append('communityType', query.communityType.toString());
    payload.append('minExpectedSalary', query.minExpectedSalary.toString());
    payload.append('employmentType', query.employmentType.toString());
    payload.append('housingType', query.housingType.toString());
    payload.append('organization', query.organization.toString());
    payload.append('church', query.church.toString());
    payload.append('city', query.city.toString());
    payload.append('states', query.states.join(','));
    if (typeof query.presbytery === 'object' && query.presbytery.length > 0) {
      payload.append('presbytery', (query.presbytery[0] as SelectOption).value);
    } else {
      payload.append('presbytery', query.presbytery.toString());
    }
    payload.append('intercultural', query.intercultural.toString());
    payload.append('pageNumber', query.pageNumber.toString());
    payload.append('sortMDPs', query.sortMDPs.toString());
    return api.post('opportunitysearchquery/', payload);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const positionTypeIds = (params.get('positionTypes') || '')
      .split(',').filter(Boolean).map((pt) => tryParseInt(pt) || 0);
    const stateIds = (params.get('states') || '').split(',').filter(Boolean).map((pt) => tryParseInt(pt) || 0);

    const data = {
      positionTypes: positionTypes.filter((pt) => positionTypeIds.includes(pt.id || 0)),
      experienceLevel: tryParseInt(params.get('experienceLevel') || '') || 0,
      city: params.get('city') || '',
      states: statesListOptions.filter((pt) => stateIds.includes(pt.id || 0)),
      congregationSize: tryParseInt(params.get('congregationSize') || '') || 0,
      communityType: tryParseInt(params.get('communityType') || '') || 0,
      employmentType: tryParseInt(params.get('employmentType') || '') || 0,
      housingType: tryParseInt(params.get('housingType') || '') || 0,
      minExpectedSalary: tryParseInt(params.get('minExpectedSalary') || '') || 0,
      organization: params.get('organization') || '',
      presbytery: presbyteryOptions.filter((pt) => pt.value === params.get('presbytery') || ''),
      church: params.get('church') || '',
      intercultural: params.get('intercultural') || '',
      pageNumber: currentPage || 1,
      sortMDPs: sort,
    };

    methods.reset(data);

    SubmitOpportunitySearchQuery({
      ...data,
      positionTypes: positionTypeIds,
      states: stateIds,
    })
      .then((response: MDPResultListResponseData) => {
        setMDPResults(response.data.options);
        setResults(response.data.options.length);
        if (response.data.options.length > 0) {
          getMDPDetail(response.data.options[0].id);
          setActive(response.data.options[0].id);
        }
        setTotalPages(response.data.totalPages);
        setTotalResults(response.data.totalResults);
      });

    if (userProfile?.isLoggedIn) {
      MDPService.GetAppliedPDPs()
        .then((response: PDPMatchListResponseData) => {
          setPDPMatchList(response.data.options);
        });
    }
  }, [refresh, location, userProfile, currentPage]);

  return (
    <div className="container-fluid mb-5">
      <AlertModal
        show={showStatementOfFaithDialogue}
        title={t('Statement_Of_Faith_Required_Title')}
        description={t('Statement_Of_Faith_Required_Dialogue')}
        closeLabel={t('OK')}
        callback={() => setShowStatementOfFaithDialogue(!showStatementOfFaithDialogue)}
      />
      <AlertModal
        show={showEccRestrictedDialogue}
        title={t('Restricted_Position_Title')}
        description={t('Restricted_Position_Description')}
        closeLabel={t('OK')}
        callback={() => setShowEccRestrictedDialogue(!showEccRestrictedDialogue)}
      />
      <AlertModal
        show={showDeadlineDialogue}
        title={t('Application_Deadline_Title')}
        description={t('Application_Deadline_Passed')}
        closeLabel={t('OK')}
        callback={() => setShowDeadlineDialogue(!showDeadlineDialogue)}
      />
      <AlertModal
        show={showPositionTypeDialogue}
        title={t('Position_Type_Required_Title')}
        description={t('Position_Type_RequiredDialogue')}
        closeLabel={t('OK')}
        callback={() => setShowPositionTypeDialogue(!showPositionTypeDialogue)}
      />
      <AlertModal
        show={showSuccessDialogue}
        title={t('Applied_Success_Title')}
        description={t('Applied_Success_Description')}
        closeLabel={t('OK')}
        callback={() => setShowSuccessDialogue(!showSuccessDialogue)}
      />
      {userProfile.isLoggedIn ? (
        <LoggedInModal
          show={showLoggedInDialogue}
          onHide={() => setShowLoggedInDialogue(false)}
          list={list}
          setAppliedPDP={setAppliedPDP}
          submitAppliedPDP={submitAppliedPDP}
        />
      ) : (
        <NotLoggedInModal
          show={showNotLoggedInDialogue}
          onHide={() => setShowNotLoggedInDialogue(false)}
        />
      )}
      <div className="profile-panel accordion mb-3 mx-auto col-lg-11">
        <OpportunitySearchHeader />
        <OpportunitySearchForm
          results={results}
          sort={sort}
          methods={methods}
          setSort={setSort}
          setRefresh={setRefresh}
        />
        <MDPResults
          mdpResults={mdpResults}
          getMDPDetail={getMDPDetail}
          mdpDetails={mdpDetails}
          setActive={setActive}
          active={active}
          applyToMDP={applyToMDP}
          pdpMatchList={pdpMatchList}
          statementOfFaithError={statementOfFaithError}
          languageOptions={languageOptions}
          currency={currency}
          ministryProfile={ministryProfile}
          userProfile={userProfile}
          requirements={requirements}
          totalResults={totalResults}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}

export default OpportunitySearch;
