import { IData } from './generic.types';

export interface MinistryProfile extends IData {
  name: string,
  synod: string,
  presbytery: string,
  congregation: string,
  ministryName: string,
  email: string,
  preferredPhone: string,
  fax: string,
  altPhoneEmail: string,
  addressOne: string,
  addressTwo: string,
  city: string,
  state: string,
  zip: string,
  website: string,
  contactType: string,
  contactName: string,
  contactPhone: string,
  contactFax: string,
  contactEmail : string,
  contactDaytimePhone: string,
  contactAltPhoneEmail: string,
  congregationDetails: CongregationSizeData | undefined,
  averageWorshipAttendance: string,
  churchSchoolAttendance: string,
  curriculum: string,
  interculturalComp: string,
  churchStatisticsURL: string,
  communityDetails: CommunityTypeData | undefined,
  presbyteryDetails: PresbyteryData | undefined,
  synodDetails: SynodData | undefined,
  noMatchingWithinPresbytery: boolean,
  interculturalMinistry: boolean | string | undefined,
  interculturalMinistryDescription: string,
}

export interface InterculturalCompData {
  id: number,
  race: string,
  raceDescription: string,
  percentage: number,
}

export interface CommunityTypeData {
  id: number,
  description: string,
}

export interface ContactTypeData {
  id: number,
  value: string,
}

export interface CongregationSizeData {
  id: number,
  description: string,
}

export interface CommunityTypeResponseData {
  data: {
    options: CommunityTypeData[],
  }
}

export interface ContactTypeResponseData {
  data: {
    options: ContactTypeData[],
  }
}

export interface MinistryProfileResponseData {
  data: {
    options: MinistryProfile
  }
}

export interface CongregationSizeResponseData {
  data: {
    options: CongregationSizeData[],
  }
}

export interface InterculturalCompResponseData {
  data: {
    options: InterculturalCompData[],
  }
}

export const MinistryProfileDefaultValue: MinistryProfile = {
  name: '',
  synod: '',
  presbytery: '',
  congregation: '',
  ministryName: '',
  email: '',
  preferredPhone: '',
  fax: '',
  altPhoneEmail: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zip: '',
  website: '',
  contactType: '',
  contactName: '',
  contactPhone: '',
  contactFax: '',
  contactEmail: '',
  contactDaytimePhone: '',
  contactAltPhoneEmail: '',
  congregationDetails: undefined,
  averageWorshipAttendance: '',
  churchSchoolAttendance: '',
  curriculum: '',
  interculturalComp: '',
  churchStatisticsURL: '',
  communityDetails: undefined,
  presbyteryDetails: undefined,
  synodDetails: undefined,
  noMatchingWithinPresbytery: false,
  interculturalMinistry: undefined,
  interculturalMinistryDescription: '',
};

export const InterculturalCompDefaultData: InterculturalCompData = {
  id: 0,
  race: '',
  raceDescription: '',
  percentage: 0,
};

export const CommunityDetailsDefault: CommunityTypeData = {
  id: 0,
  description: '',
};

export const ContactTypeDefault: ContactTypeData = {
  id: 0,
  value: '',
};

export const CongregationDetailsDefault: CongregationSizeData = {
  id: 0,
  description: '',
};

export interface OrganizationMemberData {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  orgRoles: string,
  expected: boolean,
  isSC: boolean,
  isEP: boolean,
  isCOM: boolean,
  isCPM: boolean,
  isSCPrimary: boolean,
  isEPPrimary: boolean,
  isCOMPrimary: boolean,
  isCPMPrimary: boolean,
  isReadOnly: boolean,
}

export interface OrganizationMemberResponseData {
  data: {
    members: OrganizationMemberData[]
  }
}

export interface SessionClerkData {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  congregation: string,
  congregationInfo: CongregationInfoData[]
  account_approved: boolean,
  expected: boolean,
}

export const SessionClerkDefaultData: SessionClerkData = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  congregation: '',
  congregationInfo: [],
  account_approved: false,
  expected: false,
};

export interface SessionClerkResponseData {
  data: {
    clerks: SessionClerkData[]
  }
}

export interface PresbyteryData {
  number: string,
  name: string,
}

export interface PresbyteryResponseData {
  data: {
    options: PresbyteryData[],
  }
}

export interface SynodData {
  number: string,
  name: string,
}

export interface SynodResponseData {
  data: {
    options: SynodData[],
  }
}

export interface SearchCommitteeData {
  id: number,
  name: string,
  organization: number,
  members: CommitteeMember[],
  congregationOrg: string,
  organizationType: string,
}

export interface SearchCommitteeResponseData {
  data: {
    committees: SearchCommitteeData[],
  }
}

export interface SearchOrganizationResponseData {
  data: {
    options: SearchOrganizationData[],
  }
}

export interface SearchOrganizationData {
  id: number,
  name: string,
}

export interface CommitteeMember {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  role: string,
  roleAbbreviation: string,
  expected: boolean,
}

export interface AttestationNoteData {
  note: string,
  description: string,
}

export interface AttestationNoteResponseData {
  data: {
    options: AttestationNoteData[],
  }
}

export interface AttestationHistoryData {
  id: number,
  attestedDate: string,
  ecclesiasticalStatus: string,
  attestationStatus: string,
  attestationNote: string,
  attestedBy: string,
}

export interface AttestationHistoryResponseData {
  data: {
    attestations: AttestationHistoryData[],
  }
}

export interface CongregationInfoData {
  id: number,
  info: string,
}

export interface CongregationOptionData {
  id: string;
  number: string;
}
