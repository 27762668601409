import { UseFormRegisterReturn } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import HelpPopup from '../generic/HelpPopup';

interface SelectFieldProps {
  label: string;
  options: any[];
  register: UseFormRegisterReturn;
  tooltip?: JSX.Element;
}

const SelectField = (props: SelectFieldProps): JSX.Element | null => {
  const {
    label,
    options,
    register,
    tooltip,
  } = props;

  return (
    <Form.Group>
      <Form.Label>
        {label}
        {tooltip && (
          <HelpPopup
            helpKey={`${label}-help`}
            placement="right"
            trigger={['hover', 'focus']}
            content={tooltip}
          />
        )}
      </Form.Label>
      <Form.Select {...register}>
        {options}
      </Form.Select>
    </Form.Group>
  );
};

SelectField.defaultProps = {
  tooltip: null,
};

export default SelectField;
