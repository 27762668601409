import React from 'react';
import { useTranslation } from 'react-i18next';
import SanitizeHTML from '../../services/html.service';

const StatementOfFaith: React.FC<{ statementOfFaith: string }> = ({ statementOfFaith }) => {
  const { t } = useTranslation();

  return (
    <div className="border border-dark rounded-3 text-start mb-4">
      <div className="row">
        <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4">
          {t('PDP.Statement_of_Faith')}
        </div>
      </div>
      <div>
        <hr className="mdpdivider" />
        <div className="col-12">
          <div className="my-4  mx-3">
            <SanitizeHTML html={statementOfFaith} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatementOfFaith;
