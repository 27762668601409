import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  useNavigate,
  Navigate,
  Link,
} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import AuthService from '../../services/auth.service';
import UserService from '../../services/user.service';
import {
  appLoadingState,
  userProfileState,
  currentRoleState,
  statesListOptionsState,
  allPositionTypeListState,
} from '../../services/state.service';
import { UserProfile } from '../../types/user.types';
import { MinistryProfileResponseData } from '../../types/ministry.types';
import api from '../../services/api.service';
import { SelectOption } from '../../types/generic.types';
import {
  IPositionTypeList,
  ISelectOption,
} from '../../services/mdp.service';
import AutoFillSelect from '../../components/AutoFillSelect';

function Login(): JSX.Element {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm();
  const {
    control,
    handleSubmit: handleSubmitSearch,
  } = useForm();

  const navigate = useNavigate();
  const setUserProfileState = useSetRecoilState(userProfileState);
  const setCurrentRoleState = useSetRecoilState(currentRoleState);
  const setAppLoadingState = useSetRecoilState(appLoadingState);
  const [loginFailed, setLoginFailed] = useState(false);
  const positionTypes = useRecoilValue<IPositionTypeList[]>(allPositionTypeListState);
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const statesListOptions = useRecoilValue<SelectOption[]>(statesListOptionsState);

  if (userProfile?.isLoggedIn) {
    return (<Navigate to="/" replace />);
  }

  function setDefaultCurrentRole(profile: UserProfile): void {
    if (profile.roles.length > 0) {
      setCurrentRoleState(profile.roles[0]);
    }
  }

  const onSubmit = handleSubmit((data: any) => (
    AuthService.Login(data.Username, data.Password)
      .then(() => UserService.GetProfile())
      .then((profile: UserProfile) => {
        setUserProfileState(profile);
        if (profile.currentRole) {
          const currentRole = profile.roles.find((p) => p.id === profile.currentRole);
          if (currentRole) {
            setCurrentRoleState(currentRole);
            if (currentRole.abbreviation === 'COS') {
              UserService.GetMinistryProfile(currentRole.organizationId).then(
                (r: MinistryProfileResponseData) => {
                  if (r.data && r.data.options) {
                    const ministryProfile = r.data.options;
                    if (ministryProfile.congregation && !ministryProfile.curriculum
                      && !ministryProfile.interculturalComp) {
                      navigate('/ministryprofilewizard', { replace: true });
                      setAppLoadingState(false);
                    }
                  }
                },
              );
            }
          } else {
            setDefaultCurrentRole(profile);
          }
        } else if (profile.roles.length > 0) {
          api.put('/user/profile/', { currentRole: profile.roles[0].id })
            .then(() => setCurrentRoleState(profile.roles[0]));
        }

        if (i18n.language !== profile.language) {
          i18n.changeLanguage(profile.language);
          localStorage.setItem('language', profile.language);
          window.location.reload();
        } else {
          i18n.changeLanguage(profile.language);
          navigate('/');
        }
      })
      .catch(() => {
        setLoginFailed(true);
      })
  ));

  const onSubmitSearch = handleSubmitSearch((data: any) => {
    let url = '?';

    if (data.positionTypes && data.positionTypes.length > 0) {
      // get ids of selected position types and put into comma separated string
      const positionTypeIds = data.positionTypes.map((p: ISelectOption) => p.id).join(',');
      url = url.concat(`positionTypes=${positionTypeIds}&`);
    }

    if (data.states && data.states.length > 0) {
      const stateIds = data.states.map((p: ISelectOption) => p.id).join(',');
      url = url.concat(`states=${stateIds}&`);
    }

    navigate('/opportunity-search'.concat(url.slice(0, -1)));
  });

  return (
    <div className="container-fluid login-background">
      <div className="row">
        <div className="col-12 login-header">
          <h1>{t('Welcome_Title')}</h1>
          &nbsp;
        </div>
      </div>
      <div className="row login-panels">
        <div className="col-md-7 col-12 login-panel">
          <Form className="login-opp-search">
            <div className="row mb-4">
              <div className="offset-1 col-md-7 col-10">
                <div className="login-search-text mb-3">
                  <h2>{t('Discerning_Your_Call')}</h2>
                  <span>{t('Login_Page_Search')}</span>
                </div>
                <AutoFillSelect
                  id="positionTypes"
                  label={t('PDP.Position_Type_Title')}
                  placeholder={t('Select_Position')}
                  options={positionTypes.map((p) => ({ id: p.id, value: p.value })) as SelectOption[]}
                  control={control}
                  multiple
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="offset-1 col-4">
                <AutoFillSelect
                  id="states"
                  label={t('State')}
                  placeholder={t('Select_State')}
                  options={statesListOptions}
                  control={control}
                  multiple
                />
              </div>
              <div className="col-3 search-btn">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={onSubmitSearch}
                >
                  {t('Search')}
                </Button>
              </div>
            </div>
          </Form>
          &nbsp;
        </div>
        <div className="col-md-5 col-12 login-panel">
          <Form className="offset-1 col-10 login-form mt-4 mb-4 login-form">
            <div className="row">
              <Form.Group
                className="col-12 col-md-10 mb-3 mx-auto"
                controlId="formUsername"
              >
                <Form.Label className="required">
                  {t('Login.Username')}
                </Form.Label>
                <Form.Control
                  type="input"
                  placeholder={t('Login.UsernamePlaceholder')}
                  isInvalid={!!errors.Username}
                  {...register('Username', { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  {t('Login.UsernameRequired')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group
                className="col-12 col-md-10 mb-3 mx-auto"
                controlId="formPassword"
              >
                <Form.Label className="required">
                  {t('Password')}
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t('Password')}
                  isInvalid={!!errors.Password}
                  {...register('Password', { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  {t('Login.PasswordRequired')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            {loginFailed && (
              <div className="col-12 mb-3 text-center non-field-error">
                {t('Login.InvalidCredentials')}
              </div>
            )}
            <div className="row text-center">
              <Button
                type="submit"
                variant="primary"
                className="login-submitbutton col-12 col-md-6 mb-3 mx-auto"
                disabled={isSubmitting}
                onClick={onSubmit}
              >
                {isSubmitting ? (
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                ) : (
                  t('Login.Submit')
                )}
              </Button>
              <span className="login-links">
                <Link className="forgot-password-link" to="/signup">
                  <span>{t('DontHaveAccount')}</span>
                </Link>
                &nbsp;|&nbsp;
                <Link className="forgot-password-link" to="/forgot">
                  <span>{t('NeedHelpLoggingIn')}</span>
                </Link>
              </span>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
