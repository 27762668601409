import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PDPNarrativeData } from '../../types/pdp.types';
import SanitizeHTML from '../../services/html.service';

const PDPNarratives: React.FC<{ narratives: PDPNarrativeData[] }> = ({ narratives }) => {
  const { t } = useTranslation();

  return (
    <div className="border border-dark rounded-3 text-start mb-4">
      <div className="row">
        <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4">{t('PDP.Narratives')}</div>
      </div>
      <hr className="mdpdivider" />
      <div className="row">
        <div className="col-12 mt-3 ps-4 pe-4">
          {narratives
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((option: PDPNarrativeData) => (
              <div key={option.id} className="panel-header">
                <Table className="pdp-narrative-panel">
                  <thead>
                    <tr className="linktableheader">
                      <td>{option.question}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {option.positionTypes.length > 0 && (
                          <>
                            <div className="text-center fw-bold">
                              {t('Applies_To_Position')}
                              &nbsp;
                              {option.positionTypes}
                            </div>
                            <hr />
                          </>
                        )}
                        <div className="mb-1">
                          <SanitizeHTML html={option.response || ''} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PDPNarratives;
