import {
  useState,
  useEffect,
  ChangeEvent,
  Suspense,
  useRef,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FaLongArrowAltDown } from 'react-icons/fa';
import {
  Form,
  Button,
} from 'react-bootstrap';
import {
  useForm,
  SubmitHandler,
  Controller,
  FieldValues,
} from 'react-hook-form';
import MDPPositionTypeTable from './MDPPositionTypeTable';
import {
  EmploymentTypeData,
} from '../types/pdp.types';
import {
  MatchData,
  MDPData,
  MatchDefaultData,
} from '../types/mdp.types';
import {
  MIN_SALARY,
  MAX_SALARY,
  GAP_SALARY,
  CLC_FUNCTION,
} from '../types/constants';
import { tryParseInt } from '../utils';
import { currentRoleState, employmentTypeOptionsState } from '../services/state.service';
import SuspenseLoading from './generic/SuspenseLoading';
import { Role } from '../types/user.types';
import HelpPopup from './generic/HelpPopup';

type RequestRematchModalProps = {
  callback: (_result: boolean, _match: MatchData) => void,
  show: boolean,
  hide: () => void,
  mdp: MDPData,
  currentMatch: MatchData,
}

function RequestRematchModal({
  show,
  hide,
  callback,
  mdp,
  currentMatch,
} : RequestRematchModalProps): JSX.Element {
  const { t } = useTranslation();
  const [match, setMatch] = useState<MatchData>(MatchDefaultData);
  const employmentTypes = useRecoilValue<EmploymentTypeData[]>(employmentTypeOptionsState);
  const [valid, setValid] = useState<boolean>(false);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [advancedSearch, setAdvancedSearch] = useState<boolean>(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const submitRef = useRef<HTMLButtonElement>(null);

  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
  } = useForm<MatchData>();
  const currentPositionType = watch('positionTypes');

  useEffect(() => {
    setAdvancedSearch(currentRole.functions.indexOf(CLC_FUNCTION.advancedSearch) > -1);
    setMatch(currentMatch);
    reset(currentMatch);
  }, [currentRole, show]);

  useEffect(() => {
    // reset form with user data
    if (match.id) reset(match);
  }, [match.id]);

  useEffect(() => {
    if (
      match.employmentType
      && (currentPositionType && currentPositionType.length > 0)
      && match.maximumSalary
      && match.matchLanguages.length > 0
      && match.matchWithinPresbytery !== null
      && match.matchWithinState !== null
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [match, currentPositionType]);

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    callback(true, { ...match, positionTypes: data.positionTypes });
    setShowAdvancedFilters(false);
  };

  const handleClose = () => {
    reset(currentMatch);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    setMatch({ ...match, [name]: value, mdp: match.mdp });
  };

  const handlePresorStateSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    if (value === '') {
      setMatch({ ...match, [name]: null, mdp: match.mdp });
      return;
    }
    setMatch({ ...match, [name]: value === 'true', mdp: match.mdp });
  };

  const handleLanguageChange = (language: string) => {
    setMatch((prevMatch: any) => ({
      ...prevMatch,
      matchLanguages: prevMatch.matchLanguages.includes(language)
        ? prevMatch.matchLanguages.filter((l: string) => l !== language)
        : [...prevMatch.matchLanguages, language],
    }));
  };

  function AdvancedFilters(): JSX.Element {
    return (
      <div>
        <div className="text-end">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          >
            <span>
              {t('Advanced_Filters')}
              <FaLongArrowAltDown className={`ms-1 arrow-icon ${showAdvancedFilters ? 'rotate-up' : 'rotate-down'}`} />
            </span>
          </button>
        </div>
        {showAdvancedFilters && (
          <>
            <br />
            <hr className="match-criteria-divider" />
            <div className="advanced-filters">

              <div className="my-3">
                <Form.Group className="text-start">
                  <Suspense fallback={<SuspenseLoading />}>
                    <MDPPositionTypeTable control={control} />
                  </Suspense>
                </Form.Group>
              </div>

              <div className="my-3">
                <Form.Group className="text-start col-md-6 col-12">
                  <Form.Label className="bodyheader required">
                    {t('Employment_Status')}
                  </Form.Label>
                  <Form.Select
                    {...register('employmentType')}
                    onChange={handleSelectChange}
                    disabled={!advancedSearch}
                  >
                    <option key="" value="">
                      {t('Select_Employment')}
                    </option>
                    {(employmentTypes.map((option: any) => (
                      <option key={`employment-type-${option.id}`} value={option.id}>
                        {option.description}
                      </option>
                    )))}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="my-3">
                <Form.Group className="text-start col-12">
                  <Form.Check
                    className="d-inline-block"
                    type="checkbox"
                    id="no-matching"
                    label={t('No_Matching_Within_Presbytery')}
                    {...register('noMatchingWithinPresbytery')}
                    defaultChecked={match.disableNoMatchingWithinPresbytery || currentMatch.noMatchingWithinPresbytery}
                    onChange={() => setMatch((p: any) => ({
                      ...p, noMatchingWithinPresbytery: !p.noMatchingWithinPresbytery,
                    }))}
                    disabled={match.disableNoMatchingWithinPresbytery || match.matchWithinPresbytery || match.matchWithinState}
                  />
                  {match.disableNoMatchingWithinPresbytery && (
                    <div className="d-inline-block mt-3">
                      <HelpPopup
                        helpKey="tooltip-salary-definition"
                        placement="right"
                        trigger="click"
                        content={t('No_Matching_Within_Presbytery_Help')}
                      />
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="text-start col-12">
                  <Form.Check
                    type="checkbox"
                    id="match_within_presbytery_only"
                    label={t('Match_Within_Presbytery_Only')}
                    {...register('matchWithinPresbytery')}
                    defaultChecked={currentMatch.matchWithinPresbytery}
                    onChange={() => setMatch((p: any) => ({
                      ...p, matchWithinPresbytery: !p.matchWithinPresbytery,
                    }))}
                    disabled={match.disableNoMatchingWithinPresbytery || match.noMatchingWithinPresbytery}
                  />
                </Form.Group>
                <Form.Group className="text-start col-12">
                  <Form.Check
                    type="checkbox"
                    id="match_within_state"
                    label={t('Match_Within_State')}
                    {...register('matchWithinState')}
                    defaultChecked={currentMatch.matchWithinState}
                    onChange={() => setMatch((p: any) => ({
                      ...p, matchWithinState: !p.matchWithinState,
                    }))}
                    disabled={match.disableNoMatchingWithinPresbytery || match.noMatchingWithinPresbytery}
                  />
                </Form.Group>
              </div>
              <div className="my-3">
                <Form.Group className="text-start col-6">
                  <Form.Label className="bodyheader required">
                    {t('Request_Rematch_Languages')}
                  </Form.Label>
                  {'en,es,ko'.split(',').map((language: string) => (
                    <Controller
                      key={language}
                      name="matchLanguages"
                      control={control}
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          id={language}
                          label={t(`${language}`)}
                          checked={match.matchLanguages.includes(language)}
                          onChange={() => {
                            handleLanguageChange(language);
                            field.onChange(language);
                          }}
                        />
                      )}
                    />
                  ))}
                </Form.Group>
              </div>

            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <Modal
      show={show}
      onHide={hide}
      animation={false}
      className="modalstyle"
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Request_Rematch')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-auto ">
          <div className="matching-criteria-block p-3 mt-3 mb-3">
            <table className="matching-criteria-block-header">
              <tr>
                <td className="fw-bold pe-3">
                  {t('Position_Type_Title')}
                  :
                </td>
                <td className="text-end">
                  {mdp.positionType}
                </td>
              </tr>
            </table>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="my-3">
                <Form.Group className="text-start col-12">
                  <div className="row">
                    <div className="col-6">
                      <Form.Label className="bodyheader required">
                        {t('Match_Maximum_Salary')}
                      </Form.Label>
                    </div>
                    <div className="col-6 text-end">
                      <Form.Label className="bodyheader">
                        {`$${match.maximumSalary}`}
                      </Form.Label>
                    </div>
                  </div>
                  <Form.Range
                    value={match.maximumSalary}
                    min={MIN_SALARY}
                    max={MAX_SALARY}
                    step={GAP_SALARY}
                    {...register('maximumSalary', { required: true, valueAsNumber: true })}
                    onChange={(e: any) => setMatch((p: any) => ({
                      ...p, maximumSalary: tryParseInt(e.target.value),
                    }))}
                  />
                </Form.Group>
              </div>
              {AdvancedFilters()}
            </div>
            <button
              ref={submitRef}
              type="submit"
              style={{ display: 'none' }}
              aria-label="submit"
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-md-12 text-end">
          <Button
            className="px-3"
            variant="primary"
            onClick={() => { submitRef.current?.click(); }}
            disabled={!valid}
          >
            {t('Generate_Matches')}
          </Button>
          <Button
            className="mx-2"
            variant="danger"
            onClick={() => {
              callback(false, match);
              setShowAdvancedFilters(false);
              handleClose();
            }}
          >
            {t('PDP.Cancel')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default RequestRematchModal;
