// HeaderSection.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { FaLongArrowAltLeft } from 'react-icons/fa';

const HeaderSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Link to="/" className="dashboard-link">
        <FaLongArrowAltLeft />
        <span>{t('Back_to_Dashboard')}</span>
      </Link>
      <div className="title text-center">
        {t('Opportunity_Search_Title')}
      </div>
      <Row className="text-center">
        <Col xs="11" className="mb-4 mx-auto">{t('Opportunity_Search_Instructions')}</Col>
      </Row>
      <Row className="text-center">
        <Col xs="11" className="mb-4 mx-auto">
          {t('Opportunity_Search_Link')}
          <a
            href="https://www.pcusa.org/about-pcusa/agencies-entities/interim-unified-agency/ministry-areas/ecumenical-interreligious-relations"
          >
            {t('Ecumenical_Relations_Office')}
          </a>
        </Col>
      </Row>
    </>
  );
};

export default HeaderSection;
