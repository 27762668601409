import {
  useState,
  useEffect,
  useCallback,
  Suspense,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FaFolderOpen } from 'react-icons/fa';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  MDPData,
  MDPResponseData,
  MDPListResponseData,
  MDPDefaultData,
  MatchData,
  MatchResponseData,
  PDPMatchResponseData,
  PresbyteryInfoData,
  ExternalCallData,
  ActionData,
  ActionDefaultData,
  MatchDefaultData,
} from '../types/mdp.types';
import api from '../services/api.service';
import EditButton from '../components/generic/EditButton';
import DeleteButton from '../components/generic/DeleteButton';
import SoftDeleteButton from '../components/generic/SoftDeleteButton';
import AddButton from '../components/generic/AddButton';
import AlertModal from '../components/generic/AlertModal';
import ConfirmModal from '../components/generic/ConfirmModal';
import ViewButton from '../components/generic/ViewButton';
import PrintButton from '../components/generic/PrintButton';
import ManageMatchingModal from '../components/ManageMatchingModal';
import NotifyExternalCallModal from '../components/NotifyExternalCallModal';
import ExternalCallInstructionsModal from '../components/ExternalCallInstructionsModal';
import {
  currentRoleState,
  matchState,
  mdpState,
  presbyteryInfoState,
  userProfileState,
  mdpPanelState,
} from '../services/state.service';
import { Role, UserProfile } from '../types/user.types';
import {
  SearchCommitteeData,
} from '../types/ministry.types';
import withUserAllowed from '../components/layout/withUserAllowed';
import { CLC_FUNCTION, MINISTRY_ORG_TYPE } from '../types/constants';
import MDPService from '../services/mdp.service';
import NeedHelpModal from '../components/NeedHelpModal';
import CancelCallButton from '../components/generic/CancelCallButton';
import CancelCallNotificationModal from '../components/CancelCallNotificationModal';
import { isCallPending, isCallCompleted } from '../utils';
import SubmitCallNotificationModal from '../components/SubmitCallNotificationModal';
import SuspenseLoading from '../components/generic/SuspenseLoading';

interface OrganizationInfo {
  name: string;
  users: UserProfile[] | undefined;
}

function MDPPanel({
  committee,
}: {
  committee: SearchCommitteeData | undefined,
}): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentMDP, setCurrentMDP] = useRecoilState<MDPData>(mdpState);
  const [, setMatch] = useRecoilState<MatchData>(matchState);
  const [currentMatch, setCurrentMatch] = useState<MatchData>(MatchDefaultData);
  const [list, setList] = useState<MDPData[]>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [showNoMatchModal, setShowNoMatchModal] = useState(false);
  const [showManageMatchModal, setShowManageMatchModal] = useState(false);
  const [showNoPNC, setShowNoPNC] = useState(false);
  const [showNeedHelp, setShowNeedHelp] = useState(false);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const presbyteryInfo = useRecoilValue<PresbyteryInfoData>(presbyteryInfoState);
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfo>();
  const [showCancelCallModal, setShowCancelCallModal] = useState(false);
  const [currentMDPId, setCurrentMDPId] = useState<string>('');
  const [cancelCallOrg, setCancelCallOrg] = useState<string>('');
  const [showExternalCallModal, setShowExternalCallModal] = useState(false);
  const [showExternalCallInstructionsModal, setShowExternalCallInstructionsModal] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const setRefreshPanel = useSetRecoilState(mdpPanelState);
  const refreshPanel = useRecoilValue(mdpPanelState);
  const [showSubmitting, setShowSubmitting] = useState(false);
  const [notificationData, setNotificationData] = useState<ActionData>(ActionDefaultData);

  useEffect(() => {
    if (committee?.id) {
      api.get(`/mdp/?committee=${committee.id}`).then(
        (response: MDPListResponseData) => {
          setList(response.data.sort((a, b) => (parseInt(a.id, 10) - parseInt(b.id, 10))));
        },
      );
    } else {
      api.get('/mdp/').then(
        (response: MDPListResponseData) => {
          setList(response.data.sort((a, b) => (parseInt(a.id, 10) - parseInt(b.id, 10))));
        },
      );
    }
    setOrganizationInfo(() => ({
      name: presbyteryInfo.presbytery,
      users: presbyteryInfo.users,
    }));
  }, [committee?.id, refreshList, refreshPanel]);

  const makeNewMDP = useCallback((mdp: MDPData): void => {
    if (committee?.members.find((member) => member.roleAbbreviation === 'PNC Chair' && !member.expected)) {
      api.post('/mdp/', mdp).then((response: MDPResponseData) => {
        navigate(`/mdp/${(response.data.id)}/`);
      });
    } else {
      setShowNoPNC(true);
    }
  }, [navigate]);

  const closeNewEdit = useCallback((result: boolean, match: MatchData): void => {
    if (result && match) {
      if (match.id > 0) {
        MDPService.UpdateMatchData(match).then(() => {
          MDPService.GetPDPMatches(match.mdp.toString())
            .then((response: PDPMatchResponseData) => {
              if (response.data.length === 0) {
                setShowNoMatchModal(true);
              } else {
                setShowManageMatchModal(false);
                setRefreshList((r: boolean) => !r);
              }
            });
        });
      }
    } else {
      setShowManageMatchModal(false);
    }
  }, []);

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentMDP) {
      api.delete(`/mdp/${currentMDP.id}/`).then(() => {
        setShowDelete(false);
        setRefreshList((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentMDP, refreshList, setRefreshList, setShowDelete]);

  const closeRemove = useCallback((result: boolean): void => {
    if (result && currentMDP) {
      MDPService.RemoveMDP(currentMDP).then(() => {
        setShowRemove(false);
        setRefreshList((r: boolean) => !r);
      });
    } else {
      setShowRemove(false);
    }
  }, [currentMDP, refreshList, setRefreshList, setShowRemove]);

  const getMatches = (id: string): void => {
    MDPService.GetMatchData(id).then(
      (response: MatchResponseData) => {
        if (response.data) {
          const result = response.data[0];
          const matchData = {
            ...result,
            matchCharacteristics: result.matchCharacteristics.sort((a, b) => a.characteristic - b.characteristic),
          };
          setMatch(matchData);
          setCurrentMatch(matchData);
          setShowManageMatchModal(true);
        }
      },
    );
  };

  const saveCallCanceledReason = useCallback((isSave: boolean, reason: string, mdpId: string): void => {
    if (isSave) {
      MDPService.saveCallCanceledReason(mdpId, reason).then(() => {
        setRefreshList((r: boolean) => !r);
        setRefreshPanel((r: boolean) => !r);
      });
    }
    setShowCancelCallModal(false);
  }, []);

  function isCalled(mdp: MDPData): boolean {
    if (isCallCompleted(mdp.callingStart)) {
      return true;
    }

    if (isCallPending(mdp.callingStart)) {
      return true;
    }

    return false;
  }

  function renderMatchButtons(option: MDPData): any {
    return (
      (!isCallCompleted(option.callingStart) || option.callingStart === '') && (
        <ViewButton
          label=""
          handler={() => (
            navigate(`/mdp/${(option.id)}/matches/`)
          )}
        />
      )
    );
  }

  function renderPrintButton(id: string): any {
    return (
      <PrintButton
        label=""
        handler={() => (
          navigate(`/mdp/${id}/view/`)
        )}
      />
    );
  }

  function renderEditButtons(option: MDPData): JSX.Element {
    return (
      <>
        <EditButton
          label=""
          handler={() => (
            navigate(`/mdp/${(option.id)}/`)
          )}
        />
        {option.submitted ? (
          <SoftDeleteButton
            label=""
            handler={() => {
              setCurrentMDP({ ...option, isRemoved: true });
              setShowRemove(true);
            }}
          />
        ) : (
          <DeleteButton
            label=""
            handler={() => {
              setCurrentMDP(option);
              setShowDelete(true);
            }}
          />
        )}
      </>
    );
  }

  function renderCancelCallButton(option: MDPData): JSX.Element {
    return (
      <CancelCallButton
        label=""
        handler={() => {
          setShowCancelCallModal(true);
          setCurrentMDPId(option.id);
          setCancelCallOrg(option.organizationName);
        }}
      />
    );
  }

  function getStatus(mdp: MDPData): string {
    if (!mdp.submitted) {
      return t('InProgress');
    }

    if (mdp.submitted && !mdp.authorized) {
      return t('Submitted');
    }

    if (isCallCompleted(mdp.callingStart)) {
      return t('Calling_Notify_Completed');
    }

    if (isCallPending(mdp.callingStart)) {
      return t('Calling_Notify_Pending');
    }

    return t('Released');
  }

  function canViewEditButtons(role: Role, mdp: MDPData): boolean {
    if (role.abbreviation !== 'PNC Member') {
      if (role.abbreviation === 'PNC Chair') {
        return true;
      }
      if (role.organizationType === MINISTRY_ORG_TYPE.presbytery
        && mdp.authorized
        && mdp.submitted) {
        return true;
      }
      if (role.organizationType === MINISTRY_ORG_TYPE.congregation) {
        return true;
      }
    }
    return false;
  }

  function renderMDPTable(mdpList: MDPData[]): JSX.Element {
    return (
      <tbody>
        {mdpList?.map((option: MDPData) => (
          <tr key={option.id} className="dividinglines">
            <td>{option.id}</td>
            <td>
              {option.positionType ? option.positionType : ''}
              {option.positionTitle ? ` (${option.positionTitle})` : ''}
            </td>
            <td>{option.organizationName ? option.organizationName : '' }</td>
            <td>{getStatus(option)}</td>
            <td>
              <div>
                {option.submitted && renderPrintButton(option.id)}
                {(canViewEditButtons(currentRole, option)
                && (!isCalled(option) || option.callingStart === '')) && (renderEditButtons(option))}
                {(currentRole.functions.indexOf(CLC_FUNCTION.viewMatches) !== -1
                || currentRole.functions.indexOf(CLC_FUNCTION.manageMatching) !== -1)
                  && option.submitted && option.authorized && renderMatchButtons(option)}
                {(currentRole.functions.indexOf(CLC_FUNCTION.manageMatching) > -1
                  && isCallPending(option.callingStart)) && renderCancelCallButton(option)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  function saveExternalCallInfo(result: boolean, data: ExternalCallData): void {
    if (result) {
      api.post('/saveexternalcall/', data).then((res) => {
        // generate pdf
        setNotificationData({
          ...notificationData,
          id: res.data,
          mdpId: Number(data.mdpId),
        });
        setShowSubmitting(true);
      });
    }
    setShowExternalCallModal(false);
  }

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-12">
      {committee && (
        <div>
          <FaFolderOpen />
          <span className="accordion-header-label">
            {t('MDP._Accordion_Title')}
          </span>
        </div>
      )}
      <div className="d-flex flex-row-reverse">
        {currentRole.functions.indexOf(CLC_FUNCTION.manageMDP) > -1 && (
          <div className="mx-1">
            <Button
              onClick={() => (setShowNeedHelp(true))}
              className="mb-3 ms-auto createbutton"
              variant="primary"
              size="sm"
              active
            >
              {`+ ${t('Need_Help')}`}
            </Button>
          </div>
        )}
        <div>
          {currentRole.functions.indexOf(CLC_FUNCTION.manageMDP) > -1
          && committee?.members.find((member) => member.email === userProfile.email)
          && currentRole.organizationId === committee.organization
          && (
            <Button
              onClick={() => (makeNewMDP({
                ...MDPDefaultData,
                organization: committee?.organization,
                committee: committee?.id,
              }))}
              className="mb-3 ms-auto createbutton"
              variant="primary"
              size="sm"
              active
            >
              {`+ ${t('Create_MDP')}`}
            </Button>
          )}
        </div>
        {list.length > 0 && (
          <div className="mx-1">
            <Button
              onClick={() => setShowExternalCallInstructionsModal(true)}
              className="mb-3 ms-auto createbutton"
              variant="primary"
              size="sm"
              active
            >
              {`${t('Notify_Ext_Call')}`}
            </Button>
          </div>
        )}
      </div>
      <NeedHelpModal
        show={showNeedHelp}
        hide={() => setShowNeedHelp(false)}
        organizationLabel={
          currentRole.organizationType === 'congregation' && organizationInfo?.name ? (t('Organization_Name')) : ('')
        }
        organizationName={
          currentRole.organizationType === 'congregation' && (organizationInfo?.name || '')
        }
        organizationCOM={
          currentRole.organizationType === 'congregation'
          && (organizationInfo?.users && organizationInfo?.users?.length > 0) ? (t('Organization_COM')) : ('')
        }
        users={
          currentRole.organizationType === 'congregation'
          && (organizationInfo?.users && organizationInfo?.users?.length > 0) ? organizationInfo?.users : []
        }
        title={
          currentRole.organizationType === 'congregation'
          && organizationInfo?.name ? (t('Presbytery_Information')) : (t('Need_Help'))
        }
      />
      <ConfirmModal
        show={showDelete}
        title={t('PDP.Confirm')}
        description={t('MDPDeleteConfirmationMessage')}
        yesLabel={t('PDP.Delete')}
        noLabel={t('PDP.Cancel')}
        callback={closeDelete}
      />
      <ConfirmModal
        show={showRemove}
        title={t('PDP.Confirm')}
        description={t('MDPRemoveConfirmationMessage')}
        yesLabel={t('Remove')}
        noLabel={t('PDP.Cancel')}
        callback={closeRemove}
      />
      <AlertModal
        show={showNoMatchModal}
        title={t('NoMatch')}
        description={t('NoMatchDescription')}
        closeLabel={t('OK')}
        callback={() => setShowNoMatchModal(!showNoMatchModal)}
      />
      <AlertModal
        show={showNoPNC}
        title={t('PNC_Chair_Not_Assigned')}
        description={t('PNC_Chair_Required')}
        closeLabel={t('OK')}
        callback={() => setShowNoPNC(!showNoPNC)}
      />
      <CancelCallNotificationModal
        mdpId={currentMDPId}
        show={showCancelCallModal}
        description={t('Cancel_Call_Warning_MDP', { organization: cancelCallOrg })}
        callback={saveCallCanceledReason}
      />
      <NotifyExternalCallModal
        show={showExternalCallModal}
        hide={() => setShowExternalCallModal(false)}
        mdpList={list}
        callback={saveExternalCallInfo}
      />
      <ExternalCallInstructionsModal
        show={showExternalCallInstructionsModal}
        hide={() => setShowExternalCallInstructionsModal(false)}
        callback={() => {
          setShowExternalCallModal(true);
          setShowExternalCallInstructionsModal(false);
        }}
      />
      <SubmitCallNotificationModal
        show={showSubmitting}
        actionData={notificationData}
        callback={() => {
          setShowSubmitting(false);
          setRefreshList((r: boolean) => !r);
        }}
      />
      <Suspense fallback={<SuspenseLoading />}>
        <ManageMatchingModal
          callback={closeNewEdit}
          show={showManageMatchModal}
          hide={() => { setShowManageMatchModal(false); setRefreshList((r: boolean) => !r); }}
          currentMatch={currentMatch}
        />
      </Suspense>

      <div className="border border-dark rounded-3">
        <Table responsive hover className="mb-3 linktable">
          <thead>
            <tr className="linktableheader">
              <th>{t('Links_ID')}</th>
              <th>{t('PDP.Position')}</th>
              <th>{t('Organization')}</th>
              <th>{t('Status')}</th>
              <th>{t('Links_Table_Actions')}</th>
            </tr>
          </thead>
          { renderMDPTable(list) }
        </Table>
      </div>
    </div>
  );
}

export default withUserAllowed([CLC_FUNCTION.manageMDP, CLC_FUNCTION.viewMatches].join(','))(MDPPanel);
