import React from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceData, ServiceModalData } from '../../types/pdp.types';
import SanitizeHTML from '../../services/html.service';
import { FormatLocalDate } from '../../utils';

const ServiceToTheChurch: React.FC<{ service: ServiceModalData[] | ServiceData[] }> = ({ service }) => {
  const { t } = useTranslation();

  return (
    <div className="border border-dark rounded-3 text-start mb-4">
      <div className="row">
        <div className="col-12 mt-2 mb-2 ms-3 fw-bold h4">
          {t('PDP.Service_to_the_Church')}
        </div>
      </div>
      <hr className="mdpdivider" />
      <div className="row">
        <div className="col-6">
          <div className="my-4  mx-3">
            {service?.map((option: any) => (
              <div key={`servicetochurch-${option.id}`} className="text-start my-4">
                <b>{FormatLocalDate(option.start)}</b>
                &nbsp;-&nbsp;
                <b>{option.end ? FormatLocalDate(option.end) : t('Present')}</b>
                <br />
                {option.position}
                <br />
                {option.organization}
                {option.congregationname?.length > 0 && (
                  <>
                    <br />
                    {option.congregationname}
                    ,&nbsp;
                  </>
                )}
                {option.presbyteryname}
                {option.description?.length > 0 && (
                  <>
                    <br />
                    <SanitizeHTML html={option.description || ''} />
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceToTheChurch;
