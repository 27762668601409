import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, Control } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { SelectOption } from '../types/generic.types';

interface AutoFillSelectProps {
  control: Control<any>;
  id: string;
  label: string;
  placeholder: string;
  options: SelectOption[];
  multiple?: boolean;
  readOnly?: boolean;
  required?: boolean;
}

const AutoFillSelect: React.FC<AutoFillSelectProps> = ({
  control,
  id,
  label,
  placeholder,
  options,
  multiple,
  readOnly,
  required,
}) => (
  <Form.Group>
    <Form.Label className={required ? 'required' : ''}>{label}</Form.Label>
    <Controller
      name={id}
      control={control}
      render={({ field }) => (
        <Typeahead
          {...field}
          id={id}
          multiple={multiple}
          options={options}
          labelKey={(option: SelectOption) => `${option?.value}`}
          placeholder={placeholder}
          selected={field.value || (multiple ? [] : [])}
          onChange={(selected) => field.onChange(selected)}
          disabled={readOnly}
        />
      )}
    />
  </Form.Group>
);

AutoFillSelect.defaultProps = {
  multiple: false,
  readOnly: false,
  required: false,
};

export default AutoFillSelect;
