import { useRecoilCallback } from 'recoil';
import { useEffect } from 'react';
import i18n from 'i18next';
import {
  allPositionTypeListTriggerState,
  attestationNoteTriggerState,
  communityTypesTriggerState,
  congregationSizeTriggerState,
  contactTypeTriggerState,
  eccStatusOptionsTriggerState,
  employmentTypeTriggerState,
  experienceLevelTriggerState,
  housingTypeTriggerState,
  notifyOptionsTriggerState,
  positionTypeTriggerState,
  prefixDataTriggerState,
  raceDataTriggerState,
  suffixDataTriggerState,
} from '../services/state.service';

const useClearRecoilCacheOnLanguageChange = () => {
  const resetRecoilState = useRecoilCallback(({ set }) => () => {
    set(prefixDataTriggerState, (prev) => prev + 1);
    set(suffixDataTriggerState, (prev) => prev + 1);
    set(notifyOptionsTriggerState, (prev) => prev + 1);
    set(eccStatusOptionsTriggerState, (prev) => prev + 1);
    set(congregationSizeTriggerState, (prev) => prev + 1);
    set(communityTypesTriggerState, (prev) => prev + 1);
    set(housingTypeTriggerState, (prev) => prev + 1);
    set(employmentTypeTriggerState, (prev) => prev + 1);
    set(experienceLevelTriggerState, (prev) => prev + 1);
    set(positionTypeTriggerState, (prev) => prev + 1);
    set(allPositionTypeListTriggerState, (prev) => prev + 1);
    set(contactTypeTriggerState, (prev) => prev + 1);
    set(raceDataTriggerState, (prev) => prev + 1);
    set(attestationNoteTriggerState, (prev) => prev + 1);
  });

  useEffect(() => {
    const handleLanguageChange = () => {
      resetRecoilState();
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [resetRecoilState]);
};

export default useClearRecoilCacheOnLanguageChange;
