import { UseFormRegisterReturn } from 'react-hook-form';
import { Form } from 'react-bootstrap';

interface InputFieldProps {
  name: string;
  register: UseFormRegisterReturn;
}

const InputField = (props: InputFieldProps): JSX.Element | null => {
  const { name, register } = props;

  return (
    <Form.Group>
      <Form.Label>{name}</Form.Label>
      <Form.Control {...register} />
    </Form.Group>
  );
};

export default InputField;
