import {
  Table,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import { CompletedCallData } from '../types/mdp.types';
import { FormatLocalDate } from '../utils';

function CompletedCallTable({
  completedCalls,
}: {
  completedCalls: CompletedCallData[],
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="border mb-5 border-dark rounded-3">
      <Table responsive hover className="mb-3 linktable">
        <thead>
          <tr className="linktableheader">
            <th>
              {t('Date_Call_Started')}
            </th>
            <th>
              {t('Match_MDP_ID')}
            </th>
            <th>
              {t('Church_Location')}
            </th>
            <th>
              {t('Call_Seeker_Name')}
            </th>
          </tr>
        </thead>
        <tbody>
          {completedCalls?.map((completedCall: CompletedCallData) => (
            <tr key={completedCall.id + completedCall.year} className="dividinglines">
              <td>
                <Link
                  to={`/callnotificationsummary/${completedCall.id}/`}
                >
                  {FormatLocalDate(completedCall.date)}
                </Link>
              </td>
              <td>{completedCall.mdpId}</td>
              <td>{completedCall.organization}</td>
              <td>{completedCall.callSeeker}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default withUserAllowed([CLC_FUNCTION.manageOrganization].join(','))(CompletedCallTable);
